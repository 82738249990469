import axios from 'axios';
import environment from '@/environment.js';

export default {
  completeExamples(payload) {
    return axios.post(
      `${environment.RASA_URL}/api/databot_ai/complete_examples`,
      payload,
    );
  },
};
