<template>
  <div :class="`col-xl-${column} mb-4`">
    <div class="card p-4" id="average_agent_conversation">
      <label class="pt-2 mb-3">{{ title }}</label>
      <template v-if="items?.length">
        <div class="item row" v-for="(item, index) in items" :key="index">
          <div class="col-md-7 pl-0">
            <div class="row">
              <span v-if="show_index" class="mr-2">{{ index + 1 }}.</span>
              <p v-if="item.name" class="mb-0">{{ capitalize(item.name) }}</p>
            </div>
          </div>
          <div class="col-md-5 d-flex justify-content-end pl-0">
            <span v-if="item.value" class="mb-0">{{ item.value }}</span>
          </div>
        </div>
      </template>
      <template v-else>
        <span>Sin datos</span>
      </template>
    </div>
  </div>
</template>

<script>
import { capitalizeFirstLetter } from "@/utils/utils.js";

export default {
  props: {
    title: {
      type: String,
      default: () => ''
    },
    column: {
      type: Number,
      default: () => 3
    },
    items: {
      type: Array,
      default: () => []
    },
    show_index: {
      type: Boolean,
      default: () => false
    }
  },
  methods: {
    capitalize(val) {
      return capitalizeFirstLetter(val);
    }
  }
}
</script>

<style lang="scss" scoped>
.card {
  .item {
    padding: 1rem 0;
    border-bottom: 1px solid #e8e8e8;
    
    &:last-child {
      border-bottom: none;
      padding-bottom: 0;
    }
  }
}
</style>