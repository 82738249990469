<template>
  <FadeTransition :duration="200">
    <div v-show="selected == 'whatsapp'">
      <hr class="my-5" />
      <h3>Integrar WhatsApp API</h3>
      <p class="mb-5">
        Si utilizas otra plataforma para manejar los datos de tus clientes,
        desde aquí podrás integrarla.
      </p>
      <WhatsAppInEmbeddedSignup
        v-show="!has360Integration && wabas.length === 0"
        @onEmbeddedSignup="onEmbeddedSignup"
        @onSessionInfo="onSessionInfo"
      ></WhatsAppInEmbeddedSignup>
      <div v-if="!has360Integration">
        <div class="my-5" v-show="wabas && wabas.length > 0">
          <h3 class="mb-4">Cuentas WhatsApp Business conectadas</h3>
          <table
            v-if="wabas.length > 0 && !has360Integration"
            class="table table-striped"
          >
            <thead>
              <tr>
                <th scope="col"></th>
                <th scope="col">Nombre para mostrar</th>
                <th scope="col">Número</th>
                <th scope="col">Estado</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="waba in wabas" :key="waba.id">
                <td>
                  <i class="fab fa-whatsapp" />
                </td>
                <td>{{ waba.credentials?.phone_name }}</td>
                <td>
                  <a :href="`https://wa.me/${waba.phone}`" target="_blank">{{
                    waba.phone
                  }}</a>
                </td>
                <td>
                  {{
                    waba.credentials?.code_verification_status === 'VERIFIED'
                      ? '✅'
                      : '❌'
                  }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <template v-else>
        <button
          class="btn btn-base second"
          v-if="!serchQuery && whatsapp['D360-API-KEY'] == ''"
          @click="startIntegratedOnboarding360"
        >
          Empezar proceso
        </button>
        <template v-else>
          <div class="row mt-5 mb-4">
            <div class="col-lg-6 pl-0">
              <label>Número</label>
              <input
                type="number"
                placeholder="569 1234 5678"
                v-model="phone"
              />
            </div>
            <div class="col-lg-6 pr-0">
              <label>Proveedor</label>
              <input
                placeholder="360 Dialog"
                type="text"
                disabled
                v-model="whatsapp.proveedor"
              />
            </div>
          </div>
          <label class="mb-2">Api key</label>
          <label for="whatsapp_api_key" class="card card-click mb-5">
            <input
              type="text"
              placeholder="Ingresa tu Api key"
              class="no_input"
              id="whatsapp_api_key"
              v-model="whatsapp['D360-API-KEY']"
            />
          </label>
          <div class="row justify-content-between">
            <button
              @click="saveIntegration()"
              class="btn btn-base md mx-0"
              :disabled="loading"
            >
              {{ !this.edit ? 'Guardar' : 'Guardar cambios' }}
            </button>
            <button
              class="btn btn-base outline md mx-0"
              @click="$emit('closeView')"
            >
              Cancelar
            </button>
          </div>
        </template>
      </template>

      <hr class="mt-5 mb-3" />
    </div>
  </FadeTransition>
</template>

<script>
import axios from 'axios';
import { mapState } from 'vuex';
import VFacebookLogin from 'vue-facebook-login-component';
import { FadeTransition } from 'vue2-transitions';
import WhatsAppInEmbeddedSignup from '@/components/WhatsAppInEmbeddedSignup.vue';
import dashboard_api from '@/dashboard_api.js';
import environment from '@/environment';
import graphApiService from '@/services/api/graphApi.js';

export default {
  components: {
    FadeTransition,
    VFacebookLogin,
    WhatsAppInEmbeddedSignup,
  },
  props: ["selected", "bot_id", "fields", "view_permissions"],

  data() {
    return {
      edit: false,
      loading: false,
      currentWhatsapp: {},
      phone: '569',
      channel: 'Whatsapp',
      whatsapp: {
        proveedor: '360DIALOG',
        'D360-API-KEY': '',
        host_type: 'CLOUD',
      },
      wabas: [],
    };
  },

  created() {
    this.getInfoWhatsapp();
    this.check360Onboarding();
  },

  computed: {
    ...mapState(['user']),

    serchQuery() {
      return !!(
        this.$route.query.client ||
        this.$route.query.channels ||
        this.$route.query.revoked
      );
    },
    isTestUser() {
      return this.$store.state.user?.email === 'victor@databot.cl';
    },
    has360Integration() {
      return this.whatsapp['D360-API-KEY'] != '';
    },
  },

  watch: {
    selected() {
      this.getInfoWhatsapp();
      // console.log("this.currentWhatsapp->", this.currentWhatsapp);

      if (this.currentWhatsapp && this.currentWhatsapp.bot_id == this.bot_id) {
        this.edit = true;
        this.getCredentials();
      } else {
        this.edit = false;
      }
    },
    fields(val) {
      this.getInfoWhatsapp();
    },
    bot_id(val) {
      this.getInfoWhatsapp();
    },
  },
  methods: {
    async facebookLogged(e) {
      if (e) {
        this.$swal({
          icon: 'info',
          title: 'Integrando cuenta...',
          showConfirmButton: false,
        });
        this.$swal.showLoading();
        console.log('🚀 Aqui *** -> e', e);
        // solo para fines de test, se logea con USUARIO TEST
        const { accessToken, userID } = e.authResponse;
        graphApiService
          .generateCredentialsWhatsapp(userID, accessToken, this.bot_id)
          .then((el) => {
            // append connected pages
            this.$swal({
              icon: 'success',
              title: 'WhatsApp integrado!',
              timer: 1000,
              showConfirmButton: false,
            });
            this.loading = false;
            this.$emit('closeView');
          })
          .catch((err) => console.log('err', err));
      }
    },
    getCredentials() {
      console.log('🐞 LOG HERE this.currentWhatsapp:', this.currentWhatsapp);
      this.phone = this.currentWhatsapp.phone;
      this.channel = this.currentWhatsapp.channel;
      this.bot_id = this.currentWhatsapp.bot_id;
      this.whatsapp.proveedor = this.currentWhatsapp.credentials.proveedor;
      this.whatsapp['D360-API-KEY'] =
        this.currentWhatsapp.credentials['D360-API-KEY'];
    },
    check360Onboarding() {
      const { client, channels, revoked } = this.$route.query;
      // console.log("🚀 Aqui *** -> client,channels,revoked", {
      //   client,
      //   channels,
      //   revoked
      // });
      if (client && channels) {
        // generate api key
        this.sendDataTo360WebhookPartner({
          client,
          channels,
          revoked,
          from: 'Query parameters',
        });
      }
      // listener
      window.addEventListener(
        'message',
        (event) => {
          const { data } = event;
          const queryString = data;
          // console.log("Datos Listener: ", queryString);
          // ?client=oaY9LLfUCL&channels=[y9MiLoCH]
          // make request to backend bot to generate api key
          if (queryString.client && queryString.channels) {
            this.sendDataTo360WebhookPartner({
              ...queryString,
              from: 'Listener',
            });
          }
        },
        false,
      );
    },
    saveIntegration() {
      this.loading = true;

      if (!this.edit) {
        dashboard_api
          .post('/bot_credential/create', {
            phone: this.phone,
            channel: this.channel,
            credentials: this.whatsapp,
            bot_id: this.bot_id,
          })
          .then((resp) => {
            this.$swal({
              icon: 'success',
              title: '¡WhatsApp integrado!',
              timer: 1000,
              showConfirmButton: false,
            });

            // contador de mensajes proximamente

            // dashboard_api
            //   .post('/payroll_message_counters', {
            //     payroll_message_counter: {
            //     income_messages: 0,
            //     outcome_messages: 0,
            //     bot_id: this.bot_id,
            //     provider_id: 1
            //   }
            //   .then((resp => {
            //     console.log('contador de mensajes', resp.data);
            //   }))
            // });
            this.loading = false;
            this.credentials = {};
            this.$emit('closeView');
          })
          .catch((error) => console.log('error->', error));
      } else {
        dashboard_api
          .put(`/bot_credential/update/${this.integrationId}`, {
            phone: this.phone,
            channel: this.channel,
            credentials: this.whatsapp,
            bot_id: this.bot_id,
          })
          .then((resp) => {
            console.log('resp edit whatsapp', resp.data);

            this.$swal({
              icon: 'success',
              title: 'Integración modificada',
              timer: 1000,
              showConfirmButton: false,
            });
            this.loading = false;
            this.$emit('closeView');
          });
      }
      // seteando webhook 360dialog
      axios.post(environment.socket_url + '/whatsapp/360dialog/set-webhook', {
        apiKey: this.whatsapp['D360-API-KEY'],
      });
    },
    /**
     * @Description WhatsApp Business API 360Dialog Integrated Process
     */
    async sendDataTo360WebhookPartner(queryString) {
      const resp = await axios.post(
        environment.socket_url + '/whatsapp/360dialog/webhook_partner_data',
        {
          bot_id: this.bot_id,
          ...queryString,
        },
      );
      const data = resp.data;
      this.whatsapp['D360-API-KEY'] = data.api_key;
      this.phone = data.phone;
      // notificando a cliente
      this.$swal({
        icon: 'success',
        title: '¡WhatsApp integrado!',
        timer: 1000,
        showConfirmButton: false,
      });
    },
    getInfoWhatsapp() {
      dashboard_api
        .get(`/bot_credential/get_by_bot_id/${this.bot_id}`)
        .then((resp) => {
          // console.log("respuesta whsap->", resp.data);
          if (resp.data && resp.data.length > 0) {
            let wsp_filter = resp.data.find(
              (el) =>
                el.channel.toLowerCase() == 'whatsapp' &&
                el.credentials &&
                el.credentials.hasOwnProperty('D360-API-KEY'),
            );
            console.log('🐞 LOG HERE wsp_filter:', wsp_filter);
            if (wsp_filter) {
              console.log('wsp_filter->', wsp_filter);

              this.currentWhatsapp = wsp_filter;
              this.integrationId = wsp_filter;
              this.$emit('getWsp', this.currentWhatsapp);
            }
            // check if has WhatsApp meta Integration
            if (!wsp_filter) {
              this.wabas = resp.data.filter(
                (el) =>
                  el.channel.toLowerCase() == 'whatsapp' &&
                  el.credentials?.phone_number_id,
              );
              if (this.wabas.length > 0) {
                this.$emit('getWsp', { provider: 'meta', wabas: this.wabas });
              }
            }
          } else {
            this.$emit('dontWsp');
            this.phone = '569';
            this.whatsapp['D360-API-KEY'] = '';
            this.edit = false;
          }
        });
    },
    startIntegratedOnboarding360() {
      const windowFeatures =
        'toolbar=no, menubar=no, width=600, height=900, top=100, left=100';
      open(
        'https://hub.360dialog.com/dashboard/app/pD8nLIPA/permissions',
        'integratedOnboardingWindow',
        windowFeatures,
      );
    },
    onEmbeddedSignup(response) {
      // send event to backend
      const hasFinishedEmbeddedSignup =
        response.authResponse && response.authResponse.code;
      if (hasFinishedEmbeddedSignup) {
        this.$swal({
          icon: 'info',
          title: 'Integrando WhatsApp...',
          showConfirmButton: false,
          onBeforeOpen: () => {
            this.$swal.showLoading();
          },
          showCloseButton: false,
          showCancelButton: false,
        });
      }
      axios
        .post(`${environment.url_env_bot}/whatsapp/embedded_signup_events`, {
          ...response,
          bot_id: this.bot_id,
          embedded_signup_event: 'embedded_signup',
        })
        .then((resp) => {
          if (hasFinishedEmbeddedSignup) {
            this.$swal.close();
            this.$swal({
              icon: 'success',
              title: '¡WhatsApp integrado!',
              timer: 1000,
              showConfirmButton: false,
            });
            this.$emit('closeView');
          }
        })
        .catch((err) => {
          if (hasFinishedEmbeddedSignup) {
            this.$swal.close();
          }
          console.log('err', err);
        });
    },
    onSessionInfo(data) {
      console.log('🐞 EVENT IN LISTENER:', data);
      axios
        .post(`${environment.url_env_bot}/whatsapp/embedded_signup_events`, {
          ...data,
          bot_id: this.bot_id,
          embedded_signup_event: 'embedded_session_info',
        })
        .then((resp) => {
          console.log(
            'Respuesta de evento embedded session info whatsapp:',
            resp.data,
          );
        });
    },
  },
};
</script>

<style scoped>
.fa-whatsapp {
  font-size: 1.4rem;
  color: #8ae04d;
  margin-right: 0rem;
}
</style>
