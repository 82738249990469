var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"chats botton-line position-relative h-100"},[_c('div',{staticClass:"menu"},[_c('h3',{staticClass:"my-3"},[_vm._v("Historia de conversaciones")]),_c('div',{staticClass:"d-flex justify-content-between",class:{ search_menu: true }},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchChat),expression:"searchChat"}],staticClass:"m-0",attrs:{"type":"search","placeholder":"Buscar chat"},domProps:{"value":(_vm.searchChat)},on:{"input":function($event){if($event.target.composing)return;_vm.searchChat=$event.target.value}}}),(_vm.tags.length)?_c('span',{staticClass:"ml-3 filter",class:{
          'i-btn': true,
          'i-filter': !_vm.showDropdownFilter,
          'i-btn-active i-close i-st': _vm.showDropdownFilter,
        },on:{"click":function($event){_vm.showDropdownFilter = !_vm.showDropdownFilter}}}):_vm._e(),_c('MultiselectTags',{attrs:{"showDropdown":_vm.showDropdownFilter,"items":_vm.tags},on:{"sendCategoryFilter":function($event){_vm.categoryFilter = $event}}})],1)]),(_vm.isLoadingConversations)?_c('spinnerLoading'):[(_vm.conversations?.length > 0)?[_c('FadeTransition',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isLoadingConversations),expression:"!isLoadingConversations"}],attrs:{"duracion":200}},[_c('div',{staticClass:"scroll_box position-relative"},_vm._l((_vm.conversations),function(conversation,idx){return _c('div',{key:idx,class:{
              to_card: true,
              selected_card: _vm.selectedConversation?.id === conversation.id,
            },on:{"click":function($event){_vm.$emit('onSelectedConversation', conversation), _vm.showDropdownFilter = false}}},[_c('div',{staticClass:"card-box"},[(
                  conversation.channel !== 'whatsapp' &&
                  conversation.channel !== 'instagram'
                )?_c('span',{staticClass:"user-img"},[_vm._v(_vm._s(_vm.leadName(conversation)[0]))]):(conversation.channel==='instagram' && conversation.custom?.profile_pic)?_c('img',{staticClass:"mr-2 msg-profile",attrs:{"src":conversation.custom?.profile_pic,"alt":""}}):_c('span',{class:{
                  'user-img-wsp': conversation.channel === 'whatsapp',
                  'user-img-instagram': conversation.channel === 'instagram',
                }}),_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-6 px-0"},[_c('label',{staticClass:"mb-0 mr-2"},[_vm._v(" "+_vm._s(_vm.leadName(conversation))+" ")]),(
                      conversation.category &&
                      conversation.category != 'general'
                    )?_c('span',{staticClass:"etiqueta label-p-sm mr-1"},[_vm._v(_vm._s(conversation.category))]):_vm._e()]),_c('div',{staticClass:"col-6 pl-2 pr-0"},[_c('p',{staticClass:"text_sm text-right m-0"},[_vm._v(" "+_vm._s(_vm.$moment(conversation.last_message_date).locale('es').format('DD MMMM YYYY - h:mm a'))+" ")])])])])])}),0)]),_c('div',{staticClass:"pagination-container position-absolute px-3 py-4 w-100"},[_c('Pagination',{attrs:{"itemsPerPage":_vm.itemsPerPage,"page":_vm.page,"pages":_vm.pages},on:{"changePage":function($event){return _vm.$emit('changePage', $event)},"changeItemsPerPage":function($event){return _vm.$emit('changeItemsPerPage', $event)}}})],1)]:[_c('FadeTransition',{directives:[{name:"show",rawName:"v-show",value:(_vm.conversations.length == 0),expression:"conversations.length == 0"}],attrs:{"duracion":200}},[_c('div',{staticClass:"info_box mx-3 my-4"},[_c('p',{staticClass:"text-center"},[_vm._v("Sin conversaciones")])])])]]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }