<template>
  <div :class="`col-xl-${columns} mb-3`">
    <div class="data-card-lg">
      <div class="mb-0">
        <div class="mb-4">
          <h2 v-if="title" class="mb-0">{{ title }}</h2>
          <p v-if="label" class="mb-0">{{ label }}</p>
        </div>
        <div>
          <div class="row">
            <div class="col-md-6 pl-0">
              <span class="normal mb-1">{{ label_value_1 }}: {{ value_1 }}</span>
            </div>
            <div class="col-md-6">
              <span class="normal mb-1">{{ label_value_2 }}: {{ value_2 }}</span>
            </div>
          </div>
        </div>
      </div>
      <img class="icon" src="/img/icons/46-i-metrics-carousel.svg" alt="">
      <div class="fill"></div>
    </div>
  </div>
</template>

<script>
import DataChart from '../../components/builders/DataChart';

export default {
  components: {
    DataChart
  },
  props: {
    value_1: {
      type: Number,
      default: () => 0
    },
    value_2: {
      type: Number,
      default: () => 0
    },
    label_value_1: {
      type: String,
      default: () => 'Ingresa un text 1'
    },
    label_value_2: {
      type: String,
      default: () => 'Ingresa un text 2'
    },
    title: {
      type: String,
      default: () => 'Título'
    },
    label: {
      type: String,
      default: () => 'Descripción'
    },
    columns: {
      type: Number,
      default: () => 9
    }
  },
  data() {
    return {

    }
  },
  computed: {
    diferencePercent() {
      const old = this.last_month_value;
      const now = this.current_month_value;
      let percent = 0;

      // Porcentaje de diferencia vs registro anterior
      if (old === 0 && now === 0) return percent = 0;
      else if (old === 0 || now === 0) return percent = 100;
      else return percent = (((now - old) / old ) * 100).toFixed(0);
    },
    comparison() {
      const current = this.current_month_value;
      const last = this.last_month_value;

      return current >= last;
    }
  },
  methods: {
    percentFormat(number) {
      let value = number;
      return number > 1 ? number : number = value * 100;
    },
    setFormatNumber(number) {
      switch (this.type) {
        case "price":
          return this.lang != 'es' ? Intl.NumberFormat('en-US').format(number) : Intl.NumberFormat('de-DE').format(number);
        case "percent":
          return this.percentFormat(number);
        default:
          return Intl.NumberFormat('de-DE').format(number);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.data-card-lg {
  position: relative;
  min-height: fit-content;
  height: auto;
  min-width: fit-content;
  width: 100%;
  padding: 1.5rem 2.5rem;
  border: 1px solid #e8e8e8;
  background-color: #fff;
  border-radius: 1rem;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 140px;
  margin: .5rem 0;
  background: linear-gradient(to top left, #deedff 0%, #fff 100%);
  border: 1px solid #bfdbff;
  transition: .25s;

  .title {
    font-size: 12px;
  }
  .label {
    font-size: 11px;
  }
  .icon {
    position: relative;
    width: 120px;
    height: 100%;
    z-index: 2;
  }
}
</style>
