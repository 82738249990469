<template>
  <Modal class="modal-2" ancho="600" alto="500" :show="showModal" @close="$emit('closeModal')">
    <template slot="header"> </template>
    <template slot="content">
      <section class="calendly-view">
        <template v-if="loading">
          <SpinnerLoading />
        </template>
        <!-- Principio del widget integrado de Calendly -->
        <div
          v-else
          class="calendly-inline-widget"
          data-url="https://calendly.com/nicolasbarrera/20min?back=1&month=2021-01"
          style="min-width: 320px; height: 630px"
        ></div>
      </section>
    </template>
  </Modal>
</template>

<script>
export default {
  props: ["showModal"],
  data() {
    return {
      loading: false
    };
  },
  mounted() {
    this.addCustomScript("https://assets.calendly.com/assets/external/widget.js");
  },

  methods: {
    addCustomScript(src) {
      self.loading = true;

      const recaptchaScript = document.createElement("script");
      recaptchaScript.setAttribute("src", src);
      recaptchaScript.async = true;
      document.head.appendChild(recaptchaScript);

      self.loading = false;
    }
  }
};
</script>
