<template>
  <div :class="`col-xl-${column} mb-4`">
    <div v-if="id" class="data-chart row">
      <div v-if="disabled.includes(id)" class="no_data-card"><i class="far fa-chart-bar"></i><span class="mb-0">{{ name }} sin datos</span></div>
      <div v-else class="card">
        <template v-if="tooltip.length > 0">
          <span class="float_icon">?</span>
          <span class="tooltip_text-sm">{{ tooltip }}</span>
        </template>
        <select class="float_select sm" v-if="select_model" name="" id="" v-model="edit_model">
          <option v-for="(item, index) in select_options" :key="index" :value="item">Tipo {{ item }}</option>
        </select>
        <div class="row align-items-center">
          <div class="col-md-9">
            <div :class="{ 'chart-item pb-0': true, 'full_height navegable_card': height == 100 }" :id="id"></div>
          </div>
          <div class="col-md-3">
            <div v-show="second_id" class="second_chart pb-0" :id="second_id"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    column: {
      type: Number,
      default: () => 6
    },
    type: {
      type: String,
      default: () => 'bar'
    },
    id: {
      type: String,
      default: () => ''
    },
    second_id: {
      type: String,
      default: () => ''
    },
    name: {
      type: String,
      default: () => ''
    },
    disabled: {
      type: Array,
      default: () => []
    },
    height: {
      type: Number,
      default: () => 0
    },
    select_model: {
      default: () => ''
    },
    select_options: {
      type: Array,
      default: () => []
    },
    tooltip: {
      type: String,
      default: () => ''
    }
  },
  data() {
    return {
      edit_model: this.select_model
    }
  },
  watch: {
    edit_model(val) {
      this.$emit('updateValue', val);
    }
  }
}
</script>

<style lang="scss" scoped>
.data-chart {
  width: 100%;

  .card {
    width: 100%;
    position: relative;
    padding: 1.75rem 1.75rem 0;

    @media (max-width: 991px) {
      height: fit-content;
    }
    .chart-item {
      width: 100%;
      height: 300px;

      &.full_height {
        height: 85vh;

        @media (max-width: 1600px) {
          height: 70vh;
        }
        @media (max-width: 991px) {
          height: 100%;
        }
      }
      &.navegable_card {
        background-image: radial-gradient(#cdcdcd 1px, transparent 0);
        background-size: 40px 40px;
        background-position: -1rem -1rem;
      }
    }
    .card {
      border-radius: .75rem;
      height: 100%;
    }
    .float_select {
      position: absolute;
      top: 4rem;
      left: 1.75rem;

      &.sm {
        width: 120px;
        padding: .5rem 1rem;
      }
    }
    &:hover {
      .float_icon {
        opacity: 1;

        &:hover {
          + .tooltip_text-sm {
            opacity: 1;
            visibility: visible;
          }
        }
      }
    }
  }
  .second_chart {
    width: 100%;
    height: 200px;
  }
  .float_icon {
    opacity: 0;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 1rem;
    right: 1rem;
    width: 1rem;
    height: 1rem;
    border-radius: 10px;
    background-color: #181818;
    color: #fff;
    font-weight: 700;
    transition: .25s;
    font-size: 10px;
    cursor: pointer;
    z-index: 50;
  }
  .tooltip_text-sm {
    background-color: #2f2f2f;
    left: auto;
    top: 1rem;
    right: 1rem;
    max-width: 300px;
    font-size: 12px;
    z-index: 55;

    &:hover {
      opacity: 1;
      visibility: visible;
    }
    &::after {
      border-width: 0;
      border-style: none;
      border-color: transparent;
    }
  }
  .no_data-card {
    width: 100%;
    background-color: #fafafa;
    color: #767676;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    height: 320px;
    border: 1px solid #e8e8e8;
    border-radius: 1rem;
  }
}
</style>