<template>
  <Modal class="modal-2" ancho="600" alto="500" :show="showModal" @close="$emit('closeModal')">
    <template slot="header">
      <h1 class="titulo__modal mb-4"></h1>
    </template>
    <template slot="content">
      <section class="exportaciones">
        <div class="col-lg-12">
          <div class="row align-items-center mt-3 mb-5">
            <div class="col-lg-10">
              <p class="mb-0">
                Aquí podrás exportar los datos en formato excel y filtrar por fecha si necesitas.
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-12">
          <div class="col-md-6">
            <label for="">Exportar datos</label>
            <select class="mb-5" name="" id="" v-model="type">
              <option value=null>Selecciona</option>
              <option value="crm">Tabla completa</option>
              <option value="califications">Calificaciones</option>
              <option value="questions">Pregunta inicial</option>
              <option value="requests">Número de solicitudes</option>
              <option value="phones">Numeros de teléfono no válidos</option>
            </select>
          </div>
          <div class="d-flex">
            <div class="col-md-6">
              <label>Desde</label>
              <input
                id="input__date"
                type="date"
                v-model="time_export_init"
              />
            </div>
            <div class="col-md-6">
              <label>Hasta</label>
              <input
                id="input__date"
                type="date"
                v-model="time_export_end"
              />
            </div>
          </div>
          <p @click="empty" class="btn-text pl-3">x Borrar fechas</p>
          <div class="row justify-content-center my-4">
            <button
              class="btn btn-base sm"
              @click="exportData()"
              >Exportar excel
            </button>
          </div>
        </div>
      </section>
    </template>
  </Modal>
</template>

<script>
import { mapState } from "vuex";
import dashboard_api from "@/dashboard_api.js";
import dashboard_api_v2 from "@/dashboard_api_v2.js";
import environment from "../../../environment.js"
import axios from "axios";

export default {
  props: ["userbot", "showModal", "closeModal"],

  data() {
    return {
      test: '',
      time_export_init: '',
      time_export_end: '',
      type: null
    };
  },
  computed: {
    ...mapState(['selected_bot', 'user']),
  },
  methods: {
    empty() {
      this.time_export_init = "";
      this.time_export_end = "";
    },
    exportData() {
      this.$swal({
        icon: 'success',
        title: 'El archivo será enviado a tu correo',
        showConfirmButton: false,
        allowOutsideClick: false,
        timer: 2000
      });

      switch (this.type) {
        case 'crm':
          this.crm();
          break;
        case 'califications':
          this.califications();
          break;
        case 'questions':
          this.questions();
          break;
        case 'requests':
          this.requests();
          break;
        case 'phones':
          this.phones();
          break;
      };
      this.$emit("closeModal");
    },

    califications() {
      dashboard_api
        .post('/conversations/export_califications', {
          bot_id: this.selected_bot.id,
          from: this.time_export_init,
          to: this.time_export_end
        })
        .catch(error => console.error('error >', error));
    },

    questions() {
      dashboard_api_v2
        .post('/leads/export_lead_routes', {
          bot_id: this.selected_bot.id,
          from: this.time_export_init,
          to: this.time_export_end
        })
        .catch(error => console.error('error >', error));
    },

    crm() {
      axios
        .post(`${environment.url_metrics}/export_crm_mirror`, {
          bot_id: this.selected_bot.id,
          from: this.time_export_init,
          to: this.time_export_end,
          email: this.user.email
        })
        .catch(error => console.error('error >', error));
    },

    requests() {
      dashboard_api_v2
        .post('/leads/export_count', {
          bot_id: this.selected_bot.id,
          from: this.time_export_init,
          to: this.time_export_end
        })
        .catch(error => console.error('error >', error));
    },

    phones() {
      dashboard_api_v2
        .post('/leads/check_phone_numbers', {
          bot_id: this.selected_bot.id
        })
        .catch(error => console.error('error >', error));
    },
  }
};
</script>

<style lang="scss" scoped>
.exportaciones {
  background-color: #fff;
  border-radius: 10px;
  text-align: left;

  input {
    width: 100%;
    margin: 0 0 1rem;
  }
  select {
    width: 100%;
    margin: 0 0 1rem;
  }
  .contenedor {
    padding: 0 1rem;
  }
  .btn-date {
    font-size: 14px;
    height: 49px;
    border: none;
    border-radius: 0.5rem 0 0 0.5rem;
    background-color: #b2b2b2;
    color: #fff;
    padding: 0.5rem;
    margin-right: -4px;
    z-index: 4;
    margin: 0;

    @media (max-width: 1024px) {
      display: none;
    }
  }
}
</style>
