<template>
  <div class="h-100" :key="selected_bot.id">    
    <SpinnerLoading v-if="isLoadingSessions" />
    <template v-else>
      <section v-if="!selectedConversation" class="no-data">
        <div class="space">
          <img src="/img/icons/23-no-info.svg" alt="" />
          <span class="my-4">
            Sin conversación seleccionada
          </span>
        </div>
      </section>
      <section class="h-100 d-flex flex-column justify-content-between" v-else>        
        <div>
          <div class="m-4 d-flex justify-content-between align-items-center">
            <div class="row align-items-center">
              <span
                v-if="
                  selectedConversation.channel !== 'whatsapp' &&
                  selectedConversation.channel !== 'instagram'
                "
                class="user-img"
                >{{ leadName(selectedConversation)[0] }}</span
              >
              <img
                  v-else-if="selectedConversation.channel==='instagram' && selectedConversation.custom?.profile_pic"
                  :src="selectedConversation.custom?.profile_pic"
                  alt=""
                  class="mr-2 msg-profile">
              <span
                v-else
                :class="{
                  'user-img-wsp': selectedConversation.channel === 'whatsapp',
                  'user-img-instagram': selectedConversation.channel === 'instagram',
                }"
              ></span>
              <h3 class="m-0 pl-2">Conversación con {{leadName(selectedConversation)}}</h3>              
            </div>
            <div class="row aling-items-center">
              <span @click="showFilterModal = true" class="btn-select static sm my-2">Filtrar fechas</span>
            </div>
          </div>
          <section class="table buefy">
            <b-table
              :range-before="rangeBefore"
              :range-after="rangeAfter"
              :data="sessions"
              :per-page="perPage"
              :current-page.sync="currentPage"
              :pagination-simple="true"
              :pagination-position="'bottom'"
              :default-sort-direction="'desc'"
              :sort-icon="'caret-up'"
              aria-next-label="Next page"
              aria-previous-label="Previous page"
              aria-page-label="Page"
              aria-current-label="Current page"
              icon-pack="fas"
              :key="tableKey"
              :selected.sync="selectedRow"
              :sticky-header="true"
              height="500"
              focusable
            >
              <template slot-scope="props">
                <b-table-column
                  field="session"
                  label="Session"
                  sortable
                  width="300"
                >
                  <div 
                    class="d-flex align-items-center h-100 w-100"
                    @mouseover="hoverSessionIndex = props.row.id"
                    @mouseleave="hoverSessionIndex = null"
                  >
                    <p>
                      <img src="/img/icons/42-i-chat.svg" class="mr-2" alt="">
                      Sesión - {{props.row.id}}
                    </p>
                    <section v-show="hoverSessionIndex == props.row.id">                
                      <span 
                        @click="$emit('openItem3', { type:'messages', value: props.row.id })"
                        class="i-btn i-btn-sm i-btn-white i-chat-2 ml-3">
                      </span>
                      <span 
                      @click="$emit('openItem3', { type:'files', value: props.row.id })"
                        class="i-btn i-btn-sm i-btn-white i-doc ml-2">
                      </span>
                      <span
                        @click="$emit('openItem3', { type:'recommender', value: props.row.id })"
                        class="i-btn i-btn-sm i-btn-white i-cart ml-2">
                      </span>
                    </section>
                  </div>
                </b-table-column>
                <b-table-column
                  field="bot-calification"
                  label="Calificación de chatbot"
                  sortable
                >
                  <p>{{props.row.bot_calification > 0 ? `${props.row.bot_calification}/5` : `-`}}</p>
                </b-table-column>
                <b-table-column
                  field="agent-calification"
                  label="Calificación de agente"
                  sortable
                >
                  <p>{{ props.row.agent_activities[0]?.calification > 0 ? `${ props.row.agent_activities[0]?.calification }/5` : `-`}}</p>
                </b-table-column>
                <b-table-column
                  field="created-date"
                  label="Creación"
                  sortable
                >
                  <p>{{$moment(props.row.created_at).locale('es').format('DD MMMM YYYY - h:mm a')}}</p>
                </b-table-column>
              </template>
            </b-table>
          </section>
        </div>
        <section class="table-footer p-4">
          <div class="col-md-4">
            <span class="text_sm mr-3 mb-0">
              {{ totalSessions == 1 ? `${totalSessions} sesión` : `${totalSessions} sesiones` }}
            </span>
          </div>
          <div class="col-md-6">
            <Pagination 
              :itemsPerPage="itemsPerPageSession"
              :page="pageSession"
              :pages="pagesSession"
              @changePage="$emit('changePage', $event)"
              @changeItemsPerPage="$emit('changeItemsPerPage', $event)"
            />
          </div>
          <div class="col-md-2">
            <button class="btn-select static sm my-0" @click="exportAll">
              Exportar datos
            </button>
          </div>
        </section>
      </section>
    </template>
    <DateFilterModal
      :showFilterModal="showFilterModal"
      @closeModal="showFilterModal = false"
      @filterDate="filterDatePush"
    />
  </div>
</template>

<script>
import { FadeTransition } from 'vue2-transitions/dist/vue2-transitions.cjs';
import Pagination from '../../DatabotLive/components/Pagination.vue';
import { mapState } from 'vuex';
import { capitalizeFirstLetter } from '@/utils/utils.js';
import '../../../../assets/buefy_class.scss'
import '../../../../assets/main.scss'
import environment from '@/environment.js';
import DateFilterModal from '../../../widgetWhatsapp/components/DateFilterModal.vue';
import axios from 'axios';

export default {
  props: [
    'sessions',
    'isLoadingSessions',
    'selectedConversation',
    'itemsPerPageSession',
    'pageSession',
    'pagesSession',
    'totalSessions'
  ],
  components: {
    FadeTransition,
    Pagination,
    DateFilterModal
  },
  data(){
    return {
      isLoading: false,
      // TABLA
      rangeBefore: 1,
      rangeAfter: 1,
      perPage: 5,
      currentPage: 1,
      tableKey: 0,
      selectedRow: {},
      hoverSessionIndex: null,
      showFilterModal: false,
      filteredDate: null
    }
  },
  computed: {
    ...mapState(['user', 'selected_bot', 'show_client_info', 'activeClient']),
  },
  methods: {
    leadName(conversation) {
      return this.capitalize(
        conversation.lead_name ||
          (!conversation.lead_name || conversation.lead_name == '--'
            ? 'Cliente'
            : conversation.lead_name),
      );
    },
    capitalize(value) {
      return capitalizeFirstLetter(value);
    },
    filterDatePush(init, end) {
      this.showFilterModal = false;
      this.filteredDate = {
        init: init,
        end: end
      }
      this.$emit('changeDate', { init: init, end: end });
    },
    async exportAll(){
      try {
        this.$swal({
          icon: "info",
          title: "Exportando...",
          showConfirmButton: false
        });
        this.$swal.showLoading();
        let baseUrl = `${environment.url_metrics}/export_sessions_by_conversation`
        let payload = {
          "conversation_id": this.selectedConversation.id,
          "email": this.user.email,
          "bot_id": this.selected_bot.id
        }
        if(this.filteredDate?.init) payload['from'] = this.filteredDate.init
        if(this.filteredDate?.end) payload['to'] = this.filteredDate.end
        await axios.post(baseUrl, payload)
        this.$swal({
          title: 'Exportación exitosa',
          icon: 'success',
          timer: 1000,
          showConfirmButton: false,
        });
      } catch (error) {
        console.log(error);
        this.$swal({
          title: 'Exportación fallida',
          text: 'Algo salió mal, contactese con soporte',
          icon: 'error',
          buttons: {
            confirm: {
              text: 'Ok',
              value: true,
              visible: true,
              className: '',
              closeModal: true,
            },
          },
        });
      }
    }
  }
}
</script>


<style lang="scss">
.tabla-clientes {
  .buefy .b-table .table {
    border: none;
  }
}
</style>

<style lang="scss">
.no-data {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fafafa;
  height: calc(100vh - 80px);
  overflow-y: hidden;

  .space {
    margin: auto 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    span {
      width: 70%;
      text-align: center;
    }
  }
}

.user-img,
.user-img-wsp,
.user-img-instagram {
  width: 30px;
  height: 30px;
  display: flex;
  border-radius: 50rem;
  background-color: #2981ef;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-weight: 700;
}
.table-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  border-top: 1px solid #e8e8e8;
  background-color: #fafafa;
}
.user-img-wsp {
  background-color: #9edd63;
  background-image: url('/img/icons/18-i-whatsapp-b.svg');
  background-repeat: no-repeat, repeat;
  background-position: center;
  background-size: 1rem 100%;
}
.user-img-instagram {
  background-color: #f5365c;
  background-image: url('/img/icons/31-i-instagram-b.svg');
  background-repeat: no-repeat, repeat;
  background-position: center;
  background-size: 1rem 100%;
}
</style>