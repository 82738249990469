<template>
  <FadeTransition :duration="200">
    <section v-show="selected == 'vtex'">
      <hr class="my-5" />
      <h3>Integrar Vtex</h3>
      <div class="row mt-5">
        <div class="col-lg-6 pl-0">
          <p class="text_error" v-if="error == 'error_app_key'">Completa el App Key</p>
          <label v-else>App Key</label>
          <input
            :class="{border_error: error == 'error_app_key'}"
            placeholder="Ingresa tu App Key"
            type="text"
            v-model="vtex.app_key"
          />
        </div>
        <div class="col-lg-6 pr-0">
          <p class="text_error" v-if="error == 'error_app_domain'">Completa el dominio</p>
          <label class="mb-2">Dominio</label>
          <input
            :class="{ border_error: error == 'error_empty' || error == 'error_app_domain'}"
            placeholder="Ejem: https://tu-dominio.com"
            type="text"
            v-model="vtex.app_domain"
          />
        </div>
      </div>
      <!-- <div class="row">
        <div class="col-lg-6 pl-0">
          <label>Account name</label>
          <input
            type="text"
            placeholder="Ingresa tu name account"
            v-model="vtex.account_name"
          />
        </div>
        <div class="col-lg-6 pr-0">
          <label>Environment</label>
          <input
            :class="{ border_error: error == 'error_app_token' }"
            placeholder="Ingresa tu environment"
            type="text"
            v-model="vtex.app_environment"
          />
        </div>
      </div> -->
      <p
        class="text_error"
        v-if="error == 'error_app_token'"
      >Completa el App token</p>
      <label v-else>App token</label>
      <label
        for="vtex-token"
        class="card card-click mb-5"
        :class="{border_error: error == 'error_app_token'}"
      >
        <input
          type="text"
          placeholder="Ingresa tu App token"
          class="no_input"
          v-model="vtex.app_token"
          id="vtex-token"
        />
      </label>
      <div class="row justify-content-between">
        <button
          @click="saveIntegration()"
          class="btn btn-base md mx-0"
          :disabled="loading || view_permissions.edit != true"
          >{{ !edit ? 'Guardar' : 'Guardar cambios' }}
          <span class="tooltip_text-sm for_disabled" v-if="view_permissions.edit != true">Lo sentimos, no tienes permisos para guardar cambios.</span>
        </button>
        <button
          class="btn btn-base outline md mx-0"
          @click="$emit('closeView')"
          >Cancelar
        </button>
      </div>
      <hr class="mt-5 mb-3" />
    </section>
  </FadeTransition>
</template>

<script>
import { FadeTransition } from "vue2-transitions";
import dashboard_api from "@/dashboard_api.js";
import axios from "axios";
import environment from '@/environment.js';
import moment from "moment"
import https from "https";

export default {
  components: {
    FadeTransition
  },
  props: [
    "selected",
    "bot_id",
    "fields",
    "integrations",
    "view_permissions"
  ],
  data() {
    return {
      edit: false,
      loading: false,
      credentials: {},
      error: '',
      vtex: {
        app_key: '',
        app_token: '',
        // account_name: '',
        // app_environment: '',
        app_domain: ''
      }
    };
  },
  watch: {
    selected(val) {
      if (this.integrations && this.integrations.length > 0) {
        for (let integration of this.integrations) {
          if (this.selected == integration.provider) {
            this.edit = true;
            this.integrationId = integration.id;
            this.credentials = integration.credentials;
            this.getCredentials();
          } else {
            this.edit = false;
          }
        }
      }
    },

    fields(val) {
      this.edit = false,
      this.loading = false;
      this.vtex.app_key = '';
      this.vtex.app_token = '';
      // this.vtex.account_name = '';
      // this.vtex.app_environment = '';
      this.vtex.app_domain = '';
    },
    loading(val) {
      if(val===true){
        console.log("true",val);
        this.$swal({
            title: 'Cargando',
            allowOutsideClick: false,
            onBeforeOpen: () => {
                this.$swal.showLoading()
            },
        });
      }
      // else {
      //   console.log("false",val);
      //   this.$swal.hideLoading()
      // }
    }
  },

  methods: {
    async checkCredentials(domain, appKey, appToken) {
      try {
        const config = {
          method: 'POST',
          url: `${environment.url_automations}/check_cms_credentials`,
          data: {
            provider: "vtex",
            credentials: {
              vtexDomain: domain,
              vtexAppKey: appKey,
              vtexAppToken: appToken
            }
          },
        }
        const res = await axios.request(config)
        return res.data
      } catch (error) {
        return false
      }
    },
    getCredentials() {
      let currentCredentials = JSON.parse(this.credentials);
      this.vtex.app_key = currentCredentials.app_key;
      this.vtex.app_token = currentCredentials.app_token;
      // this.vtex.account_name = currentCredentials.account_name;
      // this.vtex.app_environment = currentCredentials.app_environment;
      this.vtex.app_domain = currentCredentials.app_domain;
    },
    async saveIntegration() {
      let status = true;
      this.loading = true;
      const { app_key, app_token, account_name, app_environment, app_domain } = this.vtex;

      const deleteTimer = localStorage.getItem("deleteTimer")
      const actualTime = new Date()

      // LUEGO DE BORRAR UN HOOK DE VTEX, HAY QUE ESPERAR 60 SEGUNDOS
      if(deleteTimer && moment(actualTime).diff(deleteTimer,'seconds') <= 120){
        this.loading = false
        console.log("no se borra");
        this.$swal({
          icon: 'error',
          title: `No se puede crear el evento, espere ${120 - moment(actualTime).diff(deleteTimer,'seconds')} segundos`,
        });
        return
      } else {
        localStorage.removeItem("deleteTimer")
      }
      if (app_key.length == 0) {
        this.error = 'error_app_key';
        this.loading = false;
      } else if (app_token.length == 0) {
        this.error = 'error_app_token';
        this.loading = false;
      } else if (app_domain.length == 0) {
        this.error = 'error_empty';
        this.loading = false;
      } else if (!app_domain.includes("http")) {
        this.error = 'error_app_domain';
        this.loading = false;
      } else if (!this.edit) {
          const credValidation = await this.checkCredentials(app_domain, app_key, app_token);

          if(!credValidation) {
            this.$swal({
              icon: "error",
              title: "Credenciales no válidas",
            });
            this.loading = false
            return
          }

          dashboard_api
            .post("/user_credentials", {
              provider: this.selected,
              credentials: JSON.stringify(this.vtex),
              status,
              bot_id: this.bot_id,
            },
          ).then(async resp => {
            try {
              await axios.post(`${ environment.url_env_bot }/create-vtex-hook`, { 
                orderStatus: ["invoiced"],
                credential: resp.data,
                endpoint: "vtex-status-cart"
              })
            } catch (error) {
              console.log("create-vtex-hook error")
            }
            this.$swal({
              icon: "success",
              title: "¡Vtex integrado!",
              timer: 1000,
              showConfirmButton: false,
            });
            this.loading = false;
            this.error = '';
            this.credentials = {};
            this.$emit('closeView');
            this.$emit('getIntegrations');
            try {
              // CARGA DE PRODUCTOS
              const config = {
                method: 'POST',
                url: `${environment.url_automations}/charge_products_by_bot`,
                data: {
                  "bot_id": this.bot_id
                },
              }
              await axios.request(config)
            } catch (error) {
              console.log("loading products error",error)
            }
          })
          .catch(error => console.log('error->', error));
        } else {
          const credValidation = await this.checkCredentials(app_domain, app_key, app_token)
          if(!credValidation) {
            this.$swal({
              icon: "error",
              title: "Credenciales no válidas",
            });
            this.loading = false
            return
          }
          dashboard_api
            .put(`user_credentials/${this.integrationId}`, {
              credentials: JSON.stringify(this.vtex)
            }).then((async resp => {
              try {
                await axios.post(`${ environment.url_env_bot }/create-vtex-hook`, { 
                  orderStatus: ["invoiced"],
                  credential: resp.data,
                  endpoint: "vtex-status-cart"
                })
              } catch (error) {
                console.log("create-vtex-hook error")
              }
              this.$swal({
                icon: "success",
                title: "¡Vtex integrado!",
                timer: 1000,
                showConfirmButton: false,
              });
              this.loading = false;
              this.error = '';
              this.credentials = {};
              this.$emit('closeView');
              this.$emit('getIntegrations');
              try {                
                // CARGA DE PRODUCTOS
                const config = {
                  method: 'POST',
                  url: `${environment.url_automations}/charge_products_by_bot`,
                  data: {
                    "bot_id": this.bot_id
                  },
                }
                await axios.request(config)
              } catch (error) {
                console.log("loading products error",error)
              }
            }));
        }
    }
  }
};
</script>