import * as echarts from 'echarts';

export default {
  methods: {
    questionBarChart(id, title, months, names, values) {
      let chartDom = document.getElementById(id);
      var myChart = echarts.init(chartDom, null, {
        renderer: 'svg',
      });

      window.addEventListener('resize', () => {
        myChart.resize();
      });

      myChart.setOption({
        title: {
          text: title,
        },
        legend: {},
        tooltip: {},
        // dataset: {
        //   source: values,
        // },
        grid: { containLabel: true },
        xAxis: { type: 'value' },
        yAxis: { type: 'category', data: names },
        toolbox: {
          feature: {
            saveAsImage: {},
            magicType: {
              type: ['line', 'bar', 'stack'],
            },
          },
        },
        series: [
          {
            type: 'bar',
            itemStyle: { color: "#2981EF" },
            data: values[0]
          },
        ],
        textStyle: {
          fontFamily: "Manrope, sans-serif"
        },
      });
    }
  },
};
