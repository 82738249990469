<template><div></div></template>
<script>
export default {
  data() {
    return {};
  },
  created() {
    window.location.href =
      "https://accounts.jumpseller.com/oauth/authorize?client_id=c7176f690639bb06f46480e2cb9e3ca4986c79df1bdb996ac41f9b758e62066b&redirect_uri=https://ia.databot.cl/registro?shop=jumpseller&response_type=code&scope=write_jsapps";
  },
};
</script>
