<template>
  <section v-if="selected_bot" class="tabla-tickets">
    <div class="row justify-content-between align-items-center b-bottom">
      <h3 class="my-2">Tickets</h3>
      <!-- FILTROS -->
      <FadeTransition :duration="100">
        <div class="row align-items-center">
          <!-- Compañias -->
          <select v-if="listCompanies.length > 0" :class="{
            'select_text my-1 mr-3': true,
            active: filterPriority.length > 0
          }" @change="getAllTickets(1)" v-model="filterCompany">
            <option value>Filtrar por cliente</option>
            <option v-for="(company, companyIndex) in listCompanies" :key="`${company} - ${companyIndex}`"
              :value="company">{{ company }}
            </option>
          </select>

          <!-- Prioridad -->
          <select :class="{
            'select_text my-1 mr-3': true,
            active: filterPriority.length > 0
          }" v-model="filterPriority" @change="getAllTickets(1)">
            <option value>Filtrar por prioridad</option>
            <option v-for="(priority) in listPriorities" :key="priority">{{ capitalize(priority) }}</option>
          </select>

          <!-- Estado -->
          <select :class="{
            'select_text my-1 mr-3': true,
            active: filterState.length > 0
          }" v-model="filterState" @change="getAllTickets(1)">
            <option value>Filtrar por estado</option>
            <option v-for="(state) in listStates" :key="state">{{ capitalize(state) }}</option>
          </select>

          <!-- Encargados -->
          <select :class="{
            'select_text my-1 mr-3': true,
            active: filterAssigned.length > 0
          }" v-model="filterAssigned" @change="getAllTickets(1)">
            <option value>Filtrar por encargado</option>
            <option v-for="(assigned) in listAssigned" :key="assigned">{{ assigned }}</option>
          </select>

          <!-- FECHA -->
          <div class="input_box">
            <span class="btn-date" @click="empty">
              <i class="fas fa-times mb-0 mr-0" />
            </span>
            <input type="date" class="input_date sm my-1 mr-3" @change="getAllTickets(1)" v-model="filterTime" />
          </div>

          <!-- SEARCHBAR -->
          <div class="input_box">
            <span class="btn-date px-2">
              <i class="fas fa-search mr-0" />
            </span>
            <input placeholder="Buscar" v-model="filterSearch" @keyup="search" type="search"
              class="input_search sm mb-0 mr-3" />
          </div>
        </div>
      </FadeTransition>
    </div>
    <SpinnerLoading v-if="loading" />
    <!-- TABLA -->
    <template v-else-if="quantity > 0">
      <section class="table buefy" :key="loadingMessages">
        <b-table
          :range-before="rangeBefore"
          :range-after="rangeAfter"
          :data="tickets"
          :per-page="itemsPerPage"
          :current-page.sync="currentPage"
          :pagination-simple="true"
          :pagination-position="'bottom'"
          :default-sort-direction="'desc'"
          :sort-icon="'caret-up'"
          aria-next-label="Next page"
          aria-previous-label="Previous page"
          aria-page-label="Page"
          aria-current-label="Current page"
          icon-pack="fas"
          :key="tableKey"
          :checked-rows.sync="checkedRows"
          :selected.sync="selectedRow"
          :sticky-header="stickyHeaders"
          height="500"
          focusable
        >
          <template slot-scope="props">
            <b-table-column
              field="tracking_code"
              label="Código de seguimiento"
              sortable
            >
              <p class="e-show">
                {{ props.row.ticket.tracking_code }}
              </p>
            </b-table-column>

            <b-table-column
              field="client"
              label="Cliente"
              sortable
            >
              <p class="e-show">
                {{ props.row.ticket.company }}
                <span
                  :class="{
                    'i-btn i-chat-2 i-btn-sm i-st i-btn-hover': true,
                    active: props.row.ticket.id == selectedRow.id
                  }"
                  @click="openClientModal"
                ></span>
              </p>
            </b-table-column>

            <b-table-column
              field="email"
              label="Correo"
              sortable
            >
              <p class="e-show">
                {{ props.row.ticket.email }}
              </p>
            </b-table-column>

            <b-table-column
              field="priority"
              label="Prioridad"
              sortable
              class="limit-cell"
            >
              <!-- <p class="e-show">
                {{ props.row.ticket.priority }}
              </p> -->
              <select 
                v-model="props.row.ticket.priority" 
                @change="updateTicket('priority', props.row.ticket.priority, props.row.ticket.id)"
                class="w-100 mb-0" 
                :class="{
                  'priority-urgente': props.row.ticket.priority == 'urgente',
                  'priority-media': props.row.ticket.priority == 'media',
                  'priority-baja': props.row.ticket.priority == 'baja',
                  'priority-none': props.row.ticket.priority == 'sin prioridad asignada',
                }"
              >
                <option v-for="priority in listPriorities" :key="priority + 1" :value="priority">
                  <span >
                    {{ priority == 'sin prioridad asignada' ? 'Sin asignar' : capitalize(priority) }}
                  </span>
                </option>
              </select>
            </b-table-column>

            <b-table-column
              field="subject"
              label="Asunto"
              sortable
            >
              <p class="e-show text-truncate limit-cell-max">
                {{ props.row.ticket.subject }}
              </p>
            </b-table-column>

            <b-table-column
              field="description"
              label="Descripción"
              sortable
            >
              <p class="e-show text-truncate limit-cell-max">
                {{ props.row.ticket.description }}
              </p>
            </b-table-column>

            <b-table-column
              field="state"
              label="Estado"
              sortable
              class="limit-cell"
            >
              <select 
                v-model="props.row.ticket.state" 
                class="w-100 mb-0"
                @change="updateTicket('state', props.row.ticket.state, props.row.ticket.id)"
              >
                <option v-for="state in listStates" :key="state + 1" :value="state">
                  {{ capitalize(state) }}
                </option>
              </select>
            </b-table-column>

            <b-table-column
              field="comments"
              label="Notas"
              sortable
            >
              <span
                class="fas fa-eye i-btn i-btn-sm comment-icon"
                @click="openCommentModal"
              ></span>
            </b-table-column>

            <b-table-column
              field="assigned"
              label="Encargado"
              class="limit-cell"
              sortable
            >
              <!-- <p class="e-show">
                {{ props.row.ticket.assigned_to }}
              </p> -->
              <select 
                v-model="props.row.ticket.user_bot_id" 
                class="w-100 mb-0"
                @change="updateTicketAssigned(props.row.ticket.user_bot_id, props.row.ticket.id)"
              >
                <option :value="null">Sin asignar</option>
                <option v-for="agent in listAgents" :key="agent.user.id + agent.user.name" :value="agent.id">
                  {{ agent.user.name }}
                </option>
              </select>
            </b-table-column>

            <b-table-column
              field="messages"
              label="Mensajes"
              sortable
            >
              <p class="d-flex justify-content-center align-items-center">
                <i class="fas fa-comments i-btn i-btn-sm messages_button p-2" @click="openMessages(props.row)"></i>
              </p>
            </b-table-column>

            <b-table-column
              field="created"
              label="Creado"
              sortable
            >
              <p class="e-show">
                {{ $moment(props.row.ticket.created_at).format("DD/MM/YYYY - hh:mm a") }}
              </p>
            </b-table-column>

            <b-table-column
              field="modified"
              label="Modificado"
              sortable
            >
              <p class="e-show">
                {{ $moment(props.row.ticket.updated_at).format("DD/MM/YYYY - hh:mm a") }}
              </p>
            </b-table-column>

          </template>
        </b-table>
      </section>
    </template>
    <template v-else>
      <div class="no_data">
        <img class="icon" src="/img/icons/15-not_found.svg" alt="databot" />
        <p class="text-center text_sm my-3">
          No hay tickets registrados.
        </p>
      </div>
    </template>

    <!-- MODAL INFORMACION CLIENTE -->
    <template>
      <Transition name="slide-fade">
        <section class="right-modal" v-show="showClient" >
          <div class="modal-head">
            <div class="label-box">
              <div>
                <label class="mb-0" for="">Información</label>
              </div>
              <div class="d-flex align-items-center">
                <span @click="showClient = false" class="i-btn i-close i-btn-sm i-sm"></span>
              </div>
            </div>
          </div>
          <div class="modal-body">
            <div v-for="data in modalBodyData" class="d-flex row justify-content-between">
              <template v-if="data.key != 'Imágenes'">
                <p class="w-50">{{ data.key }}</p>
                <p class="w-50 font-weight-bold">{{ data.value }}</p>
              </template>
              <template v-else>
                <p class="w-50">{{ data.key }}</p>
                <div class="w-50 d-flex justify-content-start align-items-center mb-4">
                  <div v-for="img in data.value" class="mr-2">
                    <a :href="img" target="_blank">
                      <img :src="img" class="images">
                    </a>
                  </div>
                </div>
              </template>
            </div>
            <h4 class="pt-4 pb-3">Datos de cliente</h4>
            <div v-for="client in modalClientData" class="d-flex row justify-content-between">
              <p class="w-50">
                <i :class="`${client.icon}`" class="mirror"></i>
                {{ client.key }}
              </p>
              <a v-if="client.key == 'Correo'" :href="`mailto:${client.value}`" class="w-50">
                {{ client.value }}
              </a>
              <a v-else-if="client.key == 'Teléfono'" :href="`tel:${client.value}`" class="w-50">
                {{ client.value }}
              </a>
              <p v-else class="w-50 font-weight-bold">{{ client.value }}</p>
            </div>
          </div>
        </section>
      </Transition>
    </template>
    <!-- MODAL NOTAS -->
    <template>
      <Transition name="slide-fade">
        <section class="right-modal index" v-show="showComments">
          <div class="modal-head">
            <div class="label-box">
              <div>
                <label class="mb-0" for="">Notas de ticket - {{ selectedRow?.ticket?.tracking_code }}</label>
              </div>
              <div class="d-flex align-items-center">
                <span @click="showComments = false" class="i-btn i-close i-btn-sm i-sm"></span>
              </div>
            </div>
          </div>
          <div class="modal-body">
            <h4>Título de la nota</h4>
            <input type="text" placeholder="Ejem: Importante" v-model="noteInput.title">
            <h4>Mensaje</h4>
            <textarea  placeholder="Escribe la nota aqui" v-model="noteInput.body" />
            <div class="d-flex justify-content-end align-items-center">
              <!-- <span class="pointer" @click="cancelNote">Cancelar</span> -->
              <button class="btn btn-base px-4 ml-4" :class="loadingCreateNote && 'disabled'" @click="saveNote" :disabled="loadingCreateNote">
                Añadir
              </button>
            </div>
            <hr class="my-4" />
            <template v-if="selectedRow?.ticket?.ticket_notes.length > 0">
              <label for="" class="mb-3">Creadas <span class="i-btn i-btn-sm ml-2 px-2">{{ selectedRow?.ticket?.ticket_notes.length }}</span></label>
              <div
                class="card info_box bg_grey mb-4"
                v-for="note in selectedRow.ticket?.ticket_notes"
                :key="`comment-${note.id}`"
              >
                <div class="d-flex justify-content-between">
                  <div class="label-box text_xs">
                    <span class="m-0">Creada por {{ note.sender }}</span>
                    <span class="ml-2">
                      {{
                        $moment(note.created_at)
                        .format("DD/MM/YY - h:mm a")
                      }}
                    </span>
                  </div>
                  <div v-if="user.id == note.user_bot_id" class="ml-3 p-1 dropdown">
                    <img src="img/icons/elipsis-hor.svg" alt="elipsis-icon" class="pointer">
                    <div class="dropdown-content">
                      <p class="dropdown-item p-2 pointer" @click="openEditNote(note)"><i class="fas fa-edit text-dark"/> Editar</p>
                      <p class="dropdown-item p-2 pointer" @click="openDeleteNote(note)"><i class="fas fa-times text-dark"/> Eliminar</p>
                    </div>
                  </div>
                </div>
                <div
                  class="deleteModal d-flex justify-content-center align-items-center flex-column"
                  v-if="deleteModal.find(e=>e.id == note.id)"
                >
                  <h3>¿Estás seguro de eliminar esta nota?</h3>
                  <div class="d-flex justify-content-end">
                    <span v-if="!deleteModal.find(e=>e.id==note.id)?.state" class="text-dark px-3 py-2 mr-4 pointer font-weight-bold" @click="closeDeleteNote">Cancelar</span>
                    <button 
                      class="btn btn-outlined btn-danger px-3 p-2" 
                      :class="deleteModal.find(e=>e.id==note.id)?.state && 'disabled'"
                      :disabled="deleteModal.find(e=>e.id==note.id)?.state"
                      @click="deleteNote(note)"
                    >
                      <span class="text-white">{{ deleteModal.find(e=>e.id==note.id)?.state ? 'Eliminando...' : 'Eliminar' }}</span>
                    </button>
                  </div>
                </div>
                <div v-if="!editInput.find(e=>e.id===note.id)" class="mt-2">
                  <label>{{ note.title }} </label>
                  <p>{{ note.body }}</p>
                </div>
                <div v-else class="mt-3">
                  <input type="text" placeholder="Ejem: Importante" v-model="editInput.find(e=>e.id===note.id).title">
                  <textarea  placeholder="Escribe la nota aqui" v-model="editInput.find(e=>e.id===note.id).body" />
                  <div class="d-flex justify-content-end align-items-center">
                    <span class="pointer" @click="closeEditNote(note)">Cancelar</span>
                    <button 
                      class="btn btn-base px-4 ml-4"
                      :class="loadingEditNote.find(e=>e.id == note.id).state && 'disabled'" 
                      :disabled="loadingEditNote.find(e=>e.id == note.id).state" 
                      @click="editNote(note)"
                    >
                      Editar
                    </button>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </section>
      </Transition>
    </template>
    <div class="table-footer">
      <div class="col-md-4 pl-0">
        <span class="text_sm mr-3 mb-0">
          Visibles: {{ tickets.length == 1 ? tickets.length + " Ticket." : tickets.length + " Tickets." }}
        </span>
      </div>
      <div class="col-md-4 d-flex flex-column justify-content-center">
        <div class="d-flex justify-content-center">
          <p
            class="pagination__firstPage my-auto mx-4"
            :class="actual_page != 1 && 'allowed'"
            :style="`cursor: ${actual_page == 1 ? 'not-allowed' : 'pointer'}`"
            @click="actual_page != 1 && getAllTickets(1)"
          >
            1
          </p>
          <div class="number-input my-auto">
            <button
              class="btn-left left-arrow py-1 px-3"
              type="button"
              id="button-addon1"
              @click="
                actual_page != 1 && getAllTickets(Number(actual_page) - 1)
              "
              :disabled="actual_page == 1"
            ></button>
            <div>
              <input
                class="input_page m-0"
                :class="{ input_error: errorInputPage }"
                type="number"
                :max="pages"
                v-model="input_page"
                @keyup="inputPageSubmit"
                error="true"
              />
            </div>
            <button
              class="btn-right right-arrow py-1 px-3"
              type="button"
              id="button-addon2"
              :disabled="actual_page == pages"
              @click="
                actual_page != pages && getAllTickets(Number(actual_page) + 1)
              "
            ></button>
          </div>
          <p
            class="pagination__lastPage my-auto mx-4"
            :class="actual_page != pages && 'allowed'"
            :style="
              `cursor: ${actual_page == pages ? 'not-allowed' : 'pointer'}`
            "
            @click="actual_page != pages && getAllTickets(pages)"
          >
            {{ pages }}
          </p>
        </div>
        <span
          v-if="errorInputPage"
          class="text_sm text_error text-center mx-auto mt-2"
        >
          El valor ingresado debe ser entre 1 y {{ pages }}
        </span>
      </div>
      <div class="col-md-4 d-flex align-items-center justify-content-end pr-0">
        <!-- REVISAR -->
        <!-- <span v-if="totalLeadsId" class="text_sm mb-0 mr-3">{{ totalLeadsId.length }} leads en total.</span> -->
        <select
          name="items"
          class="select_text my-1 mr-3"
          @change="getAllTickets(1)"
          v-model="itemsPerPage"
        >
          <option value="15">15 tickets por página</option>
          <option value="30">30 tickets por página</option>
          <option value="50">50 tickets por página</option>
        </select>
      </div>
    </div>
    <Modal
      class="modal-2"
      ancho="600"
      alto="500"
      :show="showChat"
      @close="closeCommentModal"
    >
      <template slot="header">
        <h1 class="titulo__modal mb-4">
          Mensajes
        </h1>
      </template>
      <template slot="content">        
        <section class="chat_container d-flex flex-column align-items-center">
          <div class="accordion_container">
            <div class="accordion mt-3 mx-0 py-2 px-3 d-flex justify-content-between" :class="{ 'accordion_active': activeAccordion }" @click="handleAccordion">
              <span>Ticket {{ selectedTicket?.ticket?.tracking_code }}</span>          
              <span class="accordion-icon">{{ activeAccordion ? '-' : '+' }}</span>
            </div>
            <div class="accordion-item px-3 py-2 mx-0" :class="{ 'accordion-item_active': activeAccordion }">
              <h4>{{ selectedTicket?.ticket?.subject }}</h4>
              <p>{{ selectedTicket?.ticket?.description }}</p>
              <div class="d-flex justify-content-start align-items-center flex-wrap">
                <div v-for="image in selectedTicket?.ticket_files">
                  <a :href="image" target="_blank">
                    <img :src="image" class="chat_image mr-2">
                  </a>
                </div>
              </div>
            </div>
          </div>
          <SpinnerLoading v-if="loadingMessages" />
          <template v-else-if="messages.length > 0">
            <div class="chat w-100 h-100 my-2 d-flex flex-column" ref="chat">
              <div v-for="message in messages" v-if="message.body || message.comment_files_urls.length > 0" class="chat_bubble" :class="listAgents.find(e=>e.id == message.user_bot_id) ? 'chat_bubble_right ml-auto mr-4 my-2' : 'chat_bubble_left mr-auto ml-4 my-2'">
                <div>
                  <p v-if="message.body" class="text-left mb-2">{{ message.body }}</p>
                  <div class="d-flex justify-content-start align-items-center mb-2 flex-wrap">
                    <div v-for="image in message.comment_files_urls" class="m-1 pointer">
                      <a :href="image" target="_blank">
                        <img :src="image" class="chat_image">
                      </a>
                    </div>
                  </div>
                  <p class="text-right text_xs">{{ $moment(message.created_at).format("DD/MM/YY - h:mm a")}} </p>
                </div>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="d-flex justify-content-center align-items-center w-100 h-100">
              <div>
                <img class="icon" src="/img/icons/15-not_found.svg" alt="databot" />
                <p class="text-center text_sm my-3">
                  No hay mensajes
                </p>
              </div>
            </div>
          </template>
          <div class="w-100 px-4">
            <div v-for="(image, index) in images" class="file_images d-flex justify-content-between align-items-center p-2 my-1">
              <span>
                {{ image.name }}
              </span>
              <span class="pointer" @click="removeFile(index)">X</span>
            </div>
          </div>
          <div class="w-100 p-4 d-flex justify-content-between align-items-center mt-auto" >
            <input class="w-100 mb-0 mr-4" type="text" placeholder="Escriba su mensaje" v-model="inputMessage" @keyup="sendMessage"/>
            <input type="file" accept="image/png, image/jpeg, image/jpg" class="d-none" ref="input_file" @change="inputFile">
            <button class="btn btn-light h-100 mr-2" @click="buttonFile"><i class="fas fa-paperclip p-2 m-0"></i></button>
            <button class="btn btn-primary h-100" @click="sendMessage(null)"><i class="fas fa-paper-plane pl-1"></i></button>
          </div>
        </section>
      </template>
    </Modal>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import { SlideYUpTransition, FadeTransition } from 'vue2-transitions';
import dashboard_api from '../../dashboard_api';
import '../../assets/buefy_class.scss';
import '../../assets/main.scss';

export default {
  name: 'tickets',
  components: {
    FadeTransition,
    SlideYUpTransition
  },
  data() {
    return {
      tickets: [],
      allTickets: [],
      currentPage: 1,
      pages: 0,
      quantity: 0,
      itemsPerPage: 15,
      loading: false,
      listCompanies: [],
      listPriorities: [
        'sin prioridad asignada',
        'urgente',
        'media',
        'baja'
      ],
      listStates: [
        'pendiente',
        'tomado',
        'terminado',
        'cancelado'
      ],
      listAssigned: [],
      listAgents: [],
      filterCompany: '',
      filterPriority: '',
      filterState: '',
      filterAssigned: '',
      filterTime: '',
      filterSearch: '',
      // TABLE PROPS
      rangeBefore: 1,
      rangeAfter:1,
      tableKey: 0,
      checkedRows: [],
      selectedRow: {},
      stickyHeaders: true,
      actual_page: 1,
      input_page: this.actual_page,
      errorInputPage: false,
      showClient: false,
      showComments: false,
      showChat: false,
      messages: [],
      loadingMessages: false,
      inputMessage: '',
      selectedTicket: '',
      modalBodyData: [],
      userbot: [],
      modalClientData: [],
      noteInput: {
        title: '',
        body: ''
      },
      selectedNote: null,
      editInput: [],
      loadingCreateNote: false,
      loadingEditNote: [],
      deleteModal: [],
      activeAccordion: false,
      images: []
    }
  },
  created() {
    window.scrollTo(0, 0);
  },
  computed: {
    ...mapState(['user', 'selected_bot', 'active_view', 'plan_name']),
  },
  watch: {
    selectedRow(val) {
      val.ticket.ticket_notes.reverse();
      this.modalBodyData = [
        {
          key: "Estado",
          value: this.capitalize(val.ticket.state)
        },
        {
          key: "Asignado",
          value: this.capitalize(val.ticket.assigned_to)
        },
        {
          key: "Área",
          value: this.capitalize(val.ticket.category)
        },
        {
          key: "Inicio",
          value: this.$moment(val.ticket.created_at).format("DD/MM/YYYY - hh:mm a")
        },
        {
          key: "Pregunta inicial",
          value: this.capitalize(val.ticket.description)
        },
        {
          key: "Imágenes",
          value: val.ticket_files
        },
      ]
      this.modalClientData = [
        {
          icon: "fas fa-user",
          key: "Nombre",
          value: `${this.capitalize(val.ticket.name)}`
        },
        {
          icon: "fas fa-building",
          key: "Empresa",
          value: this.capitalize(val.ticket.company)
        },
        {
          icon: "fas fa-envelope",
          key: "Correo",
          value: val.ticket.email
        },
        {
          icon: "fas fa-phone",
          key: "Teléfono",
          value: val.ticket.phone
        },
      ]
    }
  },
  async mounted() {
    try {
      this.loading = true
      await this.getUserBot()
      await this.getAssigned()
      await this.getCompanies()
      await this.getAllAgents()
      await this.getAllTickets(this.currentPage)
    } catch (error) {
      console.log("error en el mounted", error);
    } finally {
      this.loading = false
    }
  },
  methods: {
    removeFile(index){
      this.images.splice(index, 1)
    },
    buttonFile() {
      this.$refs.input_file.click()
    },
    inputFile(){
      if(this.images.length < 5){
        const newFile = this.$refs.input_file.files[0];
        this.$refs.input_file.value = null
        this.images.push(newFile)        
      }
    },
    handleAccordion() {
      this.activeAccordion = !this.activeAccordion
    },
    capitalize(string) {
      if(string)
        return string.charAt(0).toUpperCase() + string.slice(1)
      else return string
    },
    async openMessages(ticket){
      try {
        this.showChat = true
        this.loadingMessages = true
        this.selectedTicket = ticket
        const response = (await dashboard_api.get(`/comments/by_ticket_id/${ticket.ticket.id}`)).data
        this.messages = response
        setTimeout(() => {
          // console.log("this.$refs.chat", this.$refs.chat)
          this.$refs.chat.scrollTo(0, this.$refs.chat.scrollHeight);
        }, "500");
      } catch (error) {
        console.log(error)
      } finally {
        this.loadingMessages = false
      }
    },
    async getUserBot(){
      try {
        const response = await dashboard_api.get(`/userbot/show_bots_for_user/${this.user.id}`)
        const filterBot = response.data.find(e => e.bot_id == this.selected_bot.id)

        this.userbot = filterBot.id
      } catch (error) {
        console.log(error)
      }
    },
    async sendMessage(event){
      try {
        if(event && event.keyCode != 13) return
        if(!this.inputMessage && this.images.length == 0) return
        let formData = new FormData();
        if(this.images.length > 0) {
          this.images.map(e => {
            formData.append('comment_files[]', e);
          })
        }
        this.images = []
        const body = this.inputMessage
        this.inputMessage = ''
        this.loadingMessages = true
        formData.append('comment[sender]', this.user.name)
        formData.append('comment[body]', body)
        formData.append('comment[ticket_id]', this.selectedTicket.ticket.id)
        formData.append('comment[user_bot_id]', this.userbot)

        await dashboard_api.post("/comments", formData);
        this.openMessages(this.selectedTicket)
        console.log('selectedTicket->', this.selectedTicket);
        console.log("listAgents->", this.listAgents);

        let assigned = this.listAgents.find(el => el.user.name == this.selectedTicket.ticket.assigned_to);
        console.log("assigned->", assigned);

        let new_obj = {};
        new_obj = this.selectedTicket;
        new_obj.ticket.user_bot_id = assigned?.user_id || null;
        this.$store.dispatch('notificationsModule/createNewMessageTicket', new_obj);
      } catch (error) {
        console.log(error)
      } finally {
        this.loadingMessages = false
      }
    },
    async getAllTickets(page) {
      try {
        this.input_page = page
        this.actual_page = page
        let filters = {
          "bot_id": this.selected_bot.id
          // "user_bot_id": Number(this.user.id)
        };
        // console.log("this.filterPriority",this.filterPriority);
        // console.log("this.filterState",this.filterState);
        if (this.filterCompany) filters.company = this.filterCompany;
        if (this.filterPriority) filters.priority = this.filterPriority.toLowerCase();
        if (this.filterState) filters.state = this.filterState.toLowerCase();
        if (this.filterAssigned) filters.assigned_to = this.filterAssigned;
        if (this.filterTime) filters.created_at = this.filterTime;

        this.loading = true
        const response = (await dashboard_api.post('/tickets/tickets_by_bot', {
          "page": page,
          "items": this.itemsPerPage,
          "filters": filters
        })).data
        console.log("response->", response);
        
        this.tickets = response.tickets
        this.allTickets = response.tickets
        this.pages = response.pages
        this.quantity = response.quantity
        this.tableKey += 1; // esto hace que el componente de la tabla se renderice de nuevo para evitar bugs
      } catch (error) {
        console.log("error al obtener tickets", error);
      } finally {
        this.loading = false
      }
    },
    async getCompanies() {
      try {
        this.listCompanies = (await dashboard_api.get(`/tickets/list_company/${this.selected_bot.id}`)).data
      } catch (error) {
        console.log("error al obtener compañia", error);
      }
    },
    async getAssigned() {
      try {
        this.listAssigned = (await dashboard_api.get(`/tickets/list_assigned_to/${this.selected_bot.id}`)).data
        this.listAssigned = this.listAssigned.filter(e => e)
      } catch (error) {
        console.log("error al obtener agentes asignados", error);
      }
    },
    async getAllAgents(){
      try {
        this.listAgents = (await dashboard_api.get(`/userbot/user_for_bot/${this.selected_bot.id}`)).data
      } catch (error) {
        console.log("error al obtener agentes asignados", error);
      }
    },
    async updateTicket(type, data, id){
      try {
        this.loading = true
        await dashboard_api.put(`/tickets/${id}`,{
          [type]: data
        })
      } catch (error) {
        console.log("error al modificar ticket", error)
      }finally{
        this.loading = false
      }
    },
    async updateTicketAssigned(user_bot_id, id){
      try {
        this.loading = true
        const name = this.listAgents.find(e=>e.id == user_bot_id).user.name
        await dashboard_api.put(`/tickets/${id}`,{
          assigned_to: name,
          user_bot_id: user_bot_id
        })
      } catch (error) {
        console.log("error al modificar ticket", error)
      }finally{
        this.loading = false
      }
    },
    empty() {
      this.filterTime = '';
      this.getAllTickets(1);
    },
    search() {
      const filter = this.filterSearch.toLowerCase()
      this.tickets = []
      this.allTickets.forEach(ticket=>{
        if (
          ticket.ticket.tracking_code.toLowerCase().search(filter) >= 0 || 
          ticket.ticket.email.toLowerCase().search(filter) >= 0 || 
          ticket.ticket.subject.toLowerCase().search(filter) >= 0 || 
          ticket.ticket.description.toLowerCase().search(filter) >= 0 
        )
          this.tickets = [...this.tickets, ticket]
      })
    },
    inputPageSubmit(e) {
      this.errorInputPage = false;
      if (e.key === 'Enter' || e.keyCode === 13) {
        if (this.input_page >= 1 && this.input_page <= this.pages) {
          this.getAllTickets(Number(this.input_page));
        } else this.errorInputPage = true;
      }
    },
    openCommentModal(){
      this.showComments = true
      this.showClient = false
    },
    closeCommentModal(){
      this.showChat = false
      this.activeAccordion = false
    },
    openClientModal(){
      this.showComments = false
      this.showClient = true
    },
    async saveNote() {
      try {
        this.loadingCreateNote = true;

        // CREAR NOTA
        if (!this.noteInput.title && !this.noteInput.body) return;

        const response = await dashboard_api.post("/ticket_notes", {
          "ticket_note": {
            "title": this.noteInput.title,
            "sender": this.user.name,
            "body": this.noteInput.body,
            "ticket_id": this.selectedRow.ticket.id,
            "user_bot_id": this.userbot // userbot id 
          }
        })
        console.log("response->", response);
        console.log("selectedRow->", this.selectedRow);
        this.selectedRow.ticket.ticket_notes.unshift(response.data);
        this.cancelNote();
      } catch (error) {
        console.log("error al guardar nota", error)
      } finally {
        this.loadingCreateNote = false
      }
    },
    cancelNote(){
      this.noteInput.title = ''
      this.noteInput.body = ''
      this.selectedNote = null
    },    
    async deleteNote(note) {
      try {
        this.deleteModal.find(e=>e.id==note.id).state = true
        const index = this.selectedRow.ticket_notes.findIndex(e => e.id == note.id)
        await dashboard_api.delete(`/ticket_notes/${note.id}`)
        this.selectedRow.ticket_notes.splice(index, 1)
      } catch (error) {
        console.log("error al eliminar nota",error)
      } finally{
        this.deleteModal.find(e=>e.id==note.id).state = false
        this.closeDeleteNote(note)
      }
    },
    openDeleteNote(note){
      this.deleteModal.push({
        id: note.id,
        state: false
      })
    },
    closeDeleteNote(note) {
      const index = this.deleteModal.findIndex(e => e.id == note.id)
      this.deleteModal.splice(index, 1)
    },
    openEditNote(note) {
      if (!this.editInput.find(e => e.id == note.id)) {
        this.loadingEditNote.push({
          id: note.id,
          state: false
        });
        this.editInput.push({ 
          id: note.id,
          title: note.title,
          body: note.body
        });
      };
    },
    closeEditNote(note){
      const index = this.editInput.findIndex(e => e.id == note.id)
      const indexLoading = this.loadingEditNote.findIndex(e => e.id == note.id)
      this.editInput.splice(index, 1)
      this.loadingEditNote.splice(indexLoading, 1)
    },
    async editNote(note){
      try {
        const input = this.editInput.find(e=>e.id == note.id)
        if(note.title == input.title && note.body == input.body) return
        this.loadingEditNote.find(e=>e.id==note.id).state = true
        let ticket_note = {}

        if(input.title) ticket_note.title = input.title
        if(input.body) ticket_note.body = input.body

        ticket_note.sender = this.user.name
        const response = await dashboard_api.put(`/ticket_notes/${input.id}`,{
          ticket_note
        })
        const index = this.selectedRow.ticket_notes.findIndex(e => e.id == response.data.id)
        this.selectedRow.ticket_notes[index] = response.data
        this.closeEditNote(note)
      } catch (error) {
        console.log("Error al editar nota",error)
      } finally {
        this.loadingEditNote.find(e=>e.id==note.id).state = false
      }
    }
  }
}
</script>

<style lang="scss">
.tabla-tickets {
  .buefy .b-table .table {
    border: none;
  }
}
</style>

<style lang="scss" scoped>
.tabla-tickets {
  width: 100%;
  height: calc(100vh - 90px);
  display: grid;
  grid-template-rows: auto 2fr 90px;

  .bg-spinner {
    position: relative;
    height: calc(80vh - 90px);
  }
  .modal-2 {
    z-index: 2000;
  }
  .table {
    height: 100%;
    overflow: scroll !important;

    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
      background-color: transparent;
    }

    /* Color de fondo y radius esquinas del thumb */
    &::-webkit-scrollbar-thumb {
      background: #e8e8e8;
      border-radius: 2rem;
      margin-bottom: 1rem;
      transition: 0.5s;

      /* Cambiamos el fondo en hover */
      &:hover {
        transition: 0.5s;
        background: #cdcdcd;
      }

      /* Cambiamos el fondo en active */
      &:active {
        background-color: #cdcdcd;
      }
    }
  }

  .table-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    border-top: 1px solid #e8e8e8;
  }

  .icon_rating {
    width: 1.5rem;
  }

  .select-css {
    background-color: #767676;
    display: block;
    font-size: 12px !important;
    outline: none;
    font-weight: 700;
    color: #fff;
    text-align: center;
    text-align-last: center;
    letter-spacing: none;
    padding: 0.25rem 0.5rem;
    max-width: 100%;
    box-sizing: border-box;
    margin: 0;
    border: none;
    text-transform: capitalize;
    border-radius: 0.5rem;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-image: none !important;
    box-shadow: none !important;
    cursor: pointer;

    @media (max-width: 768px) {
      width: 30%;
    }
  }

  .fa-whatsapp {
    font-size: 0.9rem;
    margin-right: 0.2rem;
    color: #00bb2d;

    &:hover {
      color: #767676;
      transition: 0.5s;
    }
  }

  .fa-whatsapp.red {
    color: #ff6565;
  }

  .input_page {
    width: 80px;
  }

  .no-state {
    text-transform: capitalize;
    font-size: 12px;
    color: #b2b2b2;
    display: flex;
    justify-content: center;
    border: none;
    padding: 0.25rem 0.5rem;
    margin: auto 0;
    border-radius: 0;
  }

  .tooltip_text {
    background-color: #fafafa;
    border: 1px solid #cdcdcd;
    color: #767676;
    text-transform: none;
    text-align: center;
    white-space: normal;
    font-size: 10px;
    width: 113px;
    padding: 0.25rem;
    bottom: -0.44rem;
    left: -1rem;

    &::after {
      position: absolute;
      bottom: 0;
      left: 0;
      margin-left: 0;
      border-width: 0;
      border-style: none;
      border-color: none;
    }
  }

  .no-state_custom {
    width: 100px;
    justify-content: left;
  }

  .select_sm {
    font-size: 12px;
    color: #767676;

    @media (max-width: 768px) {
      margin-right: 0;
    }
  }

  .column-input {
    width: auto;
    appearance: none;
    padding: 0.25rem 0.5rem;
    margin: 0;
    border: 1px solid #cdcdcd;
    box-shadow: none;
    font-family: 'Manrope', 'sans-serif';
  }

  .multiselect {
    @media (max-width: 1024px) {
      max-width: 1000px;
      width: 100%;
      font-size: 12px;
    }

    .checkbox-id {
      width: 190px;
      top: 40px;
      left: 0.5rem;
      border: 1px solid #e8e8e8;
      border-radius: 0.5rem;
      padding-bottom: 0;

      .checkboxes-item {
        display: flex;
        align-items: center;
        width: 100%;
        font-size: 12px;
        color: #767676;
        font-weight: 400;
        padding: 0.25rem 0.75rem;
        margin-bottom: 0;
        cursor: pointer;
      }
    }
  }

  .no_data {
    width: 100%;
    height: 100%;
    background-color: #fafafa;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .list-w {
    width: 120px !important;
  }
  span.tag-text {
    padding: 1px 10px 1px 10px;
  }
  .text-state-fill {
    display: inline-block;
    margin: 0 0.5rem 0 0;
  }
  .tooltip-custom {
    z-index: 4;
  }
  .width-column {
    max-width: 390px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .b-bottom {
    padding: 1.5rem 2rem;
    border-bottom: 1px solid #e8e8e8;
  }
  .upgrade-label {
    font-size: 10px;
    display: flex;
    align-items: center;
    border: none;
    padding: .2rem .75rem !important;
    top: 1.5rem;
    right: 0;
    height: 22px;
    cursor: pointer;
  }
  .i-btn-hover {
    opacity: 0;
    transition: .25s;

    &:hover,
    &.active {
      opacity: 1;
    }
  }

  .e-show:hover {
    .i-btn-hover {
      opacity: 1;
    }
  }

  .flag-w {
    min-width: 40px;
  }

  .tooltip_text-sm {
    top: -100%;
    left: -.25rem;
    right: 0;
    width: 110%;

    &::after {
      content: '';
      display: flex;
      align-items: center;
      margin: 0;
      position: absolute;
      margin-left: 0;
      border-width: 0;
      border-style: none;
      border-color: none;
    }
  }

  .third-outline:hover {
    .tooltip_text-sm {
      visibility: visible;
      transition: 0.25s;
      opacity: 1;
      z-index: 250 !important;
      top: -1rem;
      left: -.25rem;
      width: 110%;
    }
  }

  // .third-outline .tooltip_text-sm {
  //   opacity: 0;
  //   visibility: hidden;
  // }
  .slide-fade-enter-active {
    transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
  }

  .slide-fade-leave-active {
    transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
  }

  .slide-fade-enter,
  .slide-fade-leave-to {
    transform: translateX(20px);
    opacity: 0;
  }
  .right-modal {
    position: absolute;
    top: 0;
    right: 0;
    height: 100vh;
    width: 480px;
    background-color: #fff;
    box-shadow: 0 0 60px rgba(0, 0, 0, 0.2);
    z-index: 200;
    overflow-y: hidden;

    .modal-head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 2rem;
      width: 100%;
      background-color: #fafafa;
      height: 80px;
      border-bottom: 1px solid #e8e8e8;
    }
    .modal-body {
      padding: 2rem;
      height: calc(92.25vh);
      overflow-y: scroll;
      background-color: #fff;
    }
  }
  .comment-icon {
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover{
      color: #2981EF;
    }
  }
  .pointer {
    cursor: pointer
  }
  .dropdown {
    position: relative;
    &-item {
      width: 100%;
      user-select: none;
      border-radius: .25rem;

      &:hover {
        background-color: #f2f2f2;
      }
    }
    &-content{
      display: none;
      position: absolute;
      top: -1rem;
      right: -1rem;
      background-color: white;
      padding: .75rem;
      border-radius: .5rem;
      box-shadow: 5px 5px 1rem rgba(0,0,0,0.2);
      min-width: 100px;
    }
    &:hover {
      .dropdown-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;        
      }
    }
  }
  .deleteModal {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    backdrop-filter: blur(3px);
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 1rem;
  }
  .messages_button {
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      color: #2A81EF;
    }
  }
  .chat {
    scroll-behavior: smooth;
    max-width: 100%;
    overflow: auto;
    &_image {
      width: 75px;
      height: 75px;
      object-fit: cover;
      border:1px solid #bbb; 
      border-radius: 5px;
    }
    &::-webkit-scrollbar {
      width: 7px;
    }
    &::-webkit-scrollbar-thumb {
      visibility: visible;
      border-radius: 2rem;
      background: #cdcdcd;
      transition: .25s;

      &:hover, &:active {
        background: #afafaf;
      }
    }
    &_bubble {
      border-radius: 1rem;
      padding: 0.75rem 1rem;
      max-width: 70%;
      &_right {
        background-color: #eff6ff;
        border: 1px solid #bfdbff;
        float: right
      }
      &_left {
        background-color: #fafafa;
        border: 1px solid #cdcdcd;
        float: left
      }
    }
    &_container {
      height: 80vh;
      overflow-y: auto;
    }
  }
  .accordion {
    background: #eee;
    color:black;
    border-radius: 5px;
    user-select: none;
    transition: background 0.4s ease;
    &:hover {
      background: #ddd;
    }
    // width: calc(100% - 32px);
    cursor: pointer;
    &_active {
      border-bottom-left-radius: 0px;  
      border-bottom-right-radius: 0px;  
    }
    &-icon {
      font-size: 1.2rem;
    }
    &_container {
      width: calc(100% - 32px)
    }
    &-item {
      // width: calc(100% - 32px);
      background-color: #fff;
      display: none;
      overflow: hidden;
      text-align: left;
      border: 1px solid #ddd;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      &_active {
        display: block;
        overflow: visible;
      }
    }
  }
  .limit-cell {
    min-width: 140px;
    &-max {
      max-width: 150px;
    }
  }
  .priority-urgente,
  .priority-media,
  .priority-baja{
    color: white
  }
  .priority-urgente {
    background-color: #F5365C !important    
  }

  .priority-media {
    background-color: #FFCD38 !important    
  }  
  .priority-baja {
    background-color: #2A81EF !important
  }
  .priority-none {
    background-color: white;
    color: rgb(118, 118, 118)
  }
  .mirror {
    transform: scaleX(-1)
  }
  .text-white {
    color: white
  }
  .file_images {
    background: #f5f5f5;
    border-radius: 10px;
  }
  .pointer {
    cursor: pointer;
    user-select: none;
  }
  .images {
    width: 75px;
    height: 75px;
    object-fit: cover;
    border:1px solid #bbb; 
    border-radius: 5px;
  }
}
</style>
