<template>
  <section class="list-color">
    <div class="tag-input">
      <input
        class="input-select"
        type="text"
        maxlength="50"
        @keyup="handleTyping"
        :placeholder="placeholderInput"
        v-model.trim="tag"
      />
      <i class="close-list fas fa-times ml-2 mr-0 pointer" @click.stop="closeModalColor()" />
    </div>
    <div class="delete-tag d-flex align-items-center pointer" @click.stop="remove()">
      <span class="tagInfo">
        <i class="fas fa-trash-alt" />
          Eliminar
      </span>
    </div>
    <div>
      <p class="tagInfo">{{ tagInfo }}</p>
    </div>
    <div class="list-tags pb-3 px-3">
      <div
        class="tags-select pointer"
        v-for="(_color, index) in colorProps"
        :key="index"
        @click.stop="colorChange(_color.hex)"
      >
        <div class="bg-tag d-flex align-items-center py-2">
          <div
            class="color-tag"
            :style="[{ backgroundColor: _color.hex }]"
          ></div>
          <span class="text-sm ml-2">
            {{ _color.name }}
          </span>
          <div class="ml-auto">
            <img
              class="table-icons i-check ml-auto"
              src="/img/icons/dashboard/check-icon.svg"
              alt="check"
              v-show="_color.hex == tagSelect.label_color"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: ['colorProps', 'tagSelect', 'allTags', 'tagName'],
  data() {
    return {
      tag: this.tagName,
      tagInfo: 'Seleccionar color',
      tagExistsInput: '',
      listExist: [],
      placeholderInput: 'Nombre del tag',
    };
  },
  watch: {
    tagName: {
      immediate: true,
      handler(val) {
        this.tag = this.tagName;
      },
    },
  },
  methods: {
    closeModalColor() {
      this.$emit('closeModalColor');
      this.tag = this.tagName;
    },
    remove() {
      console.log("tagSelect->", this.tagSelect);
      this.$swal({
        title: '¿Eliminar tag?',
        text: 'Esta acción es permanentemente y afectará a todos los leads',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Eliminar',
        cancelButtonText: 'Cancelar',
      }).then(result => {
        if (result.isConfirmed) {
          this.$swal({
            title: 'Eliminando..'
          });
          this.$swal.showLoading();

          this.$emit('deleteTag', this.tagSelect);
        } else {
          console.log('No se borro');
        }
      });
    },
    colorChange(color) {
      this.editTag(this.tagSelect.label_name, color);
    },
    editTag(tag, color) {
      if (tag.length <= 15) this.$emit('update-label', { tag, color, tagSelect: this.tagSelect });
      else this.tagInfo = "Máximo de 15 caracteres";
    },
    tagExists(tag) {
      return this.allTags.some(ele => ele.label_name == tag);
    },
    handleTyping(e) {
      if (e.keyCode === 188 || e.keyCode === 13) {
        let tagEdit = this.tag.replace(/,/g, '');
        let tagLowerEdit = tagEdit.toLowerCase();
        if (!this.tagExists(tagLowerEdit)) {
          this.editTag(tagLowerEdit, this.tagSelect.label_color || this.tagSelect.color);
        } else {
          this.$swal({
            icon: 'error',
            title: 'Este tag ya existe',
            timer: 1000,
            showConfirmButton: false,
          });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.list-color {
  position: absolute;
  right: -190px;
  width: 260px;
  max-width: 100%;
  background-color: #fff;
  border-radius: 0.5rem;
  z-index: 18;
  animation-name: fade-menu;
  animation-duration: 0.25s;
  box-shadow: 10px 20px 60px rgba(0, 0, 0, 0.2);
  border: 1px solid transparent;
  transition: 0.5s;

  input {
    width: 100%;
    padding: 0;
    border: none;
    background-color: #fafafa;
    border-radius: 0;
    font-weight: 400;
    color: #181818;
  }
  .tag-input {
    display: flex;
    background-color: #fafafa;
    align-items: center;
    height: 48px;
  }
}
.text-state-fill {
  margin: 0.5rem 0 0.5rem 0;
}

.tags {
  background-color: #fafafa;
  margin: 0 !important;
}

.list:has(input:focus) {
  transition: 0.5s;
  border-color: #2981ef;
}
.close-list {
  margin-right: 7px !important;
  font-size: 12px;
  padding: 3px 5px;
  border-radius: 10px;
  z-index: 99;
  background-color: #767676;
  color: white;
}

.tags-select {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  &:hover {
    background-color: #fafafa;
  }
}
.pl-3,
.px-3 {
  padding-left: 0 !important;
}
.list-tags.pb-3.px-3 {
  padding: 0 0 10px 0 !important;
}
.tagInfo {
  padding: 10px;
  font-size: 12px;
  font-weight: 400;
  color: #b2b2b2;
  margin-left: 2px;
}

.tags {
  flex-wrap: nowrap;
  margin-left: 10px;
}

.bg-tag {
  margin-left: 10px;
  width: 90%;
}

.buefy .tags:not(:last-child) {
  margin-left: 10px !important;
}
.text-enter-tag {
  font-size: 11px;
  font-weight: 400;
  color: #b2b2b2;
  padding: 10px 12px;

  span {
    color: #2a81ef;
  }
}
img.table-icons.i-delete {
  width: 17px;
}
.color-tag {
  width: 20px;
  height: 20px;
  border-radius: 6px;
}
.delete-tag {
  border-top: 1px solid transparent;
  border-bottom: 1px solid #e8e8e8;
  transition: 0.25s;

  &:hover {
    border-top: 1px solid #fff;
    background-color: #fafafa;

    span {
      color: #181818;
      transition: 0.25s;
      i {
        color: #181818;
        transition: 0.25s;
      }
    }
  }

  span {
    font-size: 14px;
    transition: 0.25s;
    i {
      font-size: 1rem;
      transition: 0.25s;
    }
  }
}
.input-select {
  margin-left: 12px;
}
.check-tag {
  background-color: #fafafa;
}
.pointer {
  cursor: pointer;
}
</style>
