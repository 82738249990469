<template>
  <section class="metrics" :key="selected_bot.id">
    <div class="row justify-content-between align-items-center b-bottom mb-4">
      <div class="label-box">
        <h3 class="mb-0">Métricas de {{ selected_bot.bot_name }}</h3>
        <div class="row justify-content-end align-items-center">
          <select class="select_text mb-0" v-model="filter">
            <option value="1">Últimos 2 meses</option>
            <option value="2">Últimos 4 meses</option>
            <option value="3">Últimos 6 meses</option>
            <option value="4">Últimos 12 meses</option>
          </select>
          <span
            v-for="(item, index) in filtered_items"
            @click="selected_item = item"
            :class="{
              'btn-select bg_grey desktop ml-3 mb-0': true,
              selected: selected_item.name == item.name
            }"
            :key="index"
          >{{ item.name }}</span>
          <select class="select_text mobile ml-3 mb-0" v-model="selected_item">
            <option v-for="(item, index) in filtered_items" :value="item">{{ item.name }}</option>
          </select>
        </div>
      </div>
    </div>
    <div class="col">
      <FadeTransition :duration="200" v-show="selected_item.name == 'Conversaciones y leads' || selected_item.name == 'Todo'" :key="'1' + filter">
        <div>
          <div class="row">
            <div class="col-md-6 px-0">
              <div class="row">
                <DataCardChart
                  type="number"
                  title="Conversaciones"
                  chart_id="current_conversations"
                  :label="`En ${current_month}`"
                  :current_month_value="current_month_conversations"
                  :last_month_value="last_month_conversations"
                  :columns="6"
                  :loading="all_loadings"
                />
                <DataCardChart
                  type="number"
                  title="Leads"
                  chart_id="current_leads"
                  :label="`En ${current_month}`"
                  :current_month_value="current_month_lead"
                  :last_month_value="last_month_lead"
                  :columns="6"
                  :loading="all_loadings"
                />
              </div>
            </div>
            <div class="col-md-6 px-0">
              <div class="row">
                <DataCardChart
                  type="number"
                  lang="es"
                  title="Calificación promedio"
                  chart_id="califications_average"
                  :label="`En ${current_month}`"
                  :current_month_value="current_month_calification_average"
                  :last_month_value="last_month_calification_average"
                  :columns="6"
                  :loading="all_loadings"
                />
                <DataCardChart
                  type="number"
                  lang="es"
                  title="Leads fuera de horario"
                  chart_id="out_hour_leads"
                  :label="`En ${current_month}`"
                  :current_month_value="current_lead_out_hour"
                  :last_month_value="last_lead_out_hour"
                  :columns="6"
                  :loading="all_loadings"
                />
              </div>
            </div>
          </div>
          <h3 v-if="selected_item.name == 'Todo'" class="pl-3 my-5">Conversaciones y leads</h3>
          <div class="row">
            <DataChart
              :disabled="no_data"
              id="get_conversations"
              name="Conversaciones mensuales"
              type="bar"
              :column="6"
              tooltip="Conteo de conversaciones realizadas dentro del periodo seleccionado, agrupadas por mes."
            />
            <DataChart
              :disabled="no_data"
              id="get_leads"
              name="Leads mensuales"
              type="bar"
              :column="6"
              tooltip="Conteo de leads calificados (Con nombre y número de teléfono o correo) obtenidos dentro del periodo seleccionado. Incluye registros obtenidos desde el chatbot, cargas masivas etc., agrupados por mes."
            />
          </div>
          <div class="row">
            <DataChart
              :disabled="no_data"
              id="resolved_by_bot"
              name="Conversaciones mensuales resueltas por el chatbot"
              type="bar"
              :column="6"
              tooltip="Cantidad de conversaciones resueltas por el chatbot con calificación sobre 2, dentro del periodo y sin intervención de agente."
            />
            <DataChart
              :disabled="no_data"
              id="resolved_by_bot_percent"
              name="Porcentaje de conversaciones resueltas por el chatbot"
              type="donut"
              :column="6"
              tooltip="Porcentaje de conversaciones resueltas por el chatbot con calificación sobre 2, dentro del periodo y sin intervención de agente."
            />
          </div>
          <div class="row">
            <DataChart
              :disabled="no_data"
              id="get_interactions"
              name="Top 5 días con más conversaciones de cada mes"
              type="bar"
              :column="6"
              tooltip="Se consideran los 5 días con mayor cantidad de conversaciones por cada mes dentro del periodo seleccionado."
            />
            <DataChart
              :disabled="no_data"
              id="count_califications"
              name="Conteo de calificaciones"
              type="stacked"
              :column="6"
              tooltip="Listado que considera solo las conversaciones con calificación y dentro del periodo seleccionado."
            />
          </div>
          <div class="row">
            <DataChart
              :disabled="no_data"
              id="conversations_out_time"
              name="Conversaciones y horarios de atención por mes"
              type="multi-bar"
              :column="6"
              tooltip="Conteo de conversaciones captadas por el chatbot dentro y fuera del horario de atención, agrupadas por mes. Para los chatbot que no tengan configurado su horario de atención, se considerará de Lunes a Viernes entre 8:00 y 18:00 hrs, en la zona horaria de America/Santiago."
            />
            <DataChart
              :disabled="no_data"
              id="conversations_and_hours"
              name="Porcentaje de conversaciones en horarios de atención"
              type="multi-bar"
              :column="6"
              tooltip="Porcentaje de conversaciones captadas por el chatbot dentro y fuera del horario de atención, agrupadas por mes. Para los chatbot que no tengan configurado su horario de atención, se considerará de Lunes a Viernes entre 8:00 y 18:00 hrs, en la zona horaria de America/Santiago."
            />
          </div>
          <div class="row">
            <DataChart
              :disabled="no_data"
              id="leads_out_time"
              name="Leads y horarios de atención"
              type="multi-bar"
              :column="6"
              tooltip="Cantidad de leads calificados (que tengan nombre y número de teléfono o correo) que se captaron dentro y fuera del horario de atención, agrupados por mes. Para los chatbot que no tengan configurado su horario de atención, se considerará de Lunes a Viernes entre 8:00 y 18:00 hrs, en la zona horaria de America/Santiago."
            />
            <DataChart
              :disabled="no_data"
              id="leads_and_hours"
              name="Leads y horarios de atención"
              type="multi-bar"
              :column="6"
              tooltip="Porcentaje de leads calificados (que tengan nombre y número de teléfono o correo) que se captaron dentro y fuera del horario de atención, agrupados por mes. Para los chatbot que no tengan configurado su horario de atención, se considerará de Lunes a Viernes entre 8:00 y 18:00 hrs, en la zona horaria de America/Santiago."
            />
          </div>
          <DataChart
            :disabled="no_data"
            id="bot_average_calification"
            name="Calificación promedio del chatbot"
            type="bar"
            :column="12"
            tooltip="Promedio de calificaciones obtenidas por el chatbot luego finalizar una conversación, dentro periodo seleccionado."
          />
          <div class="row my-4 align-items-center flex-column">
            <label class="text-center mb-3">Horario de atención actual</label>
            <div class="col-lg-6 d-flex justify-content-center">
              <div class="text-center mb-2" v-for="(item, index) in schedules" :key="index">
                <span class="text-center text_sm mr-2" v-for="(day, index) in item.days" :key="index">{{ getNameDay(day) }}</span>
                <span class="text-center text_sm normal negrita mr-2">{{ item.range }} hrs.</span>
                <span v-if="default_hour" class="text_xs mb-0">(Horario por defecto)</span>
              </div>
            </div>
            <p class="mb-0"><router-link class="text_sm link" to="/chatbot/edit">Modificar</router-link></p>
          </div>
        </div>
      </FadeTransition>
      <FadeTransition :duration="200" v-show="selected_item.name == 'Canales' || selected_item.name == 'Todo'" :key="'1.2' + filter">
        <div>
          <h3 v-if="selected_item.name == 'Todo'" class="pl-3 my-5">Canales</h3>
          <div class="row">
            <DataChart
              :disabled="no_data"
              id="conversations_by_channel"
              name="Conversaciones por canal"
              type="bar"
              :column="6"
              tooltip="Conteo de conversaciones según el canal en el que se gestionó la conversación dentro del periodo seleccionado."
            />
            <DataChart
              :disabled="no_data"
              id="all_conversation_by_channel"
              name="Canales"
              type="donut"
              :column="6"
              tooltip="Porcentaje de conversaciones según el canal en el que se gestionó la conversación dentro del periodo seleccionado."
            />
          </div>
        </div>
      </FadeTransition>
      <FadeTransition :duration="200" v-if="selected_bot.is_databot_live_3" v-show="selected_item.name == 'Tópicos de conversación' || selected_item.name == 'Todo'" :key="'2' + filter">
        <div>
          <h3 v-if="selected_item.name == 'Todo'" class="pl-3 my-5">Tópicos de conversación</h3>
          <div v-show="data_nodes_slice?.length > 0" class="row w-100">
            <DataCardChart
              type="percent"
              chart_id="nodes_sm_0"
              :title="capitalize(data_nodes_slice[0]?.name)"
              :label="`1er tópico más consultado.`"
              :current_month_value="data_nodes_slice[0]?.total_percent.toFixed(0)"
              :columns="3"
              :loading="all_loadings"
            />
            <DataCardChart
              type="percent"
              :title="capitalize(data_nodes_slice[1]?.name)"
              :label="`2do tópico más consultado.`"
              :current_month_value="data_nodes_slice[1]?.total_percent.toFixed(0)"
              :columns="3"
              chart_id="nodes_sm_1"
              :loading="all_loadings"
            />
            <DataCardChart
              type="percent"
              :title="capitalize(data_nodes_slice[2]?.name)"
              :label="`3er tópico más consultado.`"
              :current_month_value="data_nodes_slice[2]?.total_percent.toFixed(0)"
              :columns="3"
              chart_id="nodes_sm_2"
              :loading="all_loadings"
            />
            <DataCardChart
              type="percent"
              :title="capitalize(data_nodes_slice[3]?.name)"
              :label="`4to tópico más consultado.`"
              :current_month_value="data_nodes_slice[3]?.total_percent.toFixed(0)"
              :columns="3"
              chart_id="nodes_sm_3"
              :loading="all_loadings"
            />
          </div>
          <DataChart
            :key="selected_item.name"
            type="tree_xl"
            :disabled="no_data"
            :column="12"
            :height="100"
            id="conversations_nodes"
            tooltip="Análisis de conversaciones del cual se desglosan tópicos, categorías y cantidad de veces que fueron consultadas, según periodo seleccionado."
          />
          <div class="row">
            <DataCardTable
              title="Los 10 tópicos más consultados en las conversaciones"
              :items="nodes_topics_table"
              :column="6"
              show_index
            />
            <DataCardTable
              title="Las 10 categorías más consultadas en las conversaciones"
              :items="nodes_categories_table"
              :column="6"
              show_index
            />
          </div>
        </div>
      </FadeTransition>
      <FadeTransition :duration="200" v-show="selected_item.name == 'Agentes' || selected_item.name == 'Todo'" :key="'3' + filter">
        <div>
          <h3 v-if="selected_item.name == 'Todo'" class="pl-3 my-5">Agentes</h3>
          <div class="row">
            <DataCardChart
              type="number"
              title="Total de solicitudes de agente"
              :label="`En ${current_month}`"
              :columns="4"
            />
            <DataCardChart
              type="percent"
              title="Total de conversaciones tomadas"
              :label="`En ${current_month}`"
              :current_month_value="current_taken_conversations"
              :last_month_value="last_taken_conversations"
              :columns="4"
            />
            <DataCardChart
              type="time"
              title="Duración de conversación de agente"
              :label="`En ${current_month}`"
              :current_month_value="current_average_intervention"
              :last_month_value="last_average_intervention"
              :columns="4"
            />
          </div>
          <div class="row">
            <DataChart
              :disabled="no_data"
              id="get_interventions"
              name="Intervenciones de agente"
              type="bar"
              :column="9"
              tooltip="Conteo de conversaciones intervenidas por agentes dentro del periodo en porcentaje."
            />
            <DataCardTable
              title="Duración promedio de conversaciones con agente dentro del periodo"
              :items="average_intervention"
              :column="3"
            />
          </div>
          <div class="row">
            <DataChart
              :disabled="no_data"
              id="all_agent_monthly"
              name="Intervenciones por agente mensual"
              type="multibar"
              :column="9"
              tooltip="Conteo de conversaciones intervenidas por agentes agrupado por mes."
            />
            <DataCardTable
              title="Duración promedio de conversaciones sin agente dentro del periodo"
              :items="average_without_agent"
              :column="3"
            />
          </div>
          <div class="row">
            <template v-if="selected_bot.is_databot_live_3">
              <DataChart
                :disabled="no_data"
                id="average_calification_by_agent"
                name="Promedio de calificación por agente"
                type="multibar"
                :column="9"
                tooltip="Promedio de calificaciones por agente agrupados por mes en periodo seleccionado."
              />
              <DataCardTable
                title="Calificación promedio de todos los agentes dentro del periodo"
                :items="average_agent_calification"
                :column="3"
              />
            </template>
          </div>
        </div>
      </FadeTransition>
      <FadeTransition v-if="parque_arauco_bots.includes(selected_bot.id)" :duration="200" v-show="selected_item.name == 'Tiendas' || selected_item.name == 'Todo'" :key="'4' + filter">
        <div>
          <div class="row">
            <DataChart
              :disabled="no_data"
              id="store_metrics"
              name="5 tiendas más buscadas"
              type="multibar"
              :column="12"
              tooltip="Conteo de las 5 tiendas más consultadas agrupadas por mes dentro del periodo seleccionado."
            />
          </div>
          <div class="row">
            <DataChart
              :disabled="no_data"
              id="store_metrics_5"
              name="Porcentaje 5 tiendas más buscadas"
              type="donut"
              :column="8"
              tooltip="Porcentaje de las 5 tiendas más consultadas dentro del periodo seleccionado."
            />
            <DataCardTable
              title="10 tiendas más buscadas"
              :items="shops_for_table"
              :column="4"
              show_index
            />
          </div>
        </div>
      </FadeTransition>
      <FadeTransition :duration="200" v-show="selected_item.name == 'eCommerce' || selected_item.name == 'Todo'" :key="'5' + filter">
        <div v-if="providers?.length > 0 || selected_bot.id == 537 || selected_bot.id == 64">
          <h3 v-if="selected_item.name == 'Todo'" class="pl-3 my-5">eCommerce</h3>
          <div class="row">
            <DataCardChart
              icon="conversation"
              type="number"
              title="Total de productos recomendados por el Chatbot"
              :current_month_value="total_pay_money?.total_recommended_products"
              :columns="3"
            />
            <DataCardChart
              icon="conversation"
              type="number"
              title="Total de productos agregados al carro en el Chatbot"
              :current_month_value="total_pay_money?.total_products_added_to_cart"
              :columns="3"
            />
            <DataCardChart
              icon="cart"
              type="price"
              title="Total de dinero agregado al carro"
              :lang="total_pay_money?.currency"
              :current_month_value="total_pay_money?.total_money_added_to_cart"
              :columns="3"
            />
            <DataCardChart
              icon="price"
              type="price"
              title="Total de dinero pagado"
              :lang="total_pay_money?.currency"
              :current_month_value="total_pay_money?.total_money_paid"
              :columns="3"
              tooltip="Total de dinero pagado en carros de compra gestionados por el chatbot en periodo seleccionado. No considera carros abandonados ni recuperados."
            />
          </div>
          <div class="row">
            <DataChart
              :disabled="no_data"
              id="product_in_cart"
              name="Productos más agregados"
              type="bar"
              :column="6"
              tooltip="Porcentaje de los 10 productos más agregados al carro de compras en el periodo seleccionado."
            />
            <div class="col-lg-6 mb-4">
              <div class="no_data-card" v-show="no_data.includes('card-n1')"><p>Productos más vendidos sin datos</p></div>
              <div v-show="!no_data.includes('card-n1')" :key="'6' + filter" class="table">
                <div class="tb-head mb-2 p-3">
                  <div class="col-8 px-0">
                    <h4 class="mb-0">Productos más vendidos en {{ current_month }}</h4>
                  </div>
                  <div class="col-2">
                    <span>Precio</span>
                  </div>
                  <div class="col-2 px-0">
                    <span>Cantidad</span>
                  </div>
                </div>
                <div class="tb-body">
                  <div class="row_box mb-2" v-for="(product, index) in products_paid" :key="index">
                    <div class="row px-3 mb-2">
                      <div class="col-8 row align-items-center px-0">
                        <span class="table-item-index t-regular mr-2">{{ (index + 1) + '. ' }}</span>
                        <img class="product-img mr-2" :src="product.url" alt="">
                        <p class="capitalize t-ellipsis mb-0">{{ product.producto }}</p>
                      </div>
                      <div class="col-2 row align-items-center">
                        <p class="mb-0">$ {{ product.price }}</p>
                      </div>
                      <div class="col-2 row align-items-center px-0">
                        <p class="mb-0">{{ product.cantidad }} un.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <DataChart
            :disabled="no_data"
            id="money_cart"
            name="Dinero mensual agregado al carro desde el Chatbot"
            type="donut"
            :column="12"
            tooltip="Total de dinero agregado al carro de compras gestionado por el chatbot, agrupado de forma mensual y dentro del periodo seleccionado."
          />
        </div>
      </FadeTransition>
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex";
import LineChart from './charts/LineChart';
import BarChart from './charts/BarChart';
import MultiBarChart from './charts/MultiBarChart';
import MultiBarChart2 from './charts/MultiBarChart2';
import QuestionBarChart from './charts/QuestionsBarChart';
import donutChart from './charts/donutChart';
import donutChartSm from './charts/donutChartSm';
import pieChart from './charts/pieChart';
import StackedChart from './charts/StackedChart';
import NodesChart from './charts/NodesChart';
import TreeChart from './charts/TreeChart';
import enviroment from '@/environment';
import axios from 'axios';
import { FadeTransition } from "vue2-transitions";
import DashboardEcommerce from "@/components/DashboardEcommerce";
import dashboard_api from '@/dashboard_api.js';
import DataChart from '../../components/builders/DataChart';
import DataTwoChart from '../../components/builders/DataTwoChart';
import DataCardChart from '../../components/builders/DataCardChart';
import DataCardLg from '../../components/builders/DataCardLg';
import DataCardTable from '../../components/builders/DataCardTable';
import DataCardWelcome from '../../components/builders/DataCardWelcome';
import { capitalizeFirstLetter } from "@/utils/utils.js";

export default {
  name: 'metrics',
  components: {
    FadeTransition,
    DashboardEcommerce,
    DataChart,
    DataTwoChart,
    DataCardChart,
    DataCardLg,
    DataCardTable,
    DataCardWelcome
  },
  mixins: [
    LineChart,
    BarChart,
    MultiBarChart,
    MultiBarChart2,
    QuestionBarChart,
    donutChart,
    donutChartSm,
    pieChart,
    StackedChart,
    NodesChart,
    TreeChart
  ],
  data() {
    return {
      all_loadings: [],
      type_node_chart: 'force',
      maintenance: true,
      dataReady: false,
      filter: 3,
      conversations: {},
      products_paid: [],
      count_califications: {},
      average_intervention: [],
      average_without_agent: [],
      average_agent_calification: [],
      items: [
        { name: 'Conversaciones y leads' },
        { name: 'Canales' },
        { name: 'Tópicos de conversación' },
        // { name: 'Intenciones frecuentes' },
        { name: 'Agentes' },
        { name: 'Tiendas' },
        { name: 'eCommerce' },
        { name: 'Todo' }
      ],
      emojis: [
        { url: '/img/icons/rating/1.1-bad.svg', label: 'Malo' },
        { url: '/img/icons/rating/2.1-meh.svg', label: 'Regular' },
        { url: '/img/icons/rating/3.1-what.svg', label: 'Útil' },
        { url: '/img/icons/rating/4.1-nice.svg', label: 'Bueno' },
        { url: '/img/icons/rating/5.1-love.svg', label: 'Buenísimo' }
      ],
      selected_item: {
        name: 'Conversaciones y leads'
      },
      no_data: [],
      total_pay_money: [],
      resolved_by_bot: [],
      schedules: [],
      days: [],
      default_hour: false,
      total_conversations: 0,
      current_month_conversations: 0,
      last_month_conversations: 0,
      current_month_calification_average: 0,
      last_month_calification_average: 0,
      current_month_lead: 0,
      last_month_lead: 0,
      current_conversations_out_hour: 0,
      current_conversations_in_hour: 0,
      current_lead_out_hour: 0,
      last_lead_out_hour: 0,
      shops_for_table: [],
      current_average_intervention: 0,
      last_average_intervention: 0,
      current_taken_conversations: 0,
      last_taken_conversations: 0,
      data_nodes_slice: [],
      nodes_topics_table: [],
      nodes_categories_table: [],
    };
  },
  created() {
    window.scrollTo(0, 0);
  },
  async mounted() {
    this.getSchedule();

    // if (this.maintenance) {
    //   this.$router.push({path: "/maintenance", query: { origin: this.$route.name }});
    // };
  },
  computed: {
    ...mapState(["user", "selected_bot", "providers"]),

    filtered_items() {
      const ids = [537, 67];
      const ecommerces = ['vtex', 'shopify', 'woocommerce'];
      const filter = this.items;

      if (this.providers.length > 0) {
        let provider = this.providers.find(el => ecommerces.includes(el.provider));

        if (provider || ids.includes(this.selected_bot.id)) {
          if (this.parque_arauco_bots.includes(this.selected_bot.id)) {
            if (this.selected_bot.is_databot_live_3) {
              return filter;
            } else {
              return filter.filter(el => el.name != 'Tópicos de conversación');
            }
          } else {
            if (this.selected_bot.is_databot_live_3) {
              return filter.filter(el => el.name != 'Tiendas');
            } else {
              return filter.filter(el => el.name != 'Tópicos de conversación' && el.name != 'Tiendas')
            }
          };
        } else {
          if (this.parque_arauco_bots.includes(this.selected_bot.id)) {
            if (this.selected_bot.is_databot_live_3) {
              return filter.filter(el => el.name != 'eCommerce');
            } else {
              return filter.filter(el => el.name != 'Tópicos de conversación' && el.name != 'eCommerce');
            }
          } else {
            if (this.selected_bot.is_databot_live_3) {
              return filter.filter(el => el.name != 'Tiendas' && el.name != 'eCommerce');
            } else {
              return filter.filter(el => el.name != 'Tópicos de conversación' && el.name != 'Tiendas' && el.name != 'eCommerce')
            }
          };
        }
      } else {
        if (this.parque_arauco_bots.includes(this.selected_bot.id)) {
          if (this.selected_bot.is_databot_live_3) {
            return filter.filter(el => el.name != 'eCommerce');
          } else {
            return filter.filter(el => el.name != 'Tópicos de conversación' && el.name != 'eCommerce');
          }
        } else {
          if (this.selected_bot.is_databot_live_3) {
            return filter.filter(el => el.name != 'Tiendas' && el.name != 'eCommerce');
          } else {
            return filter.filter(el => el.name != 'Tópicos de conversación' && el.name != 'Tiendas' && el.name != 'eCommerce')
          }
        };
      };
    },
    parque_arauco_bots() {
      return [1336, 1337, 1338, 1339, 1340, 1341, 1204, 1158, 1005, 1184, 1428, 2507];
    },
    current_month() {
      return this.$moment().locale('es').format('MMMM')
    },
    add_month_format() {
      return this.$moment().format('MMM') + ' ' + this.$moment().format('YYYY');
    }
  },
  watch: {
    selected_bot(val) {
      this.getSchedule();
    },
    selected_item: {
      immediate: true,
      handler(val) {
        this.getSectionsCarts(val);
      }
    },
    filter(val) {
      this.getSectionsCarts();
    }
  },
  methods: {
    capitalize(val) {
      return capitalizeFirstLetter(val);
    },
    formatTime(el) {
      return (el.hours ? el.hours + ' hr.' : '')
        + ' '
        + (el.minutes ? el.minutes + ' min.'
          : el.seconds ? el.seconds.toFixed(0) + ' seg.' : '-')
    },
    getNameDay(number) {
      switch (number) {
        case 1: return "Lunes";
        case 2: return "Martes";
        case 3: return "Miércoles";
        case 4: return "Jueves";
        case 5: return "Viernes";
        case 6: return "Sábado";
        case 7: return "Domingo";
      }
    },
    async getSchedule() {
      this.schedules = (await dashboard_api.get(`schedule/list/${this.selected_bot.id}`)).data;

      if (this.schedules.length == 0) {
        this.default_hour = true;
        this.schedules.push({
          days: [1, 2, 3, 4, 5],
          range: "08:00-18:00",
          bot_id: this.selected_bot.id,
          timezone: "America/Santiago"
        });
      } else {
        this.default_hour = false;
      }
      let sorted_days = this.schedules[0].days.sort();
      this.days = sorted_days;
    },
    getSectionsCarts(val) {
      this.no_data = [];
      let value = val?.name ? val : this.selected_item;

      switch (value.name) {
        case 'Conversaciones y leads':
          Promise.all([
            this.getConversations(),
            this.getLeads(),
            // this.getConversationsResolvedByBot(),
            this.getCountCalifications(),
            this.getCalificationsAverage(),
            this.getInteractions(),
            this.getConversationsOutTime(), // info card
            this.getLeadOutTime() // info card
          ]);
          break;
        case 'Canales':
          new Promise((resolve, reject) => {
            this.getConversationsByChannel();
          });
          break;
        case 'Tópicos de conversación':
          new Promise((resolve, reject) => {
            // this.getIntentions();
            this.getConversationsNodes()
          });
          break;
        case 'Agentes':
          new Promise((resolve, reject) => {
            this.getInterventions(),
            this.getAverageIntervention(), // info card
            this.getDelayInResponse(), // info card
            this.getAgentAverageCalification(),
            this.getAverageCalificationByAgent()
          });
          break;
        case 'eCommerce':
          Promise.all([
            this.getProductFrequentlyInCart(),
            this.getProductPaid(),
            this.getEcommerceData(),
            this.getMoneyCart(),
          ]);
          break;
        case 'Tiendas':
          Promise.all([
            this.getStoreMetrics()
          ]);
          break;
        case 'Todo':
          this.getAllCharts();
          break;
        default:
          return [];
      }
    },
    getAllCharts() {
      Promise.all([
        // Conversaciones y lead
        this.getConversations(),
        this.getLeads(),
        // this.getConversationsResolvedByBot(),
        this.getCalificationsAverage(),
        this.getCountCalifications(),
        this.getInteractions(),
        this.getConversationsOutTime(),
        this.getLeadOutTime(),
        // Canales
        this.getConversationsByChannel(),
        // Intenciones frecuentes
        // this.getIntentions(),
        // Agentes
        this.getInterventions(),
        this.getAverageIntervention(),
        this.getDelayInResponse(),
        this.getAgentAverageCalification(),
        this.getAverageCalificationByAgent(),
        // Producto más agregado
        this.getProductFrequentlyInCart(),
        this.getProductPaid(),
        this.getEcommerceData(),
        this.getMoneyCart(),
        this.getConversationsNodes()
      ]).then(resp => {});
      if (this.parque_arauco_bots.includes(this.selected_bot.id)) {
        Promise.all([
          this.getStoreMetrics(),
        ]).then(resp => {});
      }
    },
    // 1er tab
    async getConversations() {
      this.total_conversations = 0;
      this.all_loadings.push('current_conversations');
      const url = this.selected_bot.is_databot_live_3 ? 'api_conversations/sessions_data' : 'conversation_metrics/conversations';

      axios
        .post(`${enviroment.url_metrics}/${url}`, {
          bot_id: this.selected_bot.id,
          filter: this.filter,
          chart: 'get_conversations'
        })
        .then(resp => {
          if (resp.data.status != false) {
            const new_data = resp.data.data;
            const months = new_data.map(el => el.month).reverse();
            const data = new_data.map(el => el.quantity).reverse();

            // valida si no existe data en mes actual lo agrega con valor 0
            if (!months.includes(this.add_month_format)) {
              months.push(this.add_month_format);
              data.push(0);
              new_data.unshift({month: this.add_month_format, quantity: 0, id: null});
            };

            this.total_conversations = resp.data.total_conversations;
            this.current_month_conversations = new_data[0]?.quantity;
            this.last_month_conversations = new_data[1]?.quantity;

            this.barChart(months, data, 'get_conversations', 'Conversaciones mensuales', 'Conversaciones');
            this.donutChartSm('current_conversations', this.last_month_conversations, this.current_month_conversations);
          } else {
            this.no_data.push('get_conversations', 'current_conversations');
          };
        })
        .catch(err => {
          this.no_data.push('get_conversations', 'current_conversations');
          console.log("errro", err);
        })
        .finally(() => {
          this.all_loadings = this.all_loadings.filter(el => el != 'current_conversations');
          this.getConversationsResolvedByBot();
        })
    },
    getLeads() {
      this.all_loadings.push('current_leads');

      axios
        .post(`${enviroment.url_metrics}/lead_metrics/count_leads`, {
          bot_id: this.selected_bot.id,
          filter: this.filter,
          chart: 'get_leads'
        })
        .then(resp => {
          if (resp.data.status != false) {
            const new_data = resp.data.data;
            const months = new_data.map(el => el.month).reverse();
            const data = new_data.map(el => el.quantity).reverse();

            // valida si no existe data en mes actual lo agrega con valor 0
            if (!months.includes(this.add_month_format)) {
              months.push(this.add_month_format);
              data.push(0);
              new_data.unshift({month: this.add_month_format, quantity: 0, id: null});
            };

            this.current_month_lead = new_data[0]?.quantity;
            this.last_month_lead = new_data[1]?.quantity;

            this.barChart(months, data, 'get_leads', 'Leads mensuales', 'Leads');
            this.donutChartSm('current_leads', this.last_month_lead, this.current_month_lead);
          } else {
            this.no_data.push('get_leads', 'current_leads');
          };
        })
        .catch(err => {
          this.no_data.push('get_leads', 'current_leads');
          console.log("errro", err);
        })
        .finally(() => {
          this.all_loadings = this.all_loadings.filter(el => el != 'current_leads');
        })
    },
    getConversationsResolvedByBot() {
      const url = this.selected_bot.is_databot_live_3 ? 'api_conversations/session_resolved_by_chatbot' : 'bot_metrics/conversations_resolved';
      
      axios
        .post(`${enviroment.url_metrics}/${url}`, {
          bot_id: this.selected_bot.id,
          filter: this.filter,
          chart: 'resolved_by_bot'
        })
        .then(resp => {
          if (resp.data.status != false) {
            const new_data = resp.data.data.reverse();
            const months = new_data.map(el => el.month);
            const data = new_data.map(el => el.quantity);

            // valida si no existe data en mes actual lo agrega con valor 0
            if (!months.includes(this.add_month_format)) {
              months.push(this.add_month_format);
              data.push(0);
              new_data.unshift({month: this.add_month_format, quantity: 0, id: null});
            };

            const sum = data.reduce((a, b) => a + b, 0);
            const rest = this.total_conversations - sum;
            const percent = this.total_conversations > 0 ? (sum / this.total_conversations) * 100 : 0;
            const totals = [];

            data.forEach((el, index) => {
              if (el > 0) totals.push({ name: months[index], value: el });
              else totals.push({ name: months[index], value: 0 });
            })

            // totals.push(
            //   { name: 'No resueltas', value: rest },
            //   { name: 'Resueltas', value: sum }
            // );
            this.barChart(months, data, 'resolved_by_bot', 'Conversaciones mensuales resueltas por el chatbot', 'Conversaciones resueltas');
            this.donutChart('resolved_by_bot_percent', 'Porcentaje de conversaciones resueltas por el chatbot', '', totals);
          } else {
            this.no_data.push('resolved_by_bot', 'resolved_by_bot_percent');
          };
        })
        .catch((err => {
          this.no_data.push('resolved_by_bot', 'resolved_by_bot_percent');
          console.log("getConversationsResolvedByBot err->", err)}
        ))
        .finally(() => {
          this.all_loadings.push('resolved_by_bot', 'resolved_by_bot_percent');
        });
    },
    getCountCalifications() {
      const url = this.selected_bot.is_databot_live_3 ? 'api_conversations/session_count_califications' : 'conversation_metrics/count_califications';

      axios
        .post(`${enviroment.url_metrics}/${url}`, {
          bot_id: this.selected_bot.id,
          filter: this.filter,
          chart: 'count_califications'
        })
        .then(resp => {
          if (resp.data.status != false) {
            const new_data = resp.data.data.reverse();
            const months = (new_data.map(el => Object.keys(el))).flat();
            const series = [];
            const califications_1 = new_data.map((el, index) => el[months[index]]["1"] || 0);
            const califications_2 = new_data.map((el, index) => el[months[index]]["2"] || 0);
            const califications_3 = new_data.map((el, index) => el[months[index]]["3"] || 0);
            const califications_4 = new_data.map((el, index) => el[months[index]]["4"] || 0);
            const califications_5 = new_data.map((el, index) => el[months[index]]["5"] || 0);

            series.push(
              califications_1,
              califications_2,
              califications_3,
              califications_4,
              califications_5,
            )
            const names = [
              'Calificación 1',
              'Calificación 2',
              'Calificación 3',
              'Calificación 4',
              'Calificación 5'
            ];

            this.stackedChart(months, series, 'count_califications', 'Conteo de calificaciones', names);
          } else this.no_data.push('count_califications');
        })
        .catch(err => {
          this.no_data.push('count_califications');
          console.log("errro", err);
        })
        .finally(() => {
          this.all_loadings.push('count_califications');
        });
    },
    getInteractions() {
      const url = this.selected_bot.is_databot_live_3 ? 'api_conversations/interaction_per_day' : 'conversation_metrics/interactions';

      axios.post(`${enviroment.url_metrics}/${url}`, {
        bot_id: this.selected_bot.id,
        filter: this.filter,
        chart: 'get_interactions'
      })
      .then(resp => {
        if (resp.data.status != false) {
          const new_data = resp.data.data;
          const months = Object.keys(new_data);
          const days = [];
          const values = [];
          
          months.forEach(month => {
            new_data[month].forEach(el => {
              days.push(el.day);
              values.push(el.quantity);
            })
          })
          this.barChart(days.reverse(), values.reverse(), 'get_interactions', 'Top 5 días con más conversaciones de cada mes', 'Interacciones');
        } else this.no_data.push('get_interactions');
      }).catch(err => {
        this.no_data.push('get_interactions');
        console.log("errro", err);
      })
      .finally(() => {
        this.all_loadings.push('get_interactions');
      });
    },
    getConversationsOutTime() {
      const url = this.selected_bot.is_databot_live_3 ? 'api_conversations/session_out_time' : 'conversation_metrics/out_of_time';

      axios
        .post(`${enviroment.url_metrics}/${url}`, {
          bot_id: this.selected_bot.id,
          filter: this.filter,
          chart: 'conversations_out_time'
        })
        .then(resp => {
          if (resp.data.status != false) {
            const new_data = resp.data.data.reverse();
            const months = new_data.map(el => el.month);
            const first_series = new_data.map(el => el.conversation_out_schedule_count);
            const second_series = new_data.map(el => el.conversation_in_schedule_count);
            const names_series = ['Fuera de horario', 'Dentro de horario'];

            this.current_conversations_out_hour = resp.data?.data[0]?.conversation_out_schedule_count;
            this.current_conversations_in_hour = resp.data?.data[0]?.conversation_in_schedule_count;

            let add_total_in = 0;
            let add_total_out = 0;
            let totals = [];

            resp.data.data.forEach(ele => {
              add_total_in = add_total_in + ele.conversation_in_schedule_count;
              add_total_out = add_total_out + ele.conversation_out_schedule_count;
            });
            totals.push(
              { name: 'Fuera de horario', value: add_total_out },
              { name: 'Dentro de horario', value: add_total_in }
            );
            this.multiBarChart(months, first_series, second_series, 'conversations_out_time', 'Conversaciones y horarios de atención por mes', names_series);
            this.donutChart('conversations_and_hours', 'Porcentaje de conversaciones en horarios de atención', '', totals);
          } else {
            this.no_data.push('conversations_out_time', 'conversations_and_hours');
          };
        }).catch(err => {
          this.no_data.push('conversations_out_time', 'conversations_and_hours');
          console.log("errro", err);
        })
        .finally(() => {
          this.all_loadings.push('conversations_out_time', 'conversations_and_hours');
        });
    },
    getLeadOutTime() {
      this.all_loadings.push('out_hour_leads');
      axios
        .post(`${enviroment.url_metrics}/lead_metrics/leads_out_time`, {
          bot_id: this.selected_bot.id,
          filter: this.filter,
          chart: 'leads_out_time'
        })
        .then(resp => {
          if (resp.data.status != false) {
            const new_data = resp.data.data.reverse();
            const months = new_data.map(el => el.month);
            const first_series = new_data.map(el => el.leads_out_schedule_count);
            const second_series = new_data.map(el => el.leads_in_schedule_count);
            const names_series = ['Fuera de horario', 'Dentro de horario'];

            let add_total_in = 0;
            let add_total_out = 0;
            let totals = [];

            resp.data.data.forEach(ele => {
              add_total_in = add_total_in + ele.leads_in_schedule_count;
              add_total_out = add_total_out + ele.leads_out_schedule_count;
            });
            totals.push(
              { name: 'Fuera de horario', value: add_total_out },
              { name: 'Dentro de horario', value: add_total_in }
            );
            this.donutChart('leads_and_hours', 'Leads y horarios de atención', 'En porcentaje', totals);

            // valida si no existe data en mes actual lo agrega con valor 0
            if (!months.includes(this.add_month_format)) {
              months.push(this.add_month_format);
              first_series.push(0);
              second_series.push(0);
              new_data.push({ month: this.add_month_format, leads_out_schedule_count: 0, leads_in_schedule_count: 0 })
            };
            this.current_lead_out_hour = new_data[new_data.length - 1]?.leads_out_schedule_count;
            this.last_lead_out_hour = new_data[new_data.length - 2]?.leads_out_schedule_count;

            this.donutChartSm('out_hour_leads', this.last_lead_out_hour, this.current_lead_out_hour);
            this.multiBarChart(months, first_series, second_series, 'leads_out_time', 'Leads y horarios de atención', names_series);
          } else {
            this.no_data.push('leads_out_time', 'leads_and_hours');
          };
        }).catch(err => {
          this.no_data.push('leads_out_time', 'leads_and_hours');
          console.log("errro", err);
        })
        .finally(() => {
          this.all_loadings = this.all_loadings.filter(el => el != 'out_hour_leads');
        });
    },
    getCalificationsAverage() {
      const url = this.selected_bot.is_databot_live_3 ? 'api_conversations/session_calification_received' : 'conversation_metrics/califications_received';
      this.all_loadings.push('califications_average');

      axios
        .post(`${enviroment.url_metrics}/${url}`, {
          bot_id: this.selected_bot.id,
          filter: this.filter,
          chart: 'bot_average_calification'
        })
        .then(resp => {
          if (resp.data.status != false) {
            const new_data = resp.data.data.reverse();
            const months = new_data.map(el => el.month);
            const data = new_data.map(el => el.average_calification);

            this.current_month_calification_average = Number(data[data.length - 1]);
            this.last_month_calification_average = Number(data[data.length - 2]);

            this.donutChartSm('califications_average', this.last_month_calification_average, this.current_month_calification_average);
            this.barChart(months, data, 'bot_average_calification', 'Calificación promedio del chatbot', 'Calificación promedio')
          } else {
            this.no_data.push('califications_average', 'bot_average_calification');
          };
        })
        .catch(err => {
          this.no_data.push('califications_average', 'bot_average_calification');
          console.log("errro", err);
        })
        .finally(() => {
          this.all_loadings = this.all_loadings.filter(el => el != 'califications_average');
        })
    },
    // 2do tab
    getConversationsByChannel() {
      const url = this.selected_bot.is_databot_live_3 ? 'api_conversations/session_by_channel' : 'conversation_metrics/by_channel';
      axios
        .post(`${enviroment.url_metrics}/${url}`, {
          bot_id: this.selected_bot.id,
          filter: this.filter,
          chart: 'conversations_by_channel'
        })
        .then(resp => {
          if (resp.data.status != false) {
            const data = resp.data.data;
            const totals = resp.data.total_by_channel;
            const months = data.map(el => el.month).reverse();
            const whatsapp = data.map(el => el.whatsapp).reverse();
            const web = data.map(el => el.web).reverse();
            const instagram = data.map(el => el.instagram).reverse();
            const names_series = ['WhatsApp', 'Web', 'Instagram'];
            const series = [whatsapp, web, instagram];

            totals.forEach(el => {
              el.value = el.quantity;
              el.name = el.channel;

              delete el.channel;
              delete el.quantity;              
            });
            this.multiBarChart2(months, series, 'conversations_by_channel', 'Conversaciones por canal', names_series);
            this.donutChart('all_conversation_by_channel', 'Canales', 'Total de conversaciones', totals);
          } else {
            this.no_data.push('conversations_by_channel', 'all_conversation_by_channel');
          };
        })
        .catch(err => {
          this.no_data.push('conversations_by_channel', 'all_conversation_by_channel');
          console.log("errror_pie", err);
        })
        .finally(() => {
          this.all_loadings.push('conversations_by_channel', 'all_conversation_by_channel');
        });
    },
    // 3er tab Comentado hasta nuevo aviso
    // getIntentions() {
    //   axios
    //     .post(`${enviroment.url_metrics}/lead_metrics/intentions`, {
    //       bot_id: this.selected_bot.id,
    //       filter: this.filter,
    //       chart: 'intentions'
    //     })
    //     .then(resp => {
    //       if (resp.data.status != false) {
    //         const months = resp.data.data.map(el => el.month).reverse();
    //         const names = resp.data.data.map(el => Object.keys(el)).reverse();
    //         const values = resp.data.data.map(el => Object.values(el)).reverse();

    //         this.halfDonutChart('all_intents');
    //       } else this.no_data.push('all_intents');
    //     }).catch(err => {
    //       this.no_data.push('all_intents');
    //       console.log("errro", err);
    //     });
    // },
    // 4to tab
    getInterventions() {
      const url = this.selected_bot.is_databot_live_3 ? 'api_conversations/agents_interventions' : 'agent_metrics/interventions';

      axios.post(`${enviroment.url_metrics}/${url}`, {
        bot_id: this.selected_bot.id,
        filter: this.filter,
        chart: 'get_interventions'
      })
      .then(resp => {
        if (resp.data.status != false) {
          const reversedData = [...resp.data.data];
          const months = resp.data.data.map(el => el.month).reverse() || [];
          const names = Object.keys(resp.data.total_interventions_by_agent).reverse() || [];
          const values = Object.values(resp.data.total_interventions_by_agent).reverse() || [];
          const data = [];

          for (let name of names) {
            const agent_values = [];

            for (let item of reversedData) {
              agent_values.push(item[name] || 0)
            }
            data.push(agent_values)
          };
          this.multiBarChart2(months, data, 'all_agent_monthly', 'Intervenciones por agente mensual', names);

          let new_data = [];
          let new_obj = {};

          names.forEach((el, index, array) => {
            new_obj = {
              name: el,
              value: values[index]
            };
            new_data.push(new_obj);
          });

          this.donutChart('get_interventions', 'Intervenciones de agente', '', new_data);

          const current_values = reversedData[0] ? Object.values(reversedData[0]).filter(el => typeof el === 'number') : [];
          const last_values = reversedData[1] ? Object.values(reversedData[1]).filter(el => typeof el === 'number') : [];
          const last_sum = last_values.reduce((a, b) => a + b, 0) || 0;
          const current_sum = current_values.reduce((a, b) => a + b, 0) || 0;

          this.last_taken_conversations = last_sum > 0 ? ((last_sum / this.last_month_conversations) * 100).toFixed(0) : 0;
          this.current_taken_conversations = this.current_month_conversations > 0 ? ((current_sum / this.current_month_conversations) * 100).toFixed(0) : 0;
        } else {
          this.no_data.push('get_interventions', 'all_agent_monthly');
        };
      }).catch(err => {
        this.no_data.push('get_interventions', 'all_agent_monthly');
        console.log("errro", err);
      })
      .finally(() => {
        this.all_loadings.push('get_interventions', 'all_agent_monthly');
      });
    },
    getAverageIntervention() {
      this.average_intervention = [];
      const url = this.selected_bot.is_databot_live_3 ? 'api_conversations/session_time_with_agent' : 'conversation_metrics/average_with_agent';

      axios
        .post(`${enviroment.url_metrics}/${url}`, {
          bot_id: this.selected_bot.id,
          filter: this.filter,
          chart: 'average_intervention'
        })
        .then(resp => {
          if (resp.data.status != false) {
            const new_data = resp.data.data;
            const months = new_data.map(el => el.month);

            // valida si no existe data en mes actual lo agrega con valor 0
            if (!months.includes(this.add_month_format)) {
              months.push(this.add_month_format);
              new_data.push({month: this.add_month_format, hours: 0, minutes: 0, seconds: 0});
            };
            new_data.forEach(el => {
              this.average_intervention.push({
                name: el.month,
                value: this.formatTime(el)
              })
            });
            this.current_average_intervention = this.average_intervention[this.average_intervention.length - 1]?.value;
            this.last_average_intervention = this.average_intervention[this.average_intervention.length - 2]?.value;
            // this.donutChartSm('average_intervention_sm', this.last_average_intervention, this.current_average_intervention);
          };
        }).catch(err => {
          console.log("errro", err);
        });
    },
    getAgentAverageCalification() {
      this.average_agent_calification = [];
      // Si no es dlive3 no considerar estos datos
      const url = 'api_conversations/agents_average_calification';

      axios
        .post(`${enviroment.url_metrics}/${url}`, {
          bot_id: this.selected_bot.id,
          filter: this.filter,
          chart: 'average_agent_calification'
        })
        .then(resp => {
          if (resp.data.status != false) {
            resp.data.data.forEach(el => {
              this.average_agent_calification.push({
                name: el.month,
                value: el.average_calification
              })
            })
          } else this.no_data.push('average_agent_calification');
        }).catch(err => {
          this.no_data.push('average_agent_calification');
          console.log("errro", err);
        });
    },
    getAverageCalificationByAgent() {
      const url = 'api_conversations/average_calification_by_agent';

      axios
        .post(`${enviroment.url_metrics}/${url}`, {
          bot_id: this.selected_bot.id,
          filter: this.filter,
          chart: 'average_calification_by_agent'
        })
        .then(resp => {          
          if (resp.data.status != false) {
            const reversedData = [...resp.data.data].reverse();
            const months = reversedData.map(el => el.month) || [];
            const names = [];
            const data = [];

            reversedData.forEach(el => {
              delete el.month;

              names.push(Object.keys(el));
            })
            names[0].forEach(el => {
              const agent_values = [];

              reversedData.forEach(ele => {
                agent_values.push(ele[el] || 0)
              });
              data.push(agent_values)
            })
            this.multiBarChart2(months, data, 'average_calification_by_agent', 'Promedio de calificación por agente', names[0]);
          } else this.no_data.push('average_calification_by_agent');
        }).catch(err => {
          this.no_data.push('average_calification_by_agent');
          console.log("errro", err);
        });
    },
    getDelayInResponse() {
      this.average_without_agent = [];
      const url = this.selected_bot.is_databot_live_3 ? 'api_conversations/session_time_whitout_agent' : 'conversation_metrics/average_without_agent';

      axios
        .post(`${enviroment.url_metrics}/${url}`, {
          bot_id: this.selected_bot.id,
          filter: this.filter,
          chart: 'average_without_agent'
        })
        .then(resp => {
          if (resp.data.status != false) {
            resp.data.data.forEach(el => {
              this.average_without_agent.push({
                name: el.month,
                value: this.formatTime(el)
              })
            });
          } else this.no_data.push('average_without_agent');
        }).catch(err => {
          this.no_data.push('average_without_agent');
          console.log("errro", err);
        });
    },
    // 5to tab
    getProductFrequentlyInCart() {
      const url = this.selected_bot.is_databot_live_3 ? 'api_conversations/frequent_products_added_to_cart' : 'cart_metrics/frequently_products_in_cart';

      axios.post(`${enviroment.url_metrics}/${url}`, {
        bot_id: this.selected_bot.id,
        filter: this.filter,
        chart: 'product_in_cart'
      }).then(resp => {
        if (resp.data.data) {
          const months = Object.keys(resp.data.data);
          const totals = [];

          months.forEach(el => {
            resp.data.data[el].forEach(ele => {
              let new_obj = {
                name: ele.producto,
                value: ele.cantidad
              };
              totals.push(new_obj);
            });
          });
          this.donutChart('product_in_cart', 'Productos más agregados', '', totals);
        } else this.no_data.push('product_in_cart');
      }).catch(err => {
        this.no_data.push('product_in_cart');
        console.log("errro", err);
      });
    },
    getMoneyCart() {
      const url = this.selected_bot.is_databot_live_3 ? 'api_conversations/money_added_to_cart' : 'cart_metrics/money_in_cart';

      axios.post(`${enviroment.url_metrics}/${url}`, {
        bot_id: this.selected_bot.id,
        filter: this.filter,
        chart: 'money_cart'
      }).then(resp => {
        if (resp.data.status != false) {
          const months = resp.data.data.map(el => el.month).reverse();
          const data = resp.data.data.map(el => el.quantity).reverse();

          this.barChart(months, data, 'money_cart', 'Dinero mensual agregado al carro desde el Chatbot', 'Dinero agregado');
        } else this.no_data.push('money_cart');
      }).catch(err => {
        this.no_data.push('money_cart');
        console.log("errro", err);
      });
    },
    getProductPaid() {
      const url = this.selected_bot.is_databot_live_3 ? 'api_conversations/frequent_products_paid' : 'cart_metrics/frequently_products_paid';

      axios
        .post(`${enviroment.url_metrics}/${url}`, {
          bot_id: this.selected_bot.id,
          filter: this.filter,
          chart: 'products_paid'
        })
        .then(resp => {
          if (resp.data.status != false) {
            const months = Object.keys(resp.data.data);

            months.forEach(el => {
              resp.data.data[el].forEach(ele => {
                // Tabla de productos ecommerce
                return this.products_paid.push(ele);
              });
            });
          } else this.no_data.push('card-n1');
        }).catch(err => {
          this.no_data.push('card-n1');
          console.log("errro", err);
        });
    },
    getEcommerceData() {
      const url = this.selected_bot.is_databot_live_3 ? 'api_conversations/dashboard_ecommerce' : 'ecommerce_metrics/dashboard_data';
      axios
        .post(`${enviroment.url_metrics}/${url}`, {
          bot_id: this.selected_bot.id,
          filter: this.filter,
          chart: 'ecommerce_data'
        })
        .then(resp => {
          this.total_pay_money = resp.data.statistics;
        })
        .catch(err => {})
    },
    getStoreMetrics() {
      // clean values
      this.shops_for_table = [];
      axios
        .post(`${enviroment.url_metrics}/api_conversations/store_metrics`, {
          bot_id: this.selected_bot.id,
          filter: this.filter,
          chart: 'store_metrics'
        })
        .then(resp => {
          if (resp.status == 200) {
            const new_data = resp.data.data;
            const months = new_data.map(el => el.month);
            const names = new_data.map(el => el.data.map(ele => ele.store_name)).flat();
            const series_names = [...new Set(names)];
            const series = [];
            // for donut chart
            const names_table = Object.keys(resp.data.total_store);
            const values_table = Object.values(resp.data.total_store);

            names_table.forEach((ele, index) => {
              this.shops_for_table.push({
                name: ele,
                value: values_table[index]
              })
            });

            this.shops_for_table = this.shops_for_table.slice(0, 10);

            series_names.forEach((el, index, array) => {
              let items = new_data.map(ele => ele.data.filter(item => item.store_name == el));

              // Asigna param quantity 0 cuando no hay registro y no romper el orden del chart
              items.forEach((el, idx, arr) => {
                if (!arr[idx].length > 0) arr[idx].push({ quantity: 0 });
              })

              let values = items.map(el => el.map(ele => ele.quantity)).flat();

              if (array[index] == el) series.push(values.flat());
            });
            this.stackedChart(months, series.slice(0, 5), 'store_metrics', 'Las 5 tiendas más buscadas', names.slice(0, 5));
            this.donutChart('store_metrics_5', 'Porcentaje', 'Las 5 tiendas más buscadas', this.shops_for_table.slice(0, 5));
          } else {
            this.no_data.push('store_metrics');
            this.no_data.push('store_metrics_5');
          }
        })
        .catch(err => {
          this.no_data.push('store_metrics');
          this.no_data.push('store_metrics_5');
          console.log("err->", err);
        })
    },
    getConversationsNodes() {
      this.all_loadings.push('nodes_sm_0', 'nodes_sm_1', 'nodes_sm_2', 'nodes_sm_3', 'nodes_sm_4');
      this.data_nodes_slice = [];
      this.nodes_topics_table = [];
      this.nodes_categories_table = [];
      // api_conversations/node_chart_data / nodes chart
      axios
        .post(`${enviroment.url_metrics}/api_conversations/tree_chart_data`, {
          // bot_id: 2279,
          bot_id: this.selected_bot.id,
          filter: this.filter,
          chart: 'conversations_nodes'
        })
        .then(resp => {
          console.log("resp->", resp);
          if (resp.status == 200) {
            const sorted_data = resp.data.children.sort((a, b) => b.total_percent - a.total_percent);
            const all_sessions = resp.data.total_sessions;
            const data_table = sorted_data.slice(0, 10);
            let sorted_categories = [];
            let sum_categories = [];

            this.data_nodes_slice = sorted_data.slice(0, 4);

            this.data_nodes_slice.forEach(el => {
              let total = el.children.reduce((a, b) => a + b.cantidad, 0);
              sum_categories.push(total);
            })
            console.log("sum_categories->", sum_categories);

            data_table.forEach(el => {
              let values = el.children.filter(val => val.cantidad > 0);

              sorted_categories.push(values.sort((a, b) => b.cantidad - a.cantidad));

              this.nodes_topics_table.push({
                name: el.name,
                value: `${el.total_percent.toFixed(0)} %`
              });
            });
            let final_array = sorted_categories.flat().slice(0, 10);
            // console.log("final_array->", final_array);
            
            final_array.forEach(el => {
              this.nodes_categories_table.push({
                name: el.name,
                value: el.cantidad
              });
            });

            // this.donutChartSm('nodes_sm_0', all_sessions, sum_categories[0], all_sessions);
            // this.donutChartSm('nodes_sm_1', all_sessions, sum_categories[1], all_sessions);
            // this.donutChartSm('nodes_sm_2', all_sessions, sum_categories[2], all_sessions);
            // this.donutChartSm('nodes_sm_3', all_sessions, sum_categories[3], all_sessions);
            this.donutChartSm('nodes_sm_0', 100, this.data_nodes_slice[0]?.total_percent, all_sessions);
            this.donutChartSm('nodes_sm_1', 100, this.data_nodes_slice[1]?.total_percent, all_sessions);
            this.donutChartSm('nodes_sm_2', 100, this.data_nodes_slice[2]?.total_percent, all_sessions);
            this.donutChartSm('nodes_sm_3', 100, this.data_nodes_slice[3]?.total_percent, all_sessions);
            this.TreeChart('conversations_nodes', resp.data, 'Nodos de conversaciones');
            // this.NodesChart('conversations_nodes', resp.data, 'Nodos de conversaciones', this.type_node_chart);
          } else {
            this.no_data.push('conversations_nodes', 'nodes_sm_0');
          }
        })
        .catch(err => {
          this.no_data.push('conversations_nodes', 'nodes_sm_0');
          console.log("err->", err);
        })
        .finally(() => {
          this.all_loadings = this.all_loadings.filter(el => el != 'nodes_sm_0' && el != 'nodes_sm_1' && el != 'nodes_sm_2' && el != 'nodes_sm_3')
        })
    },
  }
};
</script>

<style lang="scss" scoped>
.metrics {
  padding-bottom: 4rem;

  .product-img {
    width: 45px;
    min-height: 45px;
    height: 45px;
    border-radius: .25rem;
  }
  .table {
    border: 1px solid #e8e8e8;
    background-color: #fff;
    border-radius: 1rem;
    overflow-y: hidden;
    max-height: 318px;
    
    .tb-head {
      border-radius: .5rem .5rem 0 0;
      display: flex;
      background-color: #DEEDFF;

      span {
        color: #181818;
        font-weight: 700;
      }
    }
    .tb-body {
      overflow-y: scroll;
      max-height: calc(318px - 54.5px);
    }
    .row_box:nth-child(even) {
      background-color:#fafafa;
    }
  }
  .b-bottom {
    padding: 1.5rem 2rem;
    border-bottom: 1px solid #e8e8e8;
  }
  .selected {
    border: 1px solid #2981EF;
    color: #2981EF;
  }
  .emojis_box {
    min-height: 30px;

    .icon-emoji {
      height: 30px;
    }
  }
  .table-item-index {
    width: 1rem;
    min-width: 1rem;
    max-width: 25px;
  }
  .mobile {
    @media (min-width: 1419px) {
      display: none;
    }
  }
  .desktop {
    @media (max-width: 1420px) {
      display: none;
    }
  }
  .float_box {
    position: absolute;
    top: 2rem;
    right: 2rem;
    
    @media (max-width: 480px) {
      position: relative;
      width: 100%;
      margin: 0 2rem 3rem;
    }
    div:last-child {
      margin-bottom: 0 !important;
    }
  }
  .item {
    padding: 1rem 0;
    border-bottom: 1px solid #e8e8e8;
    
    &:last-child {
      border-bottom: none;
      padding-bottom: 0;
    }
  }
  .no_data-card {
    width: 100%;
    background-color: #fafafa;
    color: #767676;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    height: 320px;
    border: 1px solid #e8e8e8;
    border-radius: 1rem;
  }
}
</style>
