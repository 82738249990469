<template>
  <div @click.stop="dummie" v-click-outside="toggleColor">
    <section class="list">
      <label :for="`inputTag-${id}`" class="tag-input">
        <div
          class="tags-modal"
          v-for="(_tag, index) in listTagsProps"
          :key="index"
        >
          <span
            class="tag-text text-state text-state-fill"
            :style="[{ backgroundColor: _tag.label_color || _tag.color || '#767676' }]"
          >
            {{ _tag.label_name }}
            <i class="fas fa-times ml-2 mr-1" @click.stop="removeTagFromElement(_tag, index)" />
          </span>
        </div>
        <input
          v-if="!disableInput"
          ref="inputTag"
          class="ml-2"
          type="text"
          maxlength="50"
          v-model.trim="tag"
          @keyup.stop="handleTyping"
          :disabled="disableInput"
          :placeholder="placeholderInput"
          :id="`inputTag-${id}`"
        />
      </label>
      <p class="tagInfo">{{ tagInfo }}</p>
      <div class="list-tags pb-3 px-3">
        <div
          class="tags-select"
          v-for="(_tag, index) in listGeneralTags"
          :key="index"
        >
          <div class="bg-tag d-flex align-items-center">
            <img
              class="table-icons i-drag-drop"
              src="/img/icons/dashboard/drag-drop-icon.svg"
              alt="drag-drop"
            />
            <span
              @click.stop="insertTag(_tag.label_name, _tag.label_color || _tag.color)"
              class="tag-text text-state text-state-fill ml-2"
              :style="[{ backgroundColor: _tag.label_color || _tag.color || '#767676' }]"
            >
              {{ _tag.label_name }}
            </span>
            <div class="ml-auto toggle-color" @click.stop="toggleColor(_tag.id)">
              <img
                class="table-icons i-submenu ml-auto"
                src="/img/icons/dashboard/submenu-icon.svg"
                alt="submenu"
              />
            </div>
          </div>
          <ModalEtiquetasColor
            v-show="_tag.id == activeItem"
            @closeModalColor="toggleColor()"
            @deleteTag="deleteGlobalTag($event)"
            @update-label="updateTag($event)"
            :colorProps="colorTags"
            :tagSelect="_tag"
            :allTags="listGeneralTags"
            :tagName="_tag.label_name"
          />
        </div>
        <p class="text-enter-tag">Presiona <span>Enter</span> para crear</p>
      </div>
    </section>
  </div>
</template>

<script>
import ModalEtiquetasColor from './ModalEtiquetasColor.vue';

export default {
  props: [
    'listTagsProps',
    'id',
    'botId',
    'listGeneralTags',
    'listGlobalProps',
  ],
  components: { ModalEtiquetasColor },
  data() {
    return {
      tag: '',
      filterTags: '',
      disableInput: false,
      tagInfo: 'Crea o selecciona un tag',
      validationLabelBots: false,
      tagExistsInput: '',
      listExist: [],
      placeholderInput: 'Nuevo tag',
      colorTags: [
        {
          name: 'Rojo',
          hex: '#F5365C',
        },
        {
          name: 'Verde',
          hex: '#7AD676',
        },
        {
          name: 'Amarillo',
          hex: '#FFCD38',
        },
        {
          name: 'Azul',
          hex: '#2A81EF',
        },
        {
          name: 'Negro',
          hex: '#181818',
        },
        {
          name: 'Rosa',
          hex: '#D94AA2',
        },
        {
          name: 'Morado',
          hex: '#A05DD1',
        },
        {
          name: 'Turquesa',
          hex: '#43D5C8',
        },
        {
          name: 'Verde oscuro',
          hex: '#44955A',
        },
        {
          name: 'Gris claro',
          hex: '#B2B2B2',
        },
      ],
      activeItem: null,
      isActive: false,
      listGlobal: this.listGlobalProps,
    };
  },
  watch: {
    listTagsProps: {
      immediate: true,
      handler(val) {
        if (this.listTagsProps.length == 0) {
          this.tagInfo = 'Crea un nuevo tag';
          this.disableInput = false;
        } else if (this.listTagsProps.length < 10) {
          this.tagInfo = 'Crea o selecciona un tag';
          this.disableInput = false;
        } else {
          this.tagInfo = 'Máximo de tags';
          this.disableInput = true;
        }
      },
    },
    listGeneralTags: {
      immediate: true,
      handler(val) {
        if (this.listGeneralTags.length == 0) {
          this.tagInfo = 'Crea un nuevo tag';
          this.disableInput = false;
          this.validationLabelBots = false;
        } else if (this.listGeneralTags.length < 10) {
          this.tagInfo = 'Crea o selecciona un tag';
          this.disableInput = false;
          this.validationLabelBots = false;
        } else {
          this.tagInfo = 'Agregar tag existente';
          this.validationLabelBots = true;
        }
      },
    },
  },
  methods: {
    toggleColor(item) {
      this.isActive = !this.isActive;
      this.activeItem = item;
    },
    closeModal() {
      this.$emit('closeModalTags', this.id);
      this.activeItem = false;
    },
    insertTag(tag, color) {
      if (!this.tagExists(tag)) {
        this.addTag(tag, color);
        this.tag = '';
      } else {
        this.tagInfo = 'Selecciona otro tag';
      }
    },
    addTag(tag, color) {
      if (!this.validationLabelBots) {
        if (this.listGeneralTags.length > 0) {
          this.filterTags = this.listGeneralTags.find(e => e.label_name == tag);
        } else {
          this.filterTags = false;
        }
        if (!this.filterTags) {
          if (this.listTagsProps.length < 10) {
            if (tag.length <= 15) this.$emit('addNewTag', { tag, color: this.colorTags[color].hex, id: this.id });
            else this.tagInfo = "Máximo de 15 caracteres";
          } else {
            this.tagInfo = 'Máximo de tags';
            this.placeholderInput = '';
          }
        } else {
          this.$emit('addExistingTag', { tag, color, id: this.id, label_element_id: this.filterTags.id });
          this.tagInfo = 'Crea o selecciona un tag';
        }
      } else {
        this.filterTags = this.listGlobal.find(e => e.label_name == tag);
        if (this.filterTags) {
          this.$emit('addExistingTag', { tag, color, id: this.id, label_element_id: this.filterTags.id });
        } else {
          this.$swal({
            toast: true,
            position: 'top-end',
            icon: 'warning',
            text: 'Se alcanzó el límite de tags creadas (Max. 10)',
            timer: 4000,
            showConfirmButton: false,
          });
        }
      }
    },
    updateTag(data) {
      this.$emit('update', data);
    },
    deleteGlobalTag(tagName) {
      this.$emit('deleteGlobal', tagName);
    },
    removeTagFromElement(tag, index) {
      this.$emit('removeTagFromElement', { id: this.id, tag, index });
    },
    dummie(){},
    tagExists(tag) {
      return this.listTagsProps.some(e => e.label_name == tag);
    },
    handleTyping(e) {
      if (e.keyCode === 188 || e.keyCode === 13) {
        if (this.tag != '') {
          let tagInput = this.tag.replace(/,/g, '');
          let tagLower = tagInput.toLowerCase();
          let colorInput = this.listTagsProps.length;
          const colorExist = this.listGeneralTags.find(
            e => e.label_name == tagLower,
          );
          if (!this.tagExists(tagLower)) {
            if (colorExist) {
              this.addTag(tagLower, colorExist.label_color || colorExist.color);
              this.tag = '';
            } else {
              this.addTag(tagLower, colorInput);
              this.tag = '';
            }
          } else {
            this.tagInfo = 'Ingresa un nuevo tag';
          }
        }
      }
    },
  },
};
</script>

<style lang="scss">
.swal2-popup.swal2-toast .swal2-icon {
  margin-right: 10px;
}
</style>

<style lang="scss" scoped>
.list {
  position: absolute;
  top: 0;
  width: auto;
  background-color: #fff;
  border-radius: 0.5rem;
  z-index: 4;
  animation-name: fade-menu;
  animation-duration: 0.25s;
  box-shadow: 10px 20px 60px rgba(0, 0, 0, 0.2);
  transition: 0.5s;
  cursor: default;
  input {
    width: 45%;
    padding: 10px 5px;
    border: none;
    background-color: #fafafa;
    border-radius: 0 0.5rem 0.5rem 0;
    font-weight: 400;
    color: #181818;
  }
  label.tag-input {
    display: flex;
    width: 290px;
    background-color: #fafafa;
    align-items: center;
    height: fit-content;
    flex-wrap: wrap;
    justify-content: flex-start;
    flex-direction: row;
    border: 1px solid transparent;
    border-radius: 0.5rem 0.5rem 0 0;
    transition: 0.25s;

    span i {
      color: #fff;
    }
  }
  .i-submenu {
    border: 1px solid transparent;
    border-radius: 0.5rem;
    padding: 0.3rem 0.65rem;
    transition: 0.25s;

    &:hover {
      border-radius: 0.5rem;
      background-color: #fff;
      border-color: #cdcdcd;
      transition: 0.25s;
    }
  }
}
.text-state-fill {
  margin: 0.5rem 0 0.5rem 0;
}

.tags-modal {
  background-color: #fafafa;
  margin-left: 10px;
}

.tag-input:has(input:focus) {
  transition: 0.5s;
  border-color: #2981ef;
}
.close-list {
  font-size: 12px;
  padding: 3px 5px;
  border-radius: 10px;
  z-index: 99;
  right: 36px;
  position: relative;
  background-color: #767676;
  color: white;
}

.tags-select {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  &:hover {
    background-color: #fafafa;
  }
}
.pl-3,
.px-3 {
  padding-left: 0 !important;
}
.list-tags.pb-3.px-3 {
  padding: 0 !important;
}
.tagInfo {
  padding: 10px;
  font-size: 12px;
  font-weight: 400;
  color: #b2b2b2;
  // cursor: default
}

.bg-tag {
  margin-left: 10px;
  width: 93%;
}
.text-enter-tag {
  font-size: 11px;
  font-weight: 400;
  color: #b2b2b2;
  padding: 10px 12px;

  span {
    color: #2a81ef;
  }
}
span.tag-text {
  padding: 1px 10px;
}
.toggle-color {
  cursor: pointer;
}
</style>
