<template>
  <section class="mx-4">
    <div 
      v-for="product in products" 
      :key="product.id" 
      class="d-flex align-items-center py-3"
      :class="{
        container: products.length > 1
      }"
    >
      <img :src="product.url_image" alt="imagen de producto" class="small_image">
      <span class="pl-2 text-truncate w-100">{{ product.item }}</span>
      <span class="pl-2">
        {{ 
          new Intl.NumberFormat(
            currencyLocale.find(
              (e) => e.currency == (product.currency || 'CLP'),
            ).locale || 'es-CL',
            {
              currency: product.currency || 'CLP',
              style: 'currency',
            },
          ).format(product.price)  
        }}
      </span>      
    </div>
  </section>
</template>

<script>
export default {
  props: [
    'products',
  ],
  data() {
    return {
      currencyLocale: [
        {
          currency: 'PEN',
          locale: 'es-PE',
        },
        {
          currency: 'CLP',
          locale: 'es-CL',
        },
        {
          currency: 'EUR',
          locale: 'es',
        },
      ]
    }
  },
}
</script>

<style lang="scss" scoped>
.small_image{
  min-width: 40px;
  height: 40px;
  border-radius: 5px;
  overflow: hidden;
  border:1px solid #ddd;
  object-fit: cover;
}
.view_button {
  background-color: transparent;
  border: 1px solid #888;
  border-radius: 7px;
  color:#888;
  &:hover {
    border: 1px solid #2981ef;
    color:#2981ef
  }
}
.container {
  border-bottom: 1px solid #ddd;

}
</style>