<template>
  <FadeTransition :duration="200">
    <section v-show="selected == 'woocommerce'">
      <hr class="my-5" />
      <h3>Integrar Woocommerce</h3>
      <p class="mb-5">Si utilizas otra plataforma para manejar los datos de tus clientes, desde aquí podrás integrarla.</p>
      <div class="row mt-5 mb-4">
        <div class="col-lg-6 pl-0">
          <p class="text_error" v-if="error == 'error_wkey'">Completa el consumer key</p>
          <label v-else>Consumer key</label>
          <input
            :class="{border_error: error == 'error_wkey'}"
            placeholder="Ingresa tu consumer key"
            type="text"
            v-model="woocommerce.wkey"
          />
        </div>
        <div class="col-lg-6 pr-0">
          <p class="text_error" v-if="error == 'error_secret'">Completa el consumer secret</p>
          <label v-else>Consumer secret</label>
          <input
            :class="{border_error: error == 'error_secret'}"
            placeholder="Ingresa tu consumer secret"
            type="text"
            v-model="woocommerce.secret"
          />
        </div>
      </div>
      <div class="row mt-5 mb-4">
        <div class="col-lg-6 pl-0">
          <p class="text_error" v-if="error == 'error_wdomain'">Completa el dominio</p>
          <label v-else class="mb-2">Tu dominio</label>
          <input
            :class="{border_error: error == 'error_wdomain'}"
            placeholder="Ejem: https://tu-dominio-woocommerce.com"
            type="text"
            v-model="woocommerce.wdomain"
          />
        </div>
        <div class="col-lg-6 p3-0">
          <p class="text_error" v-if="error == 'error_checkout'">Completa el checkout</p>
          <label v-else class="mb-2">Dirección de checkout</label>
          <input
            :class="{border_error: error == 'error_checkout'}"
            placeholder="Ejem: carrito"
            type="text"
            v-model="woocommerce.checkout"
          />
        </div>
      </div>
      <div class="row justify-content-between">
        <button
          @click="saveIntegration()"
          class="btn btn-base md mx-0"
          :disabled="loading || view_permissions.edit != true"
          >{{ !edit ? 'Guardar' : 'Guardar cambios' }}
          <span class="tooltip_text-sm for_disabled" v-if="view_permissions.edit != true">Lo sentimos, no tienes permisos para guardar cambios.</span>
        </button>
        <button
          class="btn btn-base outline md mx-0"
          @click="$emit('closeView')"
          >Cancelar
        </button>
      </div>
      <hr class="mt-5 mb-3" />
    </section>
  </FadeTransition>
</template>

<script>
import { FadeTransition } from "vue2-transitions";
import dashboard_api from "@/dashboard_api.js";
import axios from "axios"
import environment from '@/environment.js';

export default {
  components: {
    FadeTransition
  },
  props: [
    "selected",
    "bot_id",
    "fields",
    "integrations",
    "view_permissions"
  ],
  data() {
    return {
      edit: false,
      loading: false,
      credentials: {},
      error: '',
      woocommerce: {
        wkey: '',
        secret: '',
        wdomain: '',
        checkout: ''
      }
    };
  },
  watch: {
    selected(val) {
      if (this.integrations && this.integrations.length > 0) {
        for (let integration of this.integrations) {
          if (this.selected == integration.provider) {
            this.edit = true;
            this.integrationId = integration.id;
            this.credentials = integration.credentials;
            this.getCredentials();
          } else {
            this.edit = false;
          }
        }
      }
    },
    fields(val) {
      this.edit = false,
      this.woocommerce.wkey = '';
      this.woocommerce.secret = '';
      this.woocommerce.wdomain = '';
      this.woocommerce.checkout = '';
    },
    loading(val) {
      if(val === true){
        this.$swal({
          title: 'Cargando',
          allowOutsideClick: false,
          onBeforeOpen: () => {
              this.$swal.showLoading()
          },
        });
      }
    }
  },

  methods: {
    async checkCredentials(domain, key, secret) {
      try {
        const config = {
          method: 'POST',
          url: `${environment.url_automations}/check_cms_credentials`,
          data: {
            provider: "woocommerce",
            credentials: {
              wooDomain: domain,
              wooKey: key,
              wooSecret: secret
            }
          },
        }
        const res = await axios.request(config)
        return res.data
      } catch (error) {
        return false
      }
    },
    getCredentials() {
      let currentCredentials = JSON.parse(this.credentials);
      this.woocommerce.wkey = currentCredentials.wkey;
      this.woocommerce.secret = currentCredentials.secret;
      this.woocommerce.wdomain = currentCredentials.wdomain;
      this.woocommerce.checkout = currentCredentials.checkout;
    },
    async saveIntegration() {
      let status = true;
      this.loading = true;

      const { wkey, secret, wdomain, checkout } = this.woocommerce;

      if (wkey.length == 0) {
        this.error = 'error_wkey';
        this.loading = false;
      } else if (secret.length == 0) {
        this.error = 'error_secret';
        this.loading = false;
      } else if (wdomain.length == 0) {
        this.error = 'error_wdomain';
        this.loading = false;
      } else if (checkout.length == 0) {
        this.error = 'error_checkout';
        this.loading = false;
      } else if (!this.edit) {
          const credValidation = await this.checkCredentials(wdomain, wkey, secret)
          if(!credValidation) {
            this.$swal({
              icon: "error",
              title: "Credenciales no válidas",
            });
            this.loading = false
            return
          }
          dashboard_api
            .post("/user_credentials", {
              provider: this.selected,
              credentials: JSON.stringify(this.woocommerce),
              status,
              bot_id: this.bot_id,
            },
          ).then(async resp => {
            this.$swal({
              icon: "success",
              title: "¡Woocommerce integrado!",
              timer: 1000,
              showConfirmButton: false,
            });
            this.loading = false;
            this.error = '';
            this.credentials = {};
            this.$emit('closeView');
            this.$emit('getIntegrations');
            try {
              const config = {
                method: 'POST',
                url: `${environment.url_automations}/charge_products_by_bot`,
                data: {
                  "bot_id": this.bot_id
                },
              }
              await axios.request(config)
            } catch (error) {
              console.log("loading products error", error)
            }
          })
          .catch(error => console.log('error->', error));
        } else {
          const credValidation = await this.checkCredentials(wdomain, wkey, secret)
          if(!credValidation) {
            this.$swal({
              icon: "error",
              title: "Credenciales no válidas",
            });
            this.loading = false
            return
          }
          dashboard_api
            .put(`user_credentials/${this.integrationId}`, {
              credentials: JSON.stringify(this.woocommerce)
            }).then((async resp => {            
              this.$swal({
                icon: "success",
                title: "Integración modificada",
                timer: 1000,
                showConfirmButton: false,
              });
              this.loading = false;
              this.error = '';
              this.$emit('closeView');
              this.$emit('getIntegrations');
              try {
                const config = {
                  method: 'POST',
                  url: `${environment.url_automations}/charge_products_by_bot`,
                  data: {
                    "bot_id": this.bot_id
                  },
                }
                await axios.request(config)
              } catch (error) {
                console.log("loading products error", error)
              }
            }));
        }
    }
  }
};
</script>
