<template>
  <section class="px-3">
    <ChatMessages
      :messages="messages"
      :bot="bot"
      :getImgBot="getImgBot"
      :user="user"
      :conversation="conversation"
    />
  </section>
</template>
<script>
import ChatMessages from '../../Conversations/components/ChatMessages.vue';
export default {
  props: [
    'messages',
    'bot',
    'getImgBot',
    'user',
    'conversation'
  ],
  components: {
    ChatMessages
  }
}
</script>

<style lang="scss" scoped>
</style>