import * as echarts from 'echarts';

export default {
  methods: {
    donutChart(id, title, sub_title, data) {
      var myChart = echarts.init(document.getElementById(id));

      window.addEventListener('resize', () => {
        myChart.resize();
      });

      // Draw the chart
      myChart.setOption({
        title: {
          text: title,
          left: 'left',
          top: 'top',
          subtext: sub_title,
          padding: [0, 0, 10, 0],
          textStyle: {
            fontSize: 15,
            fontWeight: 700,
            fontFamily: "Manrope, sans-serif",
            color: '#181818',
          },
        },
        toolbox: {
          feature: {
            saveAsImage: {},
            // magicType: {
            //   type: ['line', 'bar', 'stack'],
            // },
          },
        },
        tooltip: {
          trigger: 'item',
          formatter: '{b}: {c} ({d}%)' // '{b}' es el nombre, '{c}' es el valor, '{d}' es el porcentaje
        },
        color: ['#cdcdcd', '#71B1FF', '#2981EF'],
        // #E3F0FF
        series: [
          {
            type: 'pie',
            data,
            radius: ['50%', '70%'],
            itemStyle: {
              opacity: 0.75
            }
          }
        ],
        textStyle: {
          fontFamily: "Manrope, sans-serif"
        },
      });
    }
  },
};
