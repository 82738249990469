import * as echarts from 'echarts';

export default {
  methods: {
    pieChart(element, title, data) {
      var myChart = echarts.init(document.getElementById(element));

      window.addEventListener('resize', () => {
        myChart.resize();
      });

      myChart.setOption({
        // legend: {
        //   orient: 'vertical',
        //   x: 'left',
        //   data: ['A', 'B', 'C', 'D', 'E']
        // },
        color: ['#2981ef', '#cdcdcd', '#181818', '#deedff'],
        series: [
          {
            type: 'pie',
            radius: ['50%', '70%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center'
            },
            labelLine: {
              show: false
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '30',
                fontWeight: 'bold'
              }
            },
            data: [
              { value: 335, name: 'C' },
              { value: 310, name: 'L' },
            ]
          }
        ]
      });
    }
  },
};
