import * as echarts from 'echarts';

export default {
  methods: {
    barChart(months, data, id, title, label) {
      let chartDom = document.getElementById(id);
      var myChart = echarts.init(chartDom, null, {
        renderer: 'svg',
      });

      window.addEventListener('resize', function () {
        myChart.resize();
      });

      myChart.setOption({
        title: {
          text: title,
          textStyle: {
            fontSize: 15,
            fontWeight: 700,
            fontFamily: "Manrope, sans-serif",
            color: ['#181818'],
          },
        },
        toolbox: {
          feature: {
            saveAsImage: {},
            // magicType: {
            //   type: ['line', 'bar', 'stack'],
            // },
          },
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: (params) => {
            let tooltipText = '';

            params.forEach(item => {
              tooltipText += `
                <span class="text_sm">${item.name}</span><br/>${item.marker} ${label ? label : title}: <label class="mb-0 ml-2">${item.value}</label><br/>
                `;
            });
            return tooltipText;
          }
        },
        xAxis: {
          data: months
        },
        yAxis: {},
        series: [
          {
            // name: 'serie',
            type: 'bar',
            data: data,
            barWidth: '25%',
            itemStyle: {
              color: '#2981EF',
              // shadowColor: '#91cc75',
              borderType: 'dashed',
              opacity: 0.75
            }
          }
        ]
      });
    }
  },
};
