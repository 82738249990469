import * as echarts from 'echarts';

export default {
  methods: {
    setFormatSize(links, nodes, topic) {
      let sum = 0;
      let categories = [];
      let targets = [];

      // Modificar size de topicos + grande
      links.forEach(el => {
        if (el.source == topic) categories.push(el.target);
        if (categories.includes(el.source)) targets.push(el.target);
      });
      
      nodes.forEach(el => {
        if (targets.includes(el.id)) sum = sum + Number(el.name) * 0.2;
      });
      console.log("sum->", sum);

      if (sum > 50) return 50;
      else return sum;
    },
    NodesChart(id, data, title, type) {
      let chartDom = document.getElementById(id);
      var myChart = echarts.init(chartDom, null, {
        renderer: 'svg',
      });

      window.addEventListener('resize', () => {
        myChart.resize();
      });
      if (data.nodes?.length > 0) {
        data.nodes.forEach(async (node) => {
          if (node.category == 0) {
            node.symbolSize = this.setFormatSize(data.links, data.nodes, node.id);
          } else {
            node.symbolSize = 10;
          }
          node.label = {
            show: true
          };
        });
      }

      myChart.setOption({
        title: {
          text: title
        },
        tooltip: { trigger: "item" },
        legend: {
          data: data.categories.map((cat) => cat.name),
          top: "5%",
          left: "right"
        },
        series: [
          {
            type: "graph",
            layout: type,
            roam: true, // Permitir zoom y desplazamiento
            draggable: true, // Permitir arrastrar nodos
            data: data.nodes.map((node) => ({
              ...node
            })),
            links: data.links,
            categories: data.categories,
            label: {
              show: true,
              position: "right",
            },
            force: {
              repulsion: 900,
              gravity: 0.02,
            },
            lineStyle: {
              color: "#cdcdcd",
            },
            color: ['#2981EF', '#71B1FF', '#E3F0FF'],
            emphasis: {
              focus: "adjacency",
              lineStyle: {
                width: 3,
              },
            },
          },
        ],
      });
    }
  },
};
