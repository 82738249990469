<template>
  <section :class="{ 'dashboard-ecommerce': true, 'mx-0': metrics }">
    <div :class="{ container: dashboard, col: metrics }">
      <div v-if="dashboard" class="row align-items-center my-3">
        <h2 class="title">Datos de tu ecommerce de los últimos 30 días</h2>
      </div>
      <div :class="{ 'grid-container': true, 'grid-5': dashboard || metrics }">
        <!-- CUANDO DESCOMENTEN ESTO, EN STYLES DESCOMENTAR grid-template-columns-->
        <!-- <div class="grid-item">
          <label class="mb-3">Total de visitas <br />al sitio</label>
          <div class="row mr-0">
            <div class="col-6 pl-0">
              <div class="number_box mr-2">
                <p class="number" v-if="visitedPage">
                  {{ visitedPage.toLocaleString("de-DE") }}
                </p>
                <p class="number" v-else>
                  0
                </p>
              </div>
            </div>
          </div>
        </div> -->
        <div v-if="dashboard" class="grid-item d-flex">
          <div class="info-side col-lg-8 px-0">
            <div class="mb-3 justify-content-between">
              <label>Total de <br />conversaciones</label>
            </div>
            <div class="row">
              <!-- <div class="col-5 pl-0">
              <div class="number_box mr-2">
                <p class="number number-gray" v-if="totalConversations">
                  {{ getPorcent(totalConversations, visitedPage) }}%
                </p>
                <p class="number" v-else>
                  0%
                </p>
              </div>
            </div> -->
              <div class="col-6 pl-0">
                <div class="number_box mr-2">
                  <p class="number" v-if="totalConversations">
                    {{ totalConversations.toLocaleString('de-DE') }}
                  </p>
                  <p class="number" v-else>0</p>
                </div>
              </div>
            </div>
          </div>
          <div class="image-side-1 col-lg-4 d-flex justify-content-center pr-0">
            <img
              class="icon-label"
              alt="Total de conversaciones"
              src="/img/icons/dashboard/conversations.svg"
            />
          </div>
        </div>
        <div class="grid-item d-flex">
          <div class="info-side col-lg-8 px-0">
            <div class="mb-3 justify-content-between">
              <label>Conversaciones con <br />recomendación</label>
            </div>
            <div class="row align-items-center">
              <div class="number_box mr-2">
                <p class="number" v-if="recomendationConversation">
                  {{ recomendationConversation.toLocaleString('de-DE') || 0 }}
                </p>
                <p class="number" v-else>0</p>
              </div>
              <span class="text_sm" v-if="recomendationConversation">
                {{ getPorcent(recomendationConversation, totalConversations) }}%
              </span>
              <span class="text_sm" v-else>0%</span>
            </div>
          </div>
          <div class="image-side col-lg-4 d-flex justify-content-center pr-0">
            <img
              class="icon-label"
              alt="Total de conversaciones"
              src="/img/icons/dashboard/recomendations.svg"
            />
          </div>
        </div>
        <div class="grid-item d-flex">
          <div class="info-side col-lg-8 px-0">
            <div class="mb-3 justify-content-between">
              <label>Total de productos <br />recomendados</label>
            </div>
            <div class="row">
              <div class="number_box mr-2">
                <p class="number" v-if="productRecommended">
                  {{ productRecommended.toLocaleString('de-DE') }}
                </p>
                <p class="number" v-else>0</p>
              </div>
            </div>
          </div>
          <div class="image-side-1 col-lg-4 d-flex justify-content-center pr-0">
            <img
              class="icon-label"
              alt="Total de productos recomendados"
              src="/img/icons/dashboard/products.svg"
            />
          </div>
        </div>
        <div class="grid-item d-flex">
          <div class="info-side col-lg-8 px-0">
            <div class="mb-3 justify-content-between">
              <label>Productos agregados al <br />carro por chatbot</label>
            </div>
            <div class="row align-items-center">
              <div class="number_box mr-2">
                <p class="number number-gray" v-if="addCartBot">
                  {{ addCartBot.toLocaleString('de-DE') }}
                </p>
                <p class="number number-gray" v-else>0</p>
              </div>
              <span class="text_sm number-gray" v-if="addCartBot">
                {{ getPorcent(addCartBot, productRecommended) }}%
              </span>
              <span class="text_sm" v-else>0%</span>
            </div>
          </div>
          <div class="image-side col-lg-4 d-flex justify-content-center px-0">
            <img
              class="icon-label"
              alt="Productos agregados al carro por chatbot"
              src="/img/icons/dashboard/cart.svg"
            />
          </div>
        </div>
        <div :class="{ 'grid-item d-flex': true, 'grid-item-destacado': dashboard }">
          <div class="info-side col-lg-8 px-0">
            <div class="mb-3 justify-content-between">
              <label class="mb-3">
                Total de
                <span v-if="selected_bot.id == 1983">MXN</span>
                <span v-else-if="selected_bot.id == 2033">S/</span>
                <span v-else>CLP</span> <br />agregados al carro
              </label>
            </div>
            <div class="row">
              <div class="col-12 px-0">
                <div :class="{ 'number_box mr-2': true, destacado_box: dashboard }">
                  <p class="number destacado_number" v-if="totalMoneyAddCart">
                    $ {{
                      selected_bot.id == 2033 ?
                        isLocalcurrency == 'es-PE' ?
                          totalMoneyAddCart.toLocaleString(isLocalcurrency) :
                          totalMoneyAddCart.toLocaleString('es-PE') :
                        totalMoneyAddCart.toLocaleString('de-DE')
                      }}
                  </p>
                  <p class="number destacado_number" v-else>0</p>
                </div>
              </div>
            </div>
          </div>
          <div class="image-side-1 col-lg-4 d-flex justify-content-center pr-0">
            <img
              class="icon-label"
              alt="Total de dinero agregado al carro"
              src="/img/icons/dashboard/money.svg"
            />
          </div>
        </div>
        <div v-if="metrics" class="grid-item d-flex grid-item-destacado">
          <div class="info-side col-lg-8 px-0">
            <div class="mb-3 justify-content-between">
              <label class="mb-3">Dinero pagado</label>
            </div>
            <div class="row">
              <div class="col-12 px-0">
                <div class="number_box destacado_box mr-2">
                  <p class="number destacado_number">$ {{ currencyFormat(this.total_payd_money['últimos 30 días']) || 0 }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="image-side-1 col-lg-4 d-flex justify-content-center pr-0">
            <img
              class="mr-4"
              alt="Total de dinero agregado al carro"
              src="/img/icons/dashboard/paid-money.svg"
            />
          </div>
        </div>
      </div>
      <!-- <div class="grid-container-2 mt-4">
        <div class="grid-item item1 p-0">
          <div class="card d-flex h-100 p-4">
            <div class="d-flex justify-content-between">
              <label class="mb-2 title-black">10 Productos más recomendados</label>
              <label class="text_sm">Último mes</label>
            </div>
            <table>
              <tr>
                <td>1. Taladro pulento XLM 5000</td>
                <td class="text-right">1000</td>
              </tr>
              <tr>
                <td>2. Martillo automatico numero XDL</td>
                <td class="text-right">900</td>
              </tr>
              <tr>
                <td>3. Taladro pulento XLM 5000</td>
                <td class="text-right">800</td>
              </tr>
              <tr>
                <td>4. Martillo automatico numero XDL</td>
                <td class="text-right">600</td>
              </tr>
              <tr>
                <td>5. Taladro pulento XLM 5000</td>
                <td class="text-right">570</td>
              </tr>
              <tr>
                <td>6. Martillo automatico numero XDL</td>
                <td class="text-right">430</td>
              </tr>
              <tr>
                <td>7. Taladro pulento XLM 5000</td>
                <td class="text-right">367</td>
              </tr>
              <tr>
                <td>8. Martillo automatico numero XDL</td>
                <td class="text-right">190</td>
              </tr>

              <tr>
                <td>9. Taladro pulento XLM 5000</td>
                <td class="text-right">100</td>
              </tr>
              <tr>
                <td>10. Martillo automatico numero XDL</td>
                <td class="text-right">60</td>
              </tr>
            </table>
          </div>
        </div>
        <div class="grid-item item2 p-0">
          <div class="card d-flex h-100 p-4">
            <div class="d-flex justify-content-between">
              <label class="mb-2 title-black">10 Productos más agregados al carro</label>
              <label class="text_sm">Último mes</label>
            </div>
            <table>
              <tr>
                <td>1. Taladro pulento XLM 5000</td>
                <td class="text-right">1000</td>
              </tr>
              <tr>
                <td>2. Martillo automatico numero XDL</td>
                <td class="text-right">900</td>
              </tr>
              <tr>
                <td>3. Taladro pulento XLM 5000</td>
                <td class="text-right">800</td>
              </tr>
              <tr>
                <td>4. Martillo automatico numero XDL</td>
                <td class="text-right">600</td>
              </tr>
              <tr>
                <td>5. Taladro pulento XLM 5000</td>
                <td class="text-right">570</td>
              </tr>
              <tr>
                <td>6. Martillo automatico numero XDL</td>
                <td class="text-right">430</td>
              </tr>
              <tr>
                <td>7. Taladro pulento XLM 5000</td>
                <td class="text-right">367</td>
              </tr>
              <tr>
                <td>8. Martillo automatico numero XDL</td>
                <td class="text-right">190</td>
              </tr>

              <tr>
                <td>9. Taladro pulento XLM 5000</td>
                <td class="text-right">100</td>
              </tr>
              <tr>
                <td>10. Martillo automatico numero XDL</td>
                <td class="text-right">60</td>
              </tr>
            </table>
          </div>
        </div>
        <div class="grid-item item3">
          <label class="mb-2">Categorías más recomendadas</label>
          <div class="d-flex align-items-center justify-content-between">
            <div class="list-item">
              <p>1. Herramientas</p>
              <p>2. Soporte</p>
              <p>3. Motores</p>
            </div>
          </div>
        </div>
        <div class="grid-item item4">
          <label class="mb-2">Categorías más agregadas</label>
          <div class="d-flex align-items-center justify-content-between">
            <div class="list-item">
              <p>1. Herramientas</p>
              <p>2. Soporte</p>
              <p>3. Motores</p>
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </section>
</template>

<script>
import dashboard_api from '@/dashboard_api.js';
import { mapState } from 'vuex';

export default {
  props: ['dashboard', 'metrics', 'total_payd_money'],

  data() {
    return {
      totalConversations: 0,
      recomendationConversation: 0,
      productRecommended: 0,
      addCartBot: 0,
      totalMoneyAddCart: 0,
      visitedPage: 0,
    };
  },
  methods: {
    currencyFormat(price) {
      if (price) return new Intl.NumberFormat().format(price);
    },
    getStatisticsEcommerce() {
      dashboard_api
        .get(
          `/dashboards/dashboard_data/${this.selected_bot.id}`,
        )
        .then(result => {
          this.visitedPage = result.data.total_visit_web_site;
            this.totalConversations = result.data.total_conversations;
            this.recomendationConversation =
              result.data.recommendations_per_conversation;
            this.productRecommended = result.data.total_recommended_products;
            this.addCartBot = result.data.total_products_added_to_cart;
            this.totalMoneyAddCart = result.data.total_money_added_to_cart;
          });

      // TODO for debug
      if (this.selected_bot.id == 2033) console.log("totalMoneyAddCart->", this.totalMoneyAddCart);

      // console.log("locale->", Intl.DateTimeFormat().resolvedOptions().locale);
    },
    getPorcent(calA, calB) {
      return (this.result = Math.round((calA * 100) / calB));
    },
  },
  mounted() {
    this.getStatisticsEcommerce();
  },
  computed: {
    ...mapState(['selected_bot', 'user']),

    isLocalcurrency() {
      return Intl.DateTimeFormat().resolvedOptions().locale;
    }
  },
};
</script>

<style lang="scss" scoped>
.dashboard-ecommerce {
  margin: 4rem auto 0;
  
  @media (max-width: 1420px) {
    margin: 4rem 1rem 0;
  }
  .grid-container {
    display: grid;
    grid-gap: 1rem;
  }
  .grid-5 {
    grid-template-columns: repeat(5, 1fr);

    @media (max-width: 1420px) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media (max-width: 768px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  .grid-item {
    background-color: #fff;
    border: 1px solid #e8e8e8;
    border-radius: 1rem;
    padding: 1rem;
    animation: fadeInUp 1s ease backwards;

    .info-side {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .number_box {
        width: fit-content;
      }
    }
    .image-side {
      padding: 0 0 0 15px;
    }
    .image-side-1 .icon-label {
      width: 65px;
    }
    .icon-label {
      width: 40px;
      filter: drop-shadow(5px 5px 8px #bfdbff);
    }
  }
  .grid-item-destacado {
    border: 1px solid #2981ef;
  }
  .destacado_box {
    background-color: #2981ef;

    .destacado_number {
      color: #f2f2f2;
    }
  }
}
@keyframes fadeInUp {
  0% {
    transform: translate(0px, 100px);
    opacity: 0;
  }
  100% {
    transform: translate(0px, 0);
    opacity: 1;
  }
}
</style>
