<template>
  <Modal
    class="modal-2"
    ancho="600"
    alto="500"
    :show="showUnAnswered"
    @close="$emit('closeModal')"
  >
    <template slot="header">
      <h1 class="titulo__modal mb-4"></h1>
    </template>
    <template slot="content">
      <section class="date-filter">
        <div class="col-lg-12">
          <div class="mb-4">
            <label>Mensajes sin respuesta</label>
            <p>Aquí podrás ver los leads que no tienen respuesta.</p>
          </div>
          <div class="info_box">
            <div
              v-for="item in un_answered"
              :key="item.id"
              class="row justify-content-between align-items-center"
            >
              <p>{{ item.message_name }}</p>
              <p>{{ item.agent }}</p>
              <p>${{ parseFloat(item.price).toFixed(1) }}</p>
              <p>{{ item.sended }}</p>
            </div>
          </div>
        </div>
      </section>
    </template>
  </Modal>
</template>

<script>
export default {
  props: ["showUnAnswered", "un_answered"],
};
</script>

<style lang="scss" scoped>
.date-filter {
  background-color: #fff;
  border-radius: 1rem;
  text-align: left;
  padding: 0 1.5rem 2rem;

  input, select {
    width: 100%;
    margin: 0 0 1rem;
  }
  .btn-date {
    @media (max-width: 1024px) {
      display: none;
    }
  }
}
</style>
