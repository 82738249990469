<template>
  <FadeTransition :duration="200">
    <div v-show="selected == 'instagram'">
      <hr class="my-5" />
      <h3>Conectarse a Instagram</h3>
      <p class="mb-5">
        Si utilizas otra plataforma para manejar los datos de tus clientes,
        desde aquí podrás integrarla.
      </p>
      <template v-if="view_permissions.edit == true">
        <v-facebook-login
          :login-options="{
            scope:
              'instagram_manage_messages,pages_messaging,pages_manage_metadata,instagram_manage_comments,instagram_manage_insights,pages_show_list,instagram_basic,pages_read_engagement,business_management',
          }"
          style="margin: auto"
          @login="facebookLogged"
          app-id="309102442977190"
          class="mb-3 fb-login"
        />
        <div class="my-5" v-show="connectedPages && connectedPages.length > 0">
          <h3 class="mb-4">Cuentas conectadas</h3>
          <span v-for="(connectedPage, idx) in connectedPages" :key="idx" class="btn-select static mr-3">{{ connectedPage.credentials.name }}</span>
        </div>
        <hr class="mt-5 mb-3" />
      </template>
      <div v-else class="info_box">
        <span class="text-center">No tienes accesos para conectarte a este módulo.</span>
      </div>
    </div>
  </FadeTransition>
</template>

<script>
import { mapState } from 'vuex';
import VFacebookLogin from 'vue-facebook-login-component';
import { FadeTransition } from 'vue2-transitions';
import dashboard_api from '@/dashboard_api.js';
import graphApiService from '@/services/api/graphApi.js';

export default {
  components: {
    FadeTransition,
    VFacebookLogin,
  },
  props: ['selected', 'bot_id', 'fields', 'view_permissions'],

  data() {
    return {
      connectedPages: [],
      edit: false,
      loading: false,
    };
  },

  created() {
    this.getInfoInstagram();
  },
  mounted() {
    console.log("Montando ig...");
    // restart fb sdk
    window.fbAsyncInit = () => {
        FB.init({
          appId: '309102442977190',
          cookie: true,
          xfbml: true,
          version: 'v19.0',
        });
      };
    window.fbAsyncInit();
  },

  computed: {
    ...mapState(['user']),

    serchQuery() {
      return !!(
        this.$route.query.client ||
        this.$route.query.channels ||
        this.$route.query.revoked
      );
    },
  },

  watch: {
    selected() {
      this.getInfoInstagram();
    },
    fields(val) {
      this.getInfoInstagram();
    },
    bot_id(val) {
      this.getInfoInstagram();
    },
  },
  methods: {
    async facebookLogged(e) {
      if (e) {
        this.$swal({
          icon: 'info',
          title: 'Integrando cuenta...',
          showConfirmButton: false,
        });
        this.$swal.showLoading();
        console.log('🚀 Aqui *** -> e', e);
        // solo para fines de test, se logea con USUARIO TEST
        const { accessToken, userID } = e.authResponse;
        graphApiService
          .generatePageAccessToken(
            userID,
            accessToken,
            this.bot_id,
            'instagram',
          )
          .then((el) => {
            // append connected pages
            this.$swal({
              icon: 'success',
              title: '¡Instagram integrado!',
              timer: 1000,
              showConfirmButton: false,
            });
            this.loading = false;
            this.$emit('closeView');
          })
          .catch((err) => console.log('err', err));
      }
    },
    getInfoInstagram() {
      dashboard_api
        .get(`/bot_credential/get_by_bot_id/${this.bot_id}`)
        .then((resp) => {
          // filter by instagram
          resp.data = resp.data.filter((item) => item.channel === 'instagram');
          console.log('getInfoInstagram->', resp.data);
          if (resp.data && resp.data.length > 0) {
            this.connectedPages = resp.data;
            this.$emit('getInsta', this.connectedPages);
          }
        });
    },
  },
};
</script>

<style lang="scss">
.fb-login span {
  color: #ffff;
}
</style>
