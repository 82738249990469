<template>
  <section class="business-whatsapp">
    <SpinnerLoading v-if="loading" />
    <FadeTransition v-else :duration="200" v-show="view_permissions.read == true">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-6">
            <div class="row align-items-center mt-5">
              <div class="img_box mr-4">
                <img
                  class="icon"
                  src="/img/icons/9.2-whatsapp-business.svg"
                  alt=""
                />
                <!-- <p class="text-state my-4">{{ botsWithWhatsapp.length > 0 ? 'Disponible' : 'Inactivo' }}</p> -->
              </div>
              <div>
                <h2 class="mb-0">WhatsApp API</h2>
                <a
                  v-if="getCurrentPhone"
                  :key="selected_bot.id"
                  :href="`https://wa.me/${getCurrentPhone}`"
                  target="_blank"
                >
                  https://wa.me/{{ getCurrentPhone }}
                </a>
                <router-link
                  v-else-if="active_view == 'my_bots'"
                  to="/integraciones"
                  >Integrar WhatsApp</router-link
                >
                <p v-else class="text_sm">
                  Para integrar WhatsApp API contacta a un administrador.
                </p>
              </div>
            </div>
            <br />
            <!-- v-if="botsWithWhatsapp && botsWithWhatsapp.length !== 0" -->
            <div v-if="getCurrentPhone">
              <div
                class="row mt-5 mb-2 justify-content-between align-items-center"
              >
                <h2>Plantilla</h2>
                <template
                  v-if="
                    plan_name == 'Plan Basic-web0' ||
                    plan_name == 'Plan Starter-web2'
                  "
                >
                  <!-- TODO Sistema de planes con flow deprecado -->
                  <!-- <router-link
                    class="btn-base disabled md"
                    :to="{
                      name: 'dashboard-planes',
                      query: {
                        seccion: 'mejorar-plan',
                        bot_id: selected_bot.id,
                      },
                    }"
                    ><i class="fas fa-plus" />Agregar plantilla<span
                      class="upgrade-label"
                      >Mejorar</span
                    ></router-link
                  > -->
                </template>
                <button
                  v-else
                  @click="show = true"
                  class="btn btn-base m-0 md"
                  :disabled="view_permissions.add != true"
                ><i class="fas fa-plus" />Agregar plantilla
                  <span class="tooltip_text-sm for_disabled" v-if="view_permissions.add != true">Lo sentimos, no tienes accesos a este módulo.</span>
                </button>
              </div>
              <hr class="mt-4 mb-5" />
              <p>
                Cuando tu negocio desee iniciar una conversación con un contacto
                pasadas las 24 horas de su último mensaje, WhatsApp requiere que
                utilice una plantilla de mensaje. Todas las plantillas de
                mensaje deben ser aprobadas por WhatsApp antes de ser usadas
                para garantizar que sigan las pautas de contenido permitidas.
              </p>
            </div>
            <div v-else>
              <div class="info_box my-5">
                <p>
                  <i class="fas fa-info-circle" /> Aún no tienes una cuenta con
                  WhatsApp API. Conoce más información
                  <a
                    class="destacado"
                    href="https://databot.cl/whatsapp-api"
                    target="_blank"
                    >aquí</a
                  >.
                </p>
              </div>
            </div>
            <br />
            <div class="row justify-content-between align-items-center">
              <div class="col-md-4 p-0">
                <div class="label-box buefy">
                  <div class="buefy d-flex align-items-center">
                    <div class="text-state m-0">
                    <span class="text_sm mr-2">Archivados</span>
                    <b-switch
                    @input="switchArchived"
                      :value="showArchived"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-flex align-items-center flex-wrap">
                <div>
                  <select
                    class="select_md list-w m-0"
                    v-model="selectLabel"
                    @change="filterByLabel()"
                  >
                    <option value>Todas</option>
                    <option v-for="(label, index) in templateLabels" :key="index" :value="label.id">
                      {{ label.label_name[0].toUpperCase() + label.label_name.slice(1) }}
                    </option>
                  </select>
                </div>
                <input
                  placeholder="Buscar plantilla"
                  class="input_search-sm"
                  type="search"
                  v-model="search"
                />
              </div>
            </div>
            <div
              v-for="(template, templateIndex) in templatesByPage"              
              :key="templateIndex"
            >
              <div
                class="d-flex mt-5 mb-2 justify-content-between align-items-center"
              >
                <div v-click-outside="closeModalTag">
                  <label class="mb-1">{{ template.name }}</label>
                  <div class="d-flex align-items-center flex-wrap">
                    <span
                      v-for="(tags, tagIndex) in template.payroll_labels"
                      class="tag-text text-state text-state-fill"
                      :style="[
                        { backgroundColor: tags.color || '#767676' },
                      ]"                    
                      :key="tagIndex"
                    >
                      {{ tags.label_name }}
                    </span>
                    <button                    
                      class="i-btn px-2 mb-2"
                      @click.stop="showModalTag(template.id)"
                      :disabled="view_permissions.edit != true"
                    >+
                      <span class="tooltip_text-sm for_disabled" v-if="view_permissions.edit != true">Lo sentimos, no tienes accesos a este módulo.</span>
                    </button>
                  </div>
                  <div style="position: relative;">
                    <ModalEtiquetas
                      v-show="activeItem === template.id"
                      :listGeneralTags="templateLabels"
                      :listGlobalProps="templateLabels"
                      :listTagsProps="template.payroll_labels"
                      :id="template.id"
                      :botId="selected_bot.id"
                      @update="updateTag($event)"
                      @addNewTag="addNewTag($event, template.id)"
                      @addExistingTag="addExistingTag($event, template.id)"
                      @removeTagFromElement="removeTagFromTemplate($event, template.id)"
                      @deleteGlobal="deleteGlobal($event)"
                    />
                  </div>
                </div>
                <div class="d-flex align-itmes-center">
                  <p class="text-state" :class="template.status">
                    {{
                      template.status === 'approved'
                        ? 'Aprobado'
                        : template.status === 'rejected'
                        ? 'rechazado'
                        : 'Enviado'
                    }}
                  </p>
                  <div class="tooltip-custom d-flex justify-content-center align-items-center">
                    <i class="fas fa-eye ml-2 mr-0 pointer" style="color: black; font-size: 1rem;" @click="selectTemplate(template.id)"></i>
                    <p class="tooltip_text">
                      Ver plantilla completa
                    </p>
                  </div>
                  <div class="tooltip-custom">
                    <img
                      :src="showArchived ? '/img/icons/34.unarchive.svg' : '/img/icons/33.archive.svg'" 
                      @click="archiveMessage(template.id, template.archived)"
                      class="d-flex align-items-center mx-2 click" 
                    >
                    <p class="tooltip_text">
                      {{ showArchived ? 'Desarchivar plantilla' : 'Archivar plantilla' }}
                    </p>
                  </div>
                
                  <i class="bi bi-0-circle"></i>
                </div>
              </div>
              <div tabindex="1" class="div_input">
                {{
                  template.components?.find((el) => el.type === 'BODY')
                    ? template.components?.find((el) => el.type === 'BODY')?.text
                    : ''
                }}
              </div>
            </div>

            <!-- <button class="btn btn-base md my-5">Guardar plantilla</button> -->
          </div>
          <div class="col-lg-4 mt-5 mb-2">
            <!-- <div class="no-scroll_box">
              <div v-if="getCurrentPhone" class="info_box">
                <label>Mensajes enviados</label>
                <div class="d-flex align-items-center">
                  <span class="number_box">{{ TemplateMessages || 0 }}</span>
                  <p>Mensajes de plantilla enviados este mes.</p>
                </div>
                <div class="mt-3">
                  <router-link
                    class="text_md"
                    :to="`/whatsapp-business/mensajes-plantilla?bot_id=${selected_bot.id}`"
                    >Ver resumen completo<i class="fas fa-arrow-right ml-2"
                  /></router-link>
                </div>
              </div>
            </div> -->
            <!-- Saldo -->
            <!-- <div class="scroll_box">
              <span
                class="card"
                @click="saldoWhatsapp">
                <label class="mb-0">Saldo</label>
                <p class="mb-0">
                  Mejora tu plan y obtén mejores resultados.
                </p>
              </span>
            </div> -->
          </div>
        </div>
        <br />
        <div class="row my-5 justify-content-center">
          <div class="col-lg-6 row align-items-center justify-content-between">
            <router-link class="btn-text" to="/dashboard"
              >&lt;- Volver</router-link
            >
            <div class="d-flex flex-column justify-content-center">
              <div
                class="d-flex justify-content-center"
              >
                <p
                  class="pagination__firstPage my-auto mx-4"
                  :class="actual_page != 1 && 'allowed'"
                  :style="`cursor: ${actual_page == 1 ? 'not-allowed' : 'click'}`"
                  @click="actual_page != 1 && changeTemplatesToShow(1)"
                >
                  1
                </p>
                <div class="number-input my-auto">
                  <button
                    class="btn-left left-arrow py-1 px-3"
                    type="button"
                    id="button-addon1"
                    @click="
                      actual_page != 1 && changeTemplatesToShow(Number(actual_page) - 1)
                    "
                    :disabled="actual_page == 1"
                  ></button>
                  <div>
                    <input
                      class="input_page m-0"
                      :class="{ input_error: errorInputPage }"
                      type="number"
                      :max="pages"
                      v-model="input_page"
                      @keyup="inputPageSubmit"
                      error="true"
                    />
                  </div>
                  <button
                    class="btn-right right-arrow py-1 px-3"
                    type="button"
                    id="button-addon2"
                    :disabled="actual_page == pages"
                    @click="
                      actual_page != pages && changeTemplatesToShow(Number(actual_page) + 1)
                    "
                  ></button>
                </div>
                <p
                  class="pagination__lastPage my-auto mx-4"
                  :class="actual_page != pages && 'allowed'"
                  :style="
                    `cursor: ${actual_page == pages ? 'not-allowed' : 'click'}`
                  "
                  @click="actual_page != pages && changeTemplatesToShow(pages)"
                >
                  {{ pages }}
                </p>
              </div>
              <span
                v-if="errorInputPage"
                class="text_sm text_error text-center mx-auto mt-2"
              >
                El valor ingresado debe ser entre 1 y {{ pages }}
              </span>
            </div>
            <select
              name="items"
              class="select_text my-1 mr-3"
              @change="changeTemplatesToShow(1)"
              v-model="itemsPerPage"
            >
              <option value="15">15 plantillas por página</option>
              <option value="30">30 plantillas por página</option>
              <option value="50">50 plantillas por página</option>
            </select>
          </div>
          <div class="col-lg-4" />
        </div>
      </div>
    </FadeTransition>
    <NoAccessView
      :show="!view_permissions.read && loading == false"
      @loading="loading = false"
    />
    <Modal :show="show" ancho="970" @close="show = false">
      <AddTemplate
        @submittedTemplate="initialize(selected_bot.id)"
        :TemplateMessages="TemplateMessages"
        :templateLabels="templateLabels"
        slot="content"
      />
    </Modal>
    <Modal :show="showPreview" ancho="400" @close="showPreview = false">
      <h3 slot="header">Previsualización</h3>
      <div slot="content" class="m-4">
        <div class="preview" >
          <template>            
            <div class="preview_box">
              <div class="message_box d-flex flex-column align-items-start mb-1 pb-4">
                <!-- header -->
                <embed
                v-if="preview?.header?.format == 'IMAGE'"
                  :src="preview?.header?.img"
                  alt="img-whatsapp"
                />
                <p v-else class="align-left negrita mb-2">{{ preview?.header?.text }}</p>
                <!-- body -->
                <p class="align-left mb-2">{{ preview?.body?.text }}</p>
                <!-- footer -->
                <p class="align-left text-sm mb-2">{{ preview?.footer?.text }}</p>
                <p class="text_xs mb-0">2:21 p. m.</p>
                <div class="row">
                  <!-- buttons -->
                  <template v-if="preview?.buttons?.length <= 3">
                    <span
                      v-for="(button, buttonPrevIndex) in preview?.buttons"
                      :key="buttonPrevIndex"
                      :class="
                        buttonPrevIndex < 2 &&
                        preview?.buttons?.length !== 1 &&
                        preview?.buttons?.find(
                          el => el.type == 'QUICK_REPLY',
                        )
                          ? 'preview_box-button'
                          : ''
                      "
                      class="message_box btn-preview text-center mb-1"
                      >
                        <span v-if="button.type == 'PHONE_NUMBER'" class="i-base i-wsp-phone mr-2" />
                        <span v-if="button.type == 'URL'" class="i-base i-wsp-url mr-2" />
                        {{ button.text }}
                    </span>
                  </template>
                  <template v-else>
                    <span
                      v-for="(button, buttonPrevIndex) in preview?.buttons"
                      :key="buttonPrevIndex"
                      class="message_box btn-preview text-center mb-1"
                    >
                      <span v-if="button.type == 'PHONE_NUMBER'" class="i-base i-wsp-phone mr-2" />
                      <span v-if="button.type == 'URL'" class="i-base i-wsp-url mr-2" />
                      {{ button.text }}
                    </span>
                  </template>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </Modal>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import dashboard_api from '@/dashboard_api';
import AddTemplate from '../components/AddTemplate';
import { FadeTransition } from 'vue2-transitions';
import { sync360DialogTemplateMessages } from '@/utils/utils';
import ModalEtiquetas from '../components/ModalEtiquetas.vue';
import '../assets/buefy_class.scss';

export default {
  components: {
    FadeTransition,
    AddTemplate,
    ModalEtiquetas,
  },
  data() {
    return {
      getCurrentPhone: '',
      show: false,
      loading: false,
      templates: [],
      TemplateMessages: null,
      itemsPerPage: 15, // numeros de plantillas por pagina
      templatesByPage: [], // Lista de templates
      actual_page: 1,
      input_page: 1,
      pages: 0,
      errorInputPage: false,
      templateLabels: [],
      activeItem: null,
      showArchived: false,
      selectLabel: "",
      search: "",
      preview: {
        header: {
          format: '',
          img: '',
          text: ''
        },
        body: {
          text:''
        },
        footer: {
          text: ''
        },
        buttons: []
      },
      showPreview: false
    };
  },

  computed: {
    ...mapState([
      'user',
      'ownerBots',
      'selected_bot',
      'plan_name',
      'active_view',
      'view_permissions'
    ]),
  },

  watch: {
    selected_bot: {
      immediate: true,
      handler(val) {
        this.initialize(val.id);
      },
    },
    search: {
      immediate: true,
      handler(val) {
        let itemSearch = this.filterByLabel()
        itemSearch = itemSearch.filter(e => e.archived === this.showArchived)
        let filteredTemplates
        if (!val) filteredTemplates = itemSearch;

        filteredTemplates = itemSearch.filter(item => {
          let text = item.components?.find(el => el.type === "BODY")?.text || '';

          return [item.name, text].find(field => {
            return field
              .toLowerCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .includes(
                val
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
                  .toLowerCase()
                  .trim()
              );
          });
        });
        this.templatesByPage = filteredTemplates.slice(0, this.itemsPerPage)
        this.pages = Math.ceil(filteredTemplates.length / this.itemsPerPage)
      },
    },
    selectLabel: {
      immediate: true,
      handler(val){
        const tempItem = this.filterByLabel()
        const filteredTemplatesArchived = tempItem.filter(e => e.archived === this.showArchived)
        this.templatesByPage = filteredTemplatesArchived.slice(0, this.itemsPerPage)
        this.pages = Math.ceil(filteredTemplatesArchived.length / this.itemsPerPage)
      }
    }
  },
  methods: {
    selectTemplate(id) {
      this.showPreview = true
      this.resetPreview()
      const header = this.templates.find(e => e.id == id)?.components?.find(e => e.type === 'HEADER')
      const body = this.templates.find(e => e.id == id)?.components?.find(e => e.type === 'BODY')
      const footer = this.templates.find(e => e.id == id)?.components?.find(e => e.type === 'FOOTER')
      const buttons = this.templates.find(e => e.id == id)?.components?.find(e => e.type === 'BUTTONS')
      if(header){
        this.preview.header.format = header?.format
        if(header.format === 'IMAGE'){
          this.preview.header.img = header?.example?.header_handle?.[0]
        } else {
          if(header.example?.header_text?.length > 0) {
            let tempText = header?.text
            header.example.header_text.map((e, index) => {
              tempText = tempText.replace(`{{${index + 1}}}`, e)
            })
            this.preview.header.text = tempText
          } else {
            this.preview.header.text = header?.text
          }
        }
      }
      if(body){
        if(body.example?.body_text?.length > 0) {
          let tempText = body?.text
          body.example?.body_text[0].map((e, index) => {
            tempText = tempText.replace(`{{${index + 1}}}`, e)
          })
          this.preview.body.text = tempText
        } else {
          this.preview.body.text = body.text
        }
      }
      if(footer){
        this.preview.footer.text = footer.text
      }
      if(buttons){
        this.preview.buttons = buttons.buttons
      }
    },
    resetPreview(){
      this.preview = {
        header: {
          format: '',
          img: '',
          text: ''
        },
        body: {
          text:''
        },
        footer: {
          text: ''
        },
        buttons: []
      }
    },
    filterByLabel() {      
      let tempItem = this.templates.filter(item => {
        let text = item.components?.find(el => el.type === "BODY")?.text || '';
        return [item.name, text].find(field => {
          return field
            .toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .includes(
              this.search
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .toLowerCase()
                .trim()
            );
        });
      })
      if(this.selectLabel) {
        tempItem = tempItem.filter(template => template.payroll_labels.some(label => label.id == this.selectLabel))      
      }
      return tempItem     
    },
    async archiveMessage(templateId, status) {
      this.$swal({
        title: `¿ ${status ? 'Desarchivar' : 'Archivar'}  mensaje de plantilla?`,
        text: `Esta acción ${status ? 'mostrará' : 'ocultará'} su mensaje de plantilla`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: status ? 'Desarchivar' : 'Archivar',
        cancelButtonText: 'Cancelar',
      }).then(async result => {
        if (result.isConfirmed) {
          await dashboard_api.put(`/payroll_messages/${templateId}`,{
            payroll_message: {
              archived: !status
            }
          })
          await this.initialize(this.selected_bot.id)
        } else {
          console.log('No se borro');
        }
      });
    },
    async MessageCounter() {
      try {
        this.TemplateMessages = (
          await dashboard_api.get(
            `/payroll_message_counters/by_bot/360Dialog/${this.selected_bot.id}/template_message`,
          )
        ).data.outcome_messages;
      } catch (error) {
        this.TemplateMessages = 0;
      }
    },
    switchArchived(){
      this.showArchived = !this.showArchived
      const tempItem = this.filterByLabel()
      const archivedTemplates = tempItem.filter(e => e.archived === this.showArchived) 
      this.templatesByPage = archivedTemplates.slice(0, this.itemsPerPage)
      this.pages = Math.ceil(archivedTemplates.length / this.itemsPerPage)
      
    },
    changeTemplatesToShow(page){
      this.actual_page = page;
      this.input_page = page;
      const tempItem = this.filterByLabel()
      const templatesFilteredArchived = tempItem.filter(e => e.archived === this.showArchived)
      this.templatesByPage = templatesFilteredArchived.slice((page - 1) * this.itemsPerPage, (this.itemsPerPage * page))
      this.pages = Math.ceil(templatesFilteredArchived.length / this.itemsPerPage)
    },
    inputPageSubmit(e) {
      this.errorInputPage = false;
      if (e.key === 'Enter' || e.keyCode === 13) {
        if (this.input_page >= 1 && this.input_page <= this.pages) {
          this.changeTemplatesToShow(Number(this.input_page));
        } else {
          this.errorInputPage = true
        };
      }
    },
    async initialize(bot_id) {
      this.loading = true;
      this.show = false;

      try {
        let resp = (
          await dashboard_api.get(`/bot_credential/get_by_bot_id/${bot_id}`)
        ).data;
        // console.log('2-resp->', resp);

        const labelResp = await dashboard_api.get(`/label_payroll_bots/label_payroll_by_bot/${this.selected_bot.id}`);
        if (!labelResp.data.message) {
          this.templateLabels = labelResp.data;
        }

        if (resp.length > 0) {
          this.getCurrentPhone = resp.find(
            (el) => el.channel.toLowerCase() === 'whatsapp',
          )?.phone;
          this.templates = (
            await dashboard_api.get(`/payroll_messages/by_bot/${bot_id}`)
          ).data;
          if (this.templates.length > 0) {
            this.templates = await sync360DialogTemplateMessages(
              this.templates,
              bot_id,
            );
            const tempItem = this.filterByLabel();
            const filteredTemplatesArchived = tempItem.filter(e => e.archived === this.showArchived);
            this.templatesByPage = filteredTemplatesArchived.slice(0, this.itemsPerPage);
            this.pages = Math.ceil(filteredTemplatesArchived.length / this.itemsPerPage);
            this.MessageCounter();
          } else {
            this.templates = [];
          }
        }
      } catch (error) {
        console.log("🐞 LOG HERE error:", error);
      }
      this.loading = false;
    },
    showModalTag(id){
      this.activeItem = id
    },
    closeModalTag(){
      this.activeItem = null
    },
    updateTag(data){
      const { tag, color, tagSelect } = data
      dashboard_api
        .patch(
          `/payroll_labels/${ tagSelect.id }`,
          {
            payroll_label: {
              label_name: tag,
              color: color,
              bot_id: this.selected_bot.id
            }
          },
        )
        .then(res => {
          this.$swal({
            toast: true,
            position: 'top-end',
            icon: 'success',
            text: 'Tag editado',
            timer: 1000,
            showConfirmButton: false,
          });
          const indexTemplateLabels = this.templateLabels.findIndex(e => e.id === res.data.id) 
          if(indexTemplateLabels >= 0){
            this.templateLabels[indexTemplateLabels].label_name = res.data.label_name
            this.templateLabels[indexTemplateLabels].color = res.data.color
          }
          this.templatesByPage.map(e => {
            const indexPayrollLabel = e.payroll_labels.findIndex(x => x.id === res.data.id)
            if(indexPayrollLabel >= 0){
              e.payroll_labels[indexPayrollLabel].label_name = res.data.label_name
              e.payroll_labels[indexPayrollLabel].color = res.data.color
            }
          })
        });
    },
    async addNewTag(data, templateId){
      const { tag, color } = data
      const newTag = await dashboard_api.post('/payroll_labels', {
        payroll_label: {
          label_name: tag,
          color: color,
          bot_id: this.selected_bot.id
        }
      })
      await dashboard_api.post('/label_payroll_bots/create_label_payroll_bot', {
        label_payroll_bot: {
          payroll_message_id: templateId,
          payroll_label_id: newTag.data.id,
          bot_id: this.selected_bot.id
        }
      })
      this.templateLabels.push({ label_name: tag, color: color, bot_id: this.selected_bot.id, id: newTag.data.id })
      this.templatesByPage.find(e => e.id === templateId).payroll_labels.push({ label_name: tag, color: color, bot_id: this.selected_bot.id, id: newTag.data.id })
    },
    async addExistingTag(data, templateId){
      const { tag, color, label_element_id } = data
      const newTag = await dashboard_api.post('/label_payroll_bots/create_label_payroll_bot', {
        label_payroll_bot: {
          payroll_message_id: templateId,
          payroll_label_id: label_element_id,
          bot_id: this.selected_bot.id
        }
      })
      this.templatesByPage.find(e => e.id === templateId).payroll_labels.push({ label_name: tag, color: color, bot_id: this.selected_bot.id, id: label_element_id })
    },
    async removeTagFromTemplate(data, templateId) {
      const { tag } = data
      const res = await dashboard_api.delete(`/label_payroll_bots/destroy_payroll_label_by_payroll/${tag.id}/${templateId}`)
      if(res.status === 204){        
        const index = this.templatesByPage.findIndex(e => e.id === templateId)
        const indexPayrollLabel = this.templatesByPage[index].payroll_labels.findIndex(x => x.id === tag.id)
        this.templatesByPage[index].payroll_labels.splice(indexPayrollLabel, 1)
      }
    },
    deleteGlobal(tag){
      dashboard_api
        .delete(
          `/payroll_labels/${tag.id}`,
        )
        .then(res => {
          this.$swal({
            icon: 'success',
            title: 'Tag eliminado',
            timer: 1000,
            showConfirmButton: false,
          });
          const templateLabelsIndex = this.templateLabels.findIndex(e => e.id === tag.id)
          if(templateLabelsIndex >= 0)
            this.templateLabels.splice(templateLabelsIndex, 1)
          this.templatesByPage.map(e => {
            const indexPayrollLabel = e.payroll_labels.findIndex(x => x.id === tag.id)
            if(indexPayrollLabel >= 0)
              e.payroll_labels.splice(indexPayrollLabel,1)
          })
        })
        .catch(err => console.error(err))
    },
  },
};
</script>

<style lang="scss" scoped>
.business-whatsapp {
  width: 100%;
  padding-bottom: 5rem;

  .img_box {
    width: 80px;
    justify-content: flex-start;
    align-items: center;
    display: flex;
    margin: 0;
    flex-direction: column;

    .icon {
      width: 80px;
      height: 80px;
    }
  }
  .select_2 {
    border: none;
    background-color: transparent !important;
    color: #2981ef !important;
    padding: 0.5rem 2rem 0.5rem 0.5rem;
    margin: 0;
  }
  .div_input {
    font-size: 14px;
    line-height: 1.5rem;
    overflow-y: hidden;
    height: 40px;
    background-color: transparent !important;
    box-shadow: none !important;
    color: #767676;
    border-radius: 0;
    border-bottom: 1px solid #cdcdcd;
    padding: 0.75rem 0;
    transition: 0.5s;
    margin: 0;

    &:hover {
      color: #181818;
      border-bottom: 1px solid #333;
    }
    &:focus {
      height: auto !important;
      overflow-y: auto !important;
      animation: show-navbar-dropdown 0.25s ease forwards;
      color: #767676;
      border-bottom: 1px solid #333;
    }
  }
  .fa-trash-alt {
    cursor: pointer;
    font-size: 1rem;
    color: #b2b2b2;
    transition: 0.5s;
    margin: auto 0 auto 0.5rem;

    &:hover {
      color: #181818;
    }
  }
  // estados
  .rejected {
    color: #ff6565;
    border: 1px solid #ff6565;
  }
  .approved {
    color: #8ae04d;
    border: 1px solid #8ae04d;
  }
  .submitted {
    color: #b2b2b2;
    border: 1px solid #b2b2b2;
  }
  .text-dinamic {
    padding: 0.25rem;
    border-radius: 5px;
    background-color: #f2f2f2;
  }

  .no-scroll_box {
    padding: 1.25rem;

    @media (max-width: 480px) {
      padding: 0.5rem;
    }
  }
  .scroll_box {
    max-height: 450px;
    padding: 1.25rem;
    overflow-y: scroll;

    @media (max-width: 480px) {
      padding: 0.5rem;
    }
  }
  .buefy .switch input[type='checkbox'] + .check {
    width: 1.75rem;
    height: 1rem;

    &:before {
      width: 0.7rem;
      height: 0.7rem;
    }
  }
  .tooltip-custom {
    .tooltip_text {
      bottom: 0%;
      margin: 0;
    }
  }
  .i-btn {
    position: relative;

    &:hover {
      .tooltip_text-sm {
        visibility: visible;
        transition: 0.25s;
        opacity: 1;
        z-index: 250 !important;
        top: 0;
        left: 0;
        width: 200px;
      }
    }
  }
}
.preview {
    top: 0;
    width: 100%;
    min-height: 500px;
    border-radius: 1rem;
    border: 1px solid #cdcdcd;
    position: sticky;
    background-color: #f1eee8;
    .no_info {
      width: 100%;
      height: 500px;
      background-color: #fafafa;
      border-radius: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .preview_box {
      width: 100%;
      border-radius: 1rem;
      padding: 0.75rem;
      word-break: break-word;
      // justify-content: space-between;

      .message_box {
        position: relative;
        width: 100%;
        background-color: #fff;
        padding: 0.75rem;
        border-radius: 0.5rem;
        border: 1px solid #cdcdcd;
        margin: 0 auto;
        img,
        embed {
          width: 225px;
          height: 150px;
          object-fit: cover;
          object-position: center;
          margin-bottom: 0.5rem;
          border: 1px solid #cdcdcd;
          border-radius: 0.25rem;
        }

        .text_xs {
          color: #b2b2b2;
          position: absolute;
          right: 0.5rem;
          bottom: 0.5rem;
        }
      }
      .btn-preview {
        color: #2981ef;
      }
      .preview_box-button {
        margin-bottom: 1rem;
        width: 49.25%;

        &:nth-child(1) {
          // border-radius: 0.5rem 0 0 0.5rem;
          margin-right: 0.1rem;
        }
        &:nth-child(2) {
          margin-left: 0.1rem;
          // border-radius: 0 0.5rem 0.5rem 0;
        }
      }
    }
  }
  .text-sm {
    font-size: 13px !important;
    color: #b2b2b2;
  }
  .align-left {
    text-align: left;
  }
</style>
