<template>
  <section class="report">
    <FadeTransition :duration="200">
      <div class="container">
        <div class="my-4">
          <h1 class="mb-0 text-center">Páginas conectadas</h1>
        </div>
        <div class="formulario p-4" style="background-color: white">
          <div class="row">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th class="text-left">#</th>
                  <th class="text-left">Plataforma</th>
                  <th class="text-left">ID</th>
                  <th class="text-left">Nombre</th>
                  <th class="text-left">Categoría</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(page, pageIndex) in metaPages" :key="pageIndex">
                  <td>{{ pageIndex + 1 }}</td>
                  <td>
                    <img
                      src="https://i.pinimg.com/originals/ce/d6/6e/ced66ecfc53814d71f8774789b55cc76.png"
                      alt=""
                      width="30px"
                    />
                  </td>
                  <td>{{ page.id }}</td>
                  <td>{{ page.name }}</td>
                  <td>{{ page.category }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </FadeTransition>
  </section>
</template>

<script>
import { FadeTransition } from 'vue2-transitions';
import graphApiService from '@/services/api/graphApi';

export default {
  components: {
    FadeTransition,
  },
  data() {
    return {
      metaPages: [],
    };
  },
  mounted() {
    this.initialize();
  },
  methods: {
    initialize() {
      // fetch all meta connected pages
      const TOKEN = localStorage.getItem('facebookAccessToken');
      graphApiService.getBusinessPages(TOKEN).then((response) => {
        this.metaPages = response.data.payload;
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
