<template>
  <FadeTransition :duration="200">
    <section v-show="selected == 'enviame'">
      <hr class="my-5" />
      <h3>Integrar Envíame</h3>
      <div class="row mt-5">
        <div class="col-lg-12 pl-0 pr-0">
          <p class="text_error" v-if="error == 'error_api_key'">Completa el Api Key</p>
          <label v-else>Api Key</label>
          <input :class="{ border_error: error == 'error_api_key' }" placeholder="Ingresa tu Api Key" type="text"
            v-model="apiKey" />
        </div>
      </div>

      <div class="row justify-content-between">
        <button @click="saveIntegration()" class="btn btn-base md mx-0" :disabled="loading || view_permissions.edit != true">{{ !edit ? 'Guardar' :
          'Guardar cambios' }}
          <span class="tooltip_text-sm for_disabled" v-if="view_permissions.edit != true">Lo sentimos, no tienes permisos para guardar cambios.</span>
        </button>
        <button class="btn btn-base outline md mx-0" @click="$emit('closeView')">Cancelar
        </button>
      </div>
      <hr class="mt-5 mb-3" />
    </section>
  </FadeTransition>
</template>
  
<script>
import { FadeTransition } from "vue2-transitions";
import dashboard_api from "@/dashboard_api.js";

export default {
  components: {
    FadeTransition
  },
  props: [
    "selected",
    "bot_id",
    "fields",
    "integrations",
    "view_permissions"
  ],
  data() {
    return {
      edit: false,
      loading: false,
      credentials: {},
      error: '',
      apiKey: ''
    };
  },

  watch: {
    selected(val) {
      if (this.integrations && this.integrations.length > 0) {
        for (let integration of this.integrations) {
          if (this.selected == integration.provider) {
            this.edit = true;
            this.integrationId = integration.id;
            this.credentials = integration.credentials;
            this.getCredentials();
          } else {
            this.edit = false;
          }
        }
      }
    },

    fields(val) {
      this.edit = false,
      this.loading = false;
      this.apiKey = '';
    }
  },

  methods: {
    getCredentials() {
      let currentCredentials = JSON.parse(this.credentials);
      this.apiKey = currentCredentials.apiKey;
    },
    saveIntegration() {
      let status = true;
      this.loading = true;

      if (this.apiKey.length == 0) {
        this.error = 'error_api_key';
        this.loading = false;
      } else if (!this.edit) {
        dashboard_api
          .post("/user_credentials", {
            provider: this.selected,
            credentials: JSON.stringify({ apiKey: this.apiKey }),
            status,
            bot_id: this.bot_id,
          },
          ).then(resp => {
            this.$swal({
              icon: "success",
              title: "¡Vtex integrado!",
              timer: 1000,
              showConfirmButton: false,
            });
            this.loading = false;
            this.error = '';
            this.credentials = {};
            this.$emit('closeView');
            this.$emit('getIntegrations');
          })
          .catch(error => console.log('error->', error));
      } else {
        dashboard_api
          .put(`user_credentials/${this.integrationId}`, {
            credentials: JSON.stringify({ apiKey: this.apiKey }),
          }).then((resp => {
            this.$swal({
              icon: "success",
              title: "Integración modificada",
              timer: 1000,
              showConfirmButton: false,
            });
            this.loading = false;
            this.error = '';
            this.$emit('closeView');
            this.$emit('getIntegrations');
          }));
      }
    }
  }
};
</script>