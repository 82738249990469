<template>
  <section class="add-plantilla">
    <h2>Nuevo mensaje de plantilla</h2>
    <p class="mb-5">
      Asegúrate de seguir las
      <a
        href="https://developers.facebook.com/docs/whatsapp/message-templates/creation"
        target="_blank"
        >pautas contenido de WhatsApp</a
      >. La aprobación puede demorar hasta 5 días hábiles.
    </p>
    <div class="row">
      <div class="col-lg-7">
        <div v-show="!step">
          <div>
            <div class="row aligin-items-center justify-content-between">
              <div class="col-lg-7 px-0">
                <label>Nombre</label>
                <input
                  v-model="templateToReview.name"
                  placeholder="Nombre_plantilla"
                  type="text"
                />
              </div>
              <div class="col-lg-4 px-0">
                <label>Tipo de mensaje</label>
                <select v-model="value" @change="typeMessage(value)">
                  <option value="0">Solo texto</option>
                  <option value="1">Todos los campos</option>
                </select>
              </div>
            </div>
            <div class="row aligin-items-center justify-content-between">
              <div class="col-lg-7 px-0">
                <label>Categoría</label>
                <select v-model="templateToReview.category">
                  <option
                    :value="category.name"
                    v-for="(category, categoryIndex) in categoriesSorted"
                    :key="categoryIndex"
                  >
                    {{ category.custom }}
                  </option>
                </select>
              </div>
              <div class="col-lg-4 px-0" v-click-outside="closeModal">
                <label>Etiquetas</label>
                <div class="d-flex align-items-center flex-wrap">
                  <span
                    v-for="(tags, tagIndex2) in selectedTag"
                    class="tag-text text-state text-state-fill"
                    :style="[
                      { backgroundColor: tags.color || '#767676' },
                    ]"
                    :key="tagIndex2"
                    >{{ tags.label_name }}</span
                  >
                  <span
                    class="i-btn px-2 mb-2"
                    @click="showModalTag()"
                    >+</span
                  >
                </div>
                <ModalEtiquetas
                  v-show="showModal"
                  :listGeneralTags="templateLabels"
                  :listGlobalProps="templateLabels"
                  :listTagsProps="selectedTag"
                  :id="null"
                  :botId="selected_bot.id"
                  @update="updateTag($event)"
                  @addNewTag="addNewTag($event)"
                  @addExistingTag="addExistingTag($event)"
                  @removeTagFromElement="removeTagFromTemplate($event)"
                  @deleteGlobal="deleteGlobal($event)"
                />
                </div>
              <!-- <div class="col-lg-4 px-0">
                 <label>Lenguaje</label>
                <select v-model="templateToReview.language">
                  <option value="es">Español</option>
                </select>
              </div> -->
            </div>
            <template v-if="value == 1">
              <div class="d-flex justify-content-between align-items-center my-3">
                <div 
                  v-for="component in templateComponents" 
                  class="px-3 py-2" 
                  :class="component.show ? 'components_buttons-active' : 'components_buttons'"
                  @click="showComponent(component)"
                >
                  <i v-if="component.show" class="fas fa-check"></i>
                   {{ component.name }}
                </div>
              </div>
            </template>
            <!-- header -->
            <div v-show="value == 1 && templateComponents.find(e => e.name == 'Header').show" class="mt-3">
              <div class="label-box buefy mb-3">
                <label class="mb-0">Cabecera (Header)</label>
                <div class="buefy d-flex align-items-center">
                  <span v-if="!typeFile" class="info mb-0 mr-3">{{
                    templateToReview.components.find(el => el.type === 'HEADER')
                      .text.length + ' / 60'
                  }}
                </span>
                  <div class="text-state m-0">
                    <span class="text_sm">Agregar archivo</span>
                    <b-switch
                      @input="changeHeaderComponent"
                      v-model="typeFile"
                    />
                  </div>
                </div>
              </div>
              <div
                v-if="!typeFile"              
              >
                <textarea
                  cols="30"
                  rows="1"
                  maxlength="60"
                  placeholder="Ingresa el texto"
                  v-model="
                    templateToReview.components.find(el => el.type === 'HEADER')
                    .text"
                  ref="input_header"
                  class="input-no-radius-bottom"
                />
                <div class="input-container-buttons p-2 d-flex justify-content-start align-items-center">
                  <div class="py-1 px-2 input-buttons" @click="addVariable('input_header')">
                    Agregar variable
                  </div>
                </div>
              </div>
              <template v-else>
                <input
                  class="file-input bg_grey mb-2"
                  type="file"
                  @change="processFile($event)"
                  ref="file"
                />
                <FadeTransition :duration="200">
                  <div class="d-flex mt-2 mb-4">
                    <div>
                      <i class="fas fa-info-circle" />
                    </div>
                    <p class="text_sm">
                      Recomendamos el uso de imágenes en formato horizontal para
                      una mejor visualización desde WhatsApp.
                    </p>
                  </div>
                </FadeTransition>
              </template>
            </div>
            <!-- body -->
            <div class="mt-3">
              <div class="label-box mb-1">
                <div>
                  <label class="mb-0">Cuerpo (Body)</label>
                  <span class="tooltip-custom ml-2 fas fa-question-circle"
                    ><p class="tooltip_text">
                      Ingresa las variables que necesites en el formato:
                      {{ formatNumberWithBraces('1') }}, para luego asignarlas en
                      el siguiente paso.
                    </p>
                  </span>
                </div>
                <span class="info">{{
                  bodyComponent.text.length + ' / 1024'
                }}</span>
              </div>
              <textarea
                cols="30"
                rows="3"
                maxlength="1024"
                v-model="
                  templateToReview.components.find(el => el.type === 'BODY')
                    .text
                "
                :placeholder="
                  `Para agregar variables usa el formato ${formatNumberWithBraces(
                    '1',
                  )}`
                "
                class="input-no-radius-bottom"
                ref="input_body"
              />
              <div class="input-container-buttons p-2 d-flex justify-content-start align-items-center">
                <div class="i-box mr-2" :style="showHeaderEmojis ? 'z-index: 0!important;' : ''">
                  <span
                    @click="showBodyEmojis = !showBodyEmojis"                    
                    :class="{
                      'i-btn i-btn-white': true,
                      'i-close btn-select-active': showBodyEmojis,
                      'i-emoji': !showBodyEmojis,
                    }"
                  >
                  </span>
                  <VEmojiPicker v-show="showBodyEmojis" @select="e => selectEmoji(e, 'input_body')" />                                    
                </div>
                <div class="py-1 px-2 input-buttons" @click="addBold('input_body')">
                  <i class="fas fa-bold m-0"></i>
                </div>
                <div class="py-1 px-2 input-buttons" @click="addItalic('input_body')">
                  <i class="fas fa-italic m-0"></i>
                </div>
                <div class="py-1 px-2 input-buttons" @click="addStrikeThrough('input_body')">
                  <i class="fas fa-strikethrough m-0"></i>
                </div>
                <div class="py-1 px-2 input-buttons" @click="addVariable('input_body')">
                  Agregar variable
                </div>
              </div>
            </div>
            <!-- footer -->
            <div v-show="value == 1 && templateComponents.find(e => e.name == 'Footer').show" class="mt-3">
              <div class="label-box mb-1">
                <label>Final (Footer)</label>
                <span class="info">{{
                  templateToReview.components.find(el => el.type === 'FOOTER')
                    .text.length + ' / 60'
                }}</span>
              </div>
              <textarea
                cols="30"
                rows="1"
                maxlength="60"
                placeholder="Ingresa el texto"
                v-model="
                  templateToReview.components.find(el => el.type === 'FOOTER')
                    .text
                "
              />
            </div>
            <!-- buttons -->
            <div v-show="value == 1 && templateComponents.find(e => e.name == 'Botones').show" class="mt-3">
              <div class="label-box mb-3">
                <div class="d-flex align-items-center">
                  <label class="mb-0">Botones</label>
                  <span
                    v-if="buttonsComponent.buttons.length < 10"
                    @click="addButton"
                    class="btn-dash btn-dash-xs mb-0 ml-2"
                    >+</span
                  >
                  <!-- <span
                    v-if="
                      buttonsComponent.buttons.find(
                        el => el.type == 'QUICK_REPLY',
                      )
                        ? buttonsComponent.buttons.length < 3
                        : buttonsComponent.buttons.length < 2
                    "
                    @click="addButton"
                    class="btn-dash btn-dash-xs mb-0 ml-2"
                    >+</span
                  > -->
                </div>
                <div>
                  <span v-if="buttonsComponent.buttons" class="text_sm">{{ buttonsComponent.buttons.length + " / 10" }}</span>
                  <!-- <select
                    v-model="SelectedButtonType"
                    @change="buttonsComponent.buttons = []"
                    class="select_text mb-0 mr-2"
                  >
                    <option disabled>Seleccionar</option>
                    <option value="QUICK_REPLY">Quick Reply</option>
                    <option value="ACTION">Call to Action</option>
                  </select> -->
                </div>
              </div>
              <div class="row justify-content-start">
                <div
                  v-for="(button, buttonIndex) in buttonsComponent.buttons"
                  :key="buttonIndex"
                  :class="{
                    'btn_box': true,
                    input_error: button.error
                  }"
                >
                  <div class="btn_box-header">
                    <label>Botón {{ buttonIndex + 1 }}</label>
                    <div class="d-flex">
                      <select
                        @change="urlButtonsValidation(button)"
                        v-model="button.type"
                      >
                        <option value="URL">Tipo URL</option>
                        <option value="QUICK_REPLY">Tipo texto</option>
                        <option value="PHONE_NUMBER">Tipo teléfono</option>
                      </select>
                      <i
                        class="fas fa-trash-alt mr-0 click"
                        @click="deleteButton(buttonIndex)"
                      />
                    </div>
                  </div>
                  <div class="btn_box-body">
                    <template v-if="button.type != 'QUICK_REPLY'">
                      <div class="row justify-content-between">
                        <div class="col-lg-6 pl-0 pr-2">
                          <input
                            type="text"
                            class="mb-0"
                            placeholder="Etiqueta máx. 25 carácteres"
                            v-model="button.text"
                            maxlength="25"
                          />
                        </div>
                        <div class="col-lg-6 pl-3 pr-0">
                          <input
                            type="text"
                            class="mb-0"
                            :placeholder="
                              button.type == 'URL'
                                ? 'https://'
                                : 'Agrega el número'
                            "
                            v-model="button.value"
                          />
                        </div>
                      </div>
                    </template>
                    <template v-else>
                      <input
                        class="mb-0"
                        type="text"
                        maxlength="25"
                        placeholder="Etiqueta máx. 25 carácteres"
                        v-model="button.text"
                      />
                    </template>
                  </div>
                </div>
                <!-- <div
                  class="btn_box"
                  v-for="(button, buttonIndex) in buttonsComponent.buttons"
                  :key="buttonIndex"
                >
                  <input
                    class="btn-base outline md btn_input"
                    placeholder="Nombre del botón"
                    v-model="button.text"
                  />
                  <i
                    class="fas fa-times"
                    @click="deleteButton(buttonIndex)"
                  />
                </div> -->
              </div>
            </div>
            <button @click="nextStep" class="btn btn-base text-center mt-5">
              Previsualizar
              <i class="fas fa-chevron-right mr-0" />
            </button>
          </div>
        </div>
        <div v-show="step">
          <div class="col-lg-12 mt-4 px-0">
            <div class="row pb-4">
              <div class="col-lg-7 px-0">
                <template v-if="!typeFile">
                  <div
                    v-for="(dynamic, dynamicIndex) in countDynamicVariables(
                      headerComponent.text,
                    )"
                    :key="dynamicIndex"
                  >
                    <div class="row align-items-center justify-content-between">
                      <label
                        >Header
                        {{ formatNumberWithBraces(dynamicIndex + 1) }}</label
                      >
                    </div>
                    <select
                      v-model="headerComponent.custom_parameters[dynamicIndex]"
                      @change="
                        replaceTextWithSystemVariables(
                          headerComponent.custom_parameters,
                          headerComponent.text,
                          'editedTextHeader',
                        )
                      "
                    >
                      <option disabled>Seleccionar</option>
                      <option
                        :value="systemVariable"
                        v-for="(systemVariable, idx) in systemVariables"
                        :key="systemVariable + idx"
                      >
                        {{ systemVariable }}
                      </option>
                    </select>
                  </div>
                </template>
                <div
                  v-for="(dynamicNumber, dynamicIndex) in getBodyIndexes"
                  :key="dynamicIndex"
                >
                  <div class="row align-items-center justify-content-between">
                    <label
                      >Body {{ formatNumberWithBraces(dynamicNumber) }}</label
                    >
                  </div>
                  <select
                    v-model="bodyComponent.custom_parameters[dynamicIndex]"
                    @change="
                      replaceTextWithSystemVariables(
                        bodyComponent.custom_parameters,
                        bodyComponent.text,
                        'editedTextBody',
                      )
                    "
                  >
                    <option disabled>Seleccionar</option>
                    <option
                      :value="systemVariable"
                      v-for="(systemVariable, idy) in systemVariables"
                      :key="systemVariable + idy"
                    >
                      {{ systemVariable }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="info_box">
                <p>
                  Recomendamos que las imagenes enviadas sean en formato
                  horizontal.
                </p>
              </div>
            </div>
            <div class="label-box mt-5">
              <div class="col-lg-5 mx-0 px-0">
                <p @click="Back" class="btn-text mx-0">
                  <i class="fas fa-arrow-left mr-2" />Seguir editando
                </p>
              </div>
              <button
                class="btn btn-base second mx-0"
                @click="submitTemplate(templateToReview)"
                :disabled="loading"
              >
                Solicitar plantilla
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-5">
        <div class="preview">
          <template
            v-if="
              (typeFile && model.image) ||
                (textHeader && textHeader.length > 0) ||
                bodyComponent.text.length > 0 ||
                footerComponent.text.length > 0
            "
          >
            <div class="preview_box">
              <div class="message_box mb-1">
                <!-- header -->
                <embed
                  v-if="typeFile && model.image"
                  :src="model.image"
                  :accept="acceptFile"
                  alt="img-whatsapp"
                />
                <p v-else class="negrita mb-2">{{ textHeader }}</p>
                <!-- body -->
                <p class="mb-2">{{ textBody }}</p>
                <!-- footer -->
                <p class="text-sm mb-2">{{ footerComponent.text }}</p>
                <p class="text_xs mb-0">2:21 p. m.</p>
              </div>
              <div>
                <div class="row">
                  <!-- buttons -->
                  <template v-if="buttonsComponent.buttons.length <= 3">
                    <span
                      v-for="(button,
                      buttonPrevIndex) in buttonsComponent.buttons"
                      :key="buttonPrevIndex"
                      :class="
                        buttonPrevIndex < 2 &&
                        buttonsComponent.buttons.length !== 1 &&
                        buttonsComponent.buttons.find(
                          el => el.type == 'QUICK_REPLY',
                        )
                          ? 'preview_box-button'
                          : ''
                      "
                      class="message_box btn-preview text-center mb-1"
                      >
                        <span v-if="button.type == 'PHONE_NUMBER'" class="i-base i-wsp-phone mr-2" />
                        <span v-if="button.type == 'URL'" class="i-base i-wsp-url mr-2" />
                        {{ button.text || 'Nuevo botón' }}
                    </span>
                  </template>
                  <template v-else>
                    <span
                      v-for="(button,
                      buttonPrevIndex) in buttonsComponent.buttons.slice(0, 2)"
                      :key="buttonPrevIndex"
                      class="message_box btn-preview text-center mb-1"
                    >
                      <span v-if="button.type == 'PHONE_NUMBER'" class="i-base i-wsp-phone mr-2" />
                      <span v-if="button.type == 'URL'" class="i-base i-wsp-url mr-2" />
                      {{ button.text || 'Nuevo botón' }}
                    </span>
                    <span class="message_box btn-preview text-center mb-1"><span class="i-base i-wsp-options mr-2" />Ver todas las opciones</span>
                  </template>
                </div>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="no_info">
              <p class="text-loading">Aquí podrás previsualizar tu mensaje</p>
            </div>
          </template>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from 'axios';
import { mapState } from 'vuex';
import environment from '@/environment.js';
import { isNumeric, random, formatTemplateMessageName } from '@/utils/utils.js';
import '../assets/buefy_class.scss';
import dashboard_api_v2 from '../dashboard_api_v2';
import dashboard_api from '../dashboard_api';
import databotAiService from '../services/api_gpt/databot_ai';
import ModalEtiquetas from './ModalEtiquetas.vue';
import { FadeTransition } from "vue2-transitions";
import { VEmojiPicker } from 'v-emoji-picker';

export default {
  props: ['TemplateMessages', 'templateLabels'],
  components: { ModalEtiquetas, FadeTransition, VEmojiPicker },

  data() {
    return {
      showHeaderEmojis: false,
      showBodyEmojis: false,
      file: null,
      acceptFile: [
        'image/jpg',
        'image/png',
        'image/jpeg',
        'application/pdf',
        '.xlsx',
        '.xls',
      ],
      typeFile: false,
      loading: false,
      step: false,
      show: false,
      value: '0',
      button: 0,
      // este es el formato para enviar a 360Dialog
      editedTextHeader: '',
      editedTextBody: '',
      editedTextFooter: '',
      templateToReview: {
        name: '',
        category: 'MARKETING',
        language: 'es',
        components: [
          {
            type: 'HEADER',
            format: 'TEXT',
            text: '',
            example: {
              header_text: [],
            },
            custom_parameters: [],
          },
          {
            type: 'BODY',
            text: '',
            example: {
              body_text: [[]],
            },
            custom_parameters: [],
          },
          {
            type: 'FOOTER',
            text: '',
          },
          {
            type: 'BUTTONS',
            buttons: [],
          },
        ],
      },
      systemVariables: [
        '$lead.nombres',
        '$lead.correo',
        '$lead.telefono',
        '$lead.carro_abandonado',
        '$lead.monto',
        '$lead.nro_orden',
        '$lead.estado_orden',
      ],
      systemVariablesExamples: {
        '$lead.nombres': [
          'Nicolas',
          'Rodrigo',
          'Sergio',
          'Victor',
          'Matias',
          'Dylan',
        ],
        '$lead.correo': [
          'nicolas@gmail.com',
          'rodrigo@gmail.com',
          'sergio@gmail.com',
          'victor@gmail.com',
          'matias@gmail.com',
          'dylan@gmail.com',
        ],
        '$lead.telefono': [
          '983724471',
          '982475514',
          '987452416',
          '974581455',
          '987652461',
        ],
        '$lead.carro_abandonado': [
          'https://www.mystore.com/abandoned-cart/example',
        ],
        '$lead.monto': [
          '2000',
          '3000',
          '4000',
        ],
        '$lead.nro_orden': [
          '93581-1',
          '23123-1',
          '#5624',
        ],
        '$lead.estado_orden': [
          'Enviado',
          'En preparación',
        ],
      },
      categories: [
        { name: 'MARKETING', custom: 'Marketing' },
        { name: 'OTP', custom: 'Contraseña de un solo uso' },
        { name: 'UTILITY', custom: 'Utilidad' },
      ],
      model: {
        image: '',
        // image: "https://databot-files.s3.amazonaws.com/databot.mp4"
        // image: "https://ia.databot.cl/img/archivos/Guia-de-implementacion.pdf"
        // image: "https://databot.cl/_nuxt/img/0.4-banner.a88a304.png"
      },
      showModal: false,
      selectedTag: [],
      templateComponents: [
        {
          name: "Header",
          show: true
        },
        {
          name: "Body",
          show: true
        },
        {
          name: "Footer",
          show: true
        },
        {
          name: "Botones",
          show: true
        },
      ]
    };
  },

  watch: {
    selected_bot: {
      immediate: true,
      handler(val) {
        this.getLeadCustomFields(val);
      },
    },
  },

  computed: {
    ...mapState(['selected_bot']),

    textHeader() {
      return this.step
        ? this.editedTextHeader
        : this.templateToReview.components.find(el => el.type === 'HEADER')
            .text;
    },

    textBody() {
      return this.step ? this.editedTextBody : this.bodyComponent.text;
    },

    categoriesSorted() {
      return this.categories.sort((a, b) => (a.custom < b.custom ? -1 : 1));
    },
    headerComponent() {
      return this.templateToReview.components.find(el => el.type === 'HEADER');
    },
    bodyComponent() {
      return this.templateToReview.components.find(el => el.type === 'BODY');
    },
    footerComponent() {
      return this.templateToReview.components.find(el => el.type === 'FOOTER');
    },
    buttonsComponent() {
      return this.templateToReview.components.find(el => el.type === 'BUTTONS');
    },
    bodySplittedText() {
      return this.bodyComponent.text.split(/{{|}}/g).filter(el => el !== '');
    },
    getBodyIndexes() {
      return this.bodyComponent.text
        .split(/{{|}}/g)
        .filter(el => isNumeric(el));
    },
  },
  methods: {
    selectEmoji(emoji, component) {
      const input = this.$refs[component]
      this.insertInputValueCursor(input, emoji.data, true)
      this.setComponentValue(component, input.value)
    },
    checkSelection(component){
      const input = this.$refs[component]
      const selectedValue = input.value.substring(input.selectionStart, input.selectionEnd);
      return {selectedValue, start: input.selectionStart, end: input.selectionEnd }
    },
    setComponentValue(component, value) {
      switch (component) {
        case 'input_body':
          this.templateToReview.components.find(el => el.type === 'BODY').text = value
          break;
        case 'input_header':
          this.templateToReview.components.find(el => el.type === 'HEADER').text = value
          break;        
        default:
          break;
      }
    },
    addBold(component) {
      const {selectedValue, start, end} = this.checkSelection(component)
      const input = this.$refs[component]
      if(selectedValue) {
        input.value = input.value.slice(0, start) + "*" + input.value.slice(start, start + (end-start)) + "*" + input.value.slice(start + (end - start))
      } else {
        this.insertInputValueCursor(input, "**")
      }
      this.setComponentValue(component, input.value)
    },
    addItalic(component) {
      const {selectedValue, start, end} = this.checkSelection(component)
      const input = this.$refs[component]
      if(selectedValue) {
        input.value = input.value.slice(0, start) + "_" + input.value.slice(start, start + (end-start)) + "_" + input.value.slice(start + (end - start))
      } else {
        this.insertInputValueCursor(input, "__")
      }
      this.setComponentValue(component, input.value)
    },
    addStrikeThrough(component) {
      const {selectedValue, start, end} = this.checkSelection(component)
      const input = this.$refs[component]
      if(selectedValue) {
        input.value = input.value.slice(0, start) + "~" + input.value.slice(start, start + (end-start)) + "~" + input.value.slice(start + (end - start))
      } else {
        this.insertInputValueCursor(input, "~~")
      }
      this.setComponentValue(component, input.value)
    },
    addVariable(component){
      const input = this.$refs[component]
      const regex = /{{\d+}}/g;
      const matches = input.value.match(regex)
      if(matches) {
        const valuesArray = matches.map(function(match) {
          return match.substring(2, match.length - 2); // Remove {{ and }}
        });
        const missingNumbers = this.findMissingNumbers(valuesArray)
        // EN CASO DE QUE HAYA POR EJEMPLO {{1}} Y {{3}} FALTANDO EL {{2}}, EL BOTON EN VEZ DE AGREGAR {{4}} AGREGARIA {{2}} 
        if(missingNumbers.length > 0){
          this.insertInputValueCursor(input, `{{${missingNumbers[0]}}}`, true)
        } else {
          this.insertInputValueCursor(input, `{{${matches.length + 1}}}`, true)
        }
      } else {
        this.insertInputValueCursor(input, "{{1}}", true)
      }
      this.setComponentValue(component, input.value)
    },
    findMissingNumbers(num) {
      const numbers = num.map(e=>Number(e))
      const min = 1
      const max = Math.max(...numbers)
      const allNumbers = Array.from({length: max - min + 1}, (_, index) => index + min);
      const missingNumbers = allNumbers.filter(e => !numbers.includes(e))
      return missingNumbers
    },
    insertInputValueCursor(input, str, end = false) {
      const cursorPosition = input.selectionStart
      const actualValue = input.value
      const newValue = actualValue.slice(0, cursorPosition) + str + actualValue.slice(cursorPosition)
      input.value = newValue
      input.setSelectionRange(cursorPosition + str.length - (end ? 0 : 1), cursorPosition + str.length - (end ? 0 : 1))
      input.focus()
    },
    urlButtonsValidation(value) {
      let url_buttons = this.buttonsComponent.buttons.filter(el => el.type == 'URL');

      value.error = false;

      if (url_buttons.length > 2) {
        this.$swal({
          icon: "warning",
          title: "Solo puedes crear 2 botones tipo URL.",
          showConfirmButton: true,
          confirmButtonText: 'Entendido',
        });
        value.type = "QUICK_REPLY";
        value.error = true;
      }
    },
    /**
     * @description Obtiene los campos personalizados y sus emjemplos del crm
     */
    changeHeaderComponent() {
      console.log('files', this.typeFile);
      if (this.typeFile) {
        console.log('entramos');

        this.templateToReview.components.splice(0, 1, {
          type: 'HEADER',
          format: 'IMAGE',
          example: {
            header_handle: [''],
          },
        });
      } else {
        console.log('entramos 2');
        this.templateToReview.components.splice(0, 1, {
          type: 'HEADER',
          format: 'TEXT',
          text: '',
          example: {
            header_text: [],
          },
          custom_parameters: [],
        });
      }
    },

    processFile(event) {
      const self = this;

      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = () => {
        self.model.image = reader.result;
      };
      reader.onerror = error => {
        console.log('Error: ', error);
      };
    },

    async getLeadCustomFields(bot) {
      let customFields;

      try {
        customFields = (
          await axios.get(
            environment.url_env + '/lead/lead_bigger_customs/' + bot.id,
          )
        ).data;
        for (const key in customFields) {
          if (Object.hasOwnProperty.call(customFields, key)) {
            // TODO default value in back, change this
            if (customFields[key] === 'Parque Arauco') {
              customFields[key] = null;
            }
            // for RyK only
            if (key === 'horarios') {
              customFields[key] = 'Lunes a viernes de 10:00 a 20:00hrs';
            }
          }
        }
        // get empty or null values
        let emptyValues = Object.keys(customFields).filter(
          key => customFields[key] === null || customFields[key] === '',
        );
        // pusheando a las variables del sistema
        for (const key in customFields) {
          if (Object.hasOwnProperty.call(customFields, key)) {
            // el campo no debe contener palabra reservada special_
            if (!key.includes('special_')) {
              let fieldName = `$lead.${key}`;
              this.systemVariables.push(fieldName);
              this.systemVariablesExamples[fieldName] =
                [customFields[key]] || '';
              // si nombre es number,numero, etc, cambiar valor ejemplo
              if (key.toLowerCase().includes('numero')) {
                this.systemVariablesExamples[fieldName] = ['120700600150'];
              }
            }
          }
        }

        // create completion with gpt
        databotAiService.completeExamples(emptyValues).then(response => {
          const iaExamples = JSON.parse(response.data.payload);
          // push iaExamples to systemVariablesExamples
          for (const key in iaExamples) {
            if (Object.hasOwnProperty.call(iaExamples, key)) {
              const element = iaExamples[key];
              this.systemVariablesExamples[`$lead.${key}`] = [element];
            }
          }
        });
      } catch (error) {
        console.log(error);
      }
    },
    nextStep() {
      // se valida si es que existe mas de un boton URL o PHONE en Call To Action buttons por docu de wsap
      let typeURL = [];
      let typePHONE = [];

      typeURL = this.buttonsComponent.buttons.filter(el => el.type == 'URL');
      typePHONE = this.buttonsComponent.buttons.filter(
        el => el.type == 'PHONE_NUMBER',
      );

      console.log('typeURL->', typeURL);
      console.log('typePHONE->', typePHONE);

      // se valida que al menos el body tenga texto
      // typeURL.length > 1 ||
      if (
        this.templateToReview.name.trim().length === 0 ||
        this.bodyComponent.text.trim().length === 0 ||
        typePHONE.length > 1
      ) {
        return this.$swal({
          title: 'Ooops!',
          text:
            this.templateToReview.name.trim().length === 0
              ? 'Es necesario completar el nombre de la plantilla'
              : typeURL.length > 1
              ? 'Solo puede tener un tipo de botón tipo URL'
              : typePHONE.length > 1
              ? 'Solo puede tener un tipo de botón tipo Teléfono'
              : 'Es necesario completar al menos el texto en BODY',
          icon: 'error',
          buttons: {
            confirm: {
              text: 'Ok',
              value: true,
              visible: true,
              className: '',
              closeModal: true,
            },
          },
        });
      }

      this.step = true;
      // contando cantidad dynamics
      this.editedTextHeader = this.headerComponent.text;
      this.editedTextBody = this.bodyComponent.text;
      this.editedTextFooter = this.footerComponent.text;
      // dando formato customVariables, text, variableName
      this.replaceTextWithSystemVariables(
        this.headerComponent.custom_parameters,
        this.headerComponent.text,
        'editedTextHeader',
      );
      this.replaceTextWithSystemVariables(
        this.bodyComponent.custom_parameters,
        this.bodyComponent.text,
        'editedTextBody',
      );
      // dando formato correcto al nombre del template
      this.templateToReview.name = formatTemplateMessageName(
        this.templateToReview.name,
      );
    },
    Back() {
      this.step = false;
      // reiniciando parametros
      this.headerComponent.custom_parameters = [];
      this.bodyComponent.custom_parameters = [];
    },
    typeMessage(type) {
      if (type == 0) {
        this.headerComponent.text = '';
        this.footerComponent.text = '';
        this.buttonsComponent.buttons = [];
      }
    },
    addButton() {
      if (this.buttonsComponent.buttons.length < 10) {
        this.buttonsComponent.buttons.push({
          text: '',
          type: 'QUICK_REPLY'
        });
      }
      // if (this.SelectedButtonType == 'QUICK_REPLY') {
      //   if (this.buttonsComponent.buttons.length < 10) {
      //     this.buttonsComponent.buttons.push({
      //       text: '',
      //       type: 'QUICK_REPLY',
      //     });
      //   }
      // } else if (this.buttonsComponent.buttons.length < 2) {
      //   this.buttonsComponent.buttons.push({
      //     text: '',
      //     type: 'URL',
      //     value: '',
      //   });
      // }
    },
    deleteButton(index) {
      if (this.buttonsComponent.buttons.length > 0) {
        this.buttonsComponent.buttons.splice(index, 1);

        this.$swal({
          toast: true,
          position: "top-right",
          icon: "success",
          title: "Eliminado",
          timer: 1000,
          showConfirmButton: false
        });
      }
    },
    countDynamicVariables(text) {
      return (text.match(/\{\{.*?}}/g) || []).length;
    },
    formatNumberWithBraces(number) {
      return `{{${number}}}`;
    },
    replaceTextWithSystemVariables(customVariables, text, variableName) {
      if (text) {
        let count = 0;
        let splitted = text.split(/{{|}}/g).filter(el => el !== '') || [];
        for (let i = 0; i < splitted.length; i++) {
          if (isNumeric(splitted[i])) {
            splitted[i] = customVariables[count]
              ? this.getSystemVariablesExamples(customVariables[count])
              : `{{${splitted[i]}}}`;
            count += 1;
          }
        }
        this[variableName] =
          customVariables.length > 0 ? splitted.join(' ') : text;
      }
    },
    getSystemVariablesExamples(systemVariable) {
      return this.systemVariablesExamples[systemVariable][
        random(0, this.systemVariablesExamples[systemVariable].length - 1)
      ];
    },
    showModalTag() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },
    updateTag(data) {
      const { tag, color, tagSelect } = data;
      dashboard_api
        .patch(
          `/payroll_labels/${ tagSelect.id }`,
          {
            payroll_label: {
              label_name: tag,
              color,
              bot_id: this.selected_bot.id
            }
          },
        )
        .then(res => {
          this.$swal({
            toast: true,
            position: 'top-end',
            icon: 'success',
            text: 'Tag editado',
            timer: 4000,
            showConfirmButton: false,
          });
          const selectedTagIndex = this.selectedTag.findIndex(e => e.id === res.data.id);
          if (selectedTagIndex >= 0) {
            this.selectedTag[selectedTagIndex].label_name = res.data.label_name;
            this.selectedTag[selectedTagIndex].color = res.data.color;
          }
          const templateLabelsIndex = this.templateLabels.findIndex(e => e.id === res.data.id);
          if (templateLabelsIndex >= 0) {
            this.templateLabels[templateLabelsIndex].label_name = res.data.label_name;
            this.templateLabels[templateLabelsIndex].color = res.data.color;
          }
        });
    },
    async addNewTag(data) {
      const { tag, color } = data;
      const newTag = await dashboard_api.post('/payroll_labels', {
        payroll_label: {
          label_name: tag,
          color,
          bot_id: this.selected_bot.id
        }
      });
      this.selectedTag.push({ label_name: tag, color, bot_id: this.selected_bot.id, id: newTag.data.id });
      this.templateLabels.push({ label_name: tag, color, bot_id: this.selected_bot.id, id: newTag.data.id });
    },
    addExistingTag(data) {
      const { tag, color, label_element_id } = data;
      console.log("addExistingTag", data);
      this.selectedTag.push({ label_name: tag, color, bot_id: this.selected_bot.id, id: label_element_id });
    },
    removeTagFromTemplate(data) {
      const { index } = data;
      this.selectedTag.splice(index, 1);
    },
    deleteGlobal(tag) {
      dashboard_api
        .delete(
          `/payroll_labels/${tag.id}`,
        )
        .then(res => {
          this.$swal({
            icon: 'success',
            title: 'Tag eliminado',
            timer: 1000,
            showConfirmButton: false,
          });
          const index = this.selectedTag.findIndex(e => e.id === tag.id);
          if (index >= 0) this.selectedTag.splice(index, 1);

          const templateLabelsIndex = this.templateLabels.findIndex(e => e.id === tag.id);
          if (templateLabelsIndex >= 0) this.templateLabels.splice(templateLabelsIndex, 1);
        })
        .catch(err => console.error(err));
    },
    async submitTemplate(template) {
      try {
        this.loading = true;

        this.$swal({
          icon: 'info',
          title: 'Solicitando..',
          showConfirmButton: false,
        });
        this.$swal.showLoading();

        // adjuntando ejemplos de dynamic variables
        if (this.typeFile) {
          // Guardamos el file
          const originalFile = this.$refs.file.files[0];
          // Obtenemos el nombre del file
          const newFileName = originalFile.name
          // Este replace elimina caracteres especiales que tenga el nombre de la imagen
          this.file = new File([originalFile], newFileName.replace(/[!"#$%&/()=?¡°]/g, '_'), { type: originalFile.type });

          let formData = new FormData();
          formData.append('file', this.file);

          this.file = (
            await dashboard_api_v2.post('process_files/upload', formData, {
              headers: { 'Content-Type': 'multipart/form-data' },
            })
          ).data;
        } else {
          this.headerComponent.example.header_text = this.headerComponent.custom_parameters.map(
            el => this.getSystemVariablesExamples(el),
          );
        }

        this.bodyComponent.example.body_text[0] = this.bodyComponent.custom_parameters.map(
          el => this.getSystemVariablesExamples(el),
        );

        let buttomsComponent = template.components.find(
          el => el.type == 'BUTTONS',
        );

        if (buttomsComponent && buttomsComponent.buttons.length > 0) {
          let buttons = buttomsComponent.buttons;

          buttons.forEach((el, index) => {
            if (el.type == 'URL') {
              buttons[index]['url'] = el.value;
              delete buttons[index].value;
            }
            if (el.type == 'PHONE_NUMBER') {
              buttons[index]['phone_number'] = el.value;
              delete buttons[index].value;
            }
            if (el.hasOwnProperty("error")) delete buttons[index].error;
          });
        }

        let extentions = ['png', 'jpg', 'jpeg', 'pdf', 'mp4', '3gp', 'mov'];
        if (this.typeFile) {
          let selectedExtention = extentions.find(
            el => this.file && this.file.url.includes(el),
          );

          let selectedFormat =
            selectedExtention == 'png' ||
            selectedExtention == 'jpg' ||
            selectedExtention == 'jpeg'
              ? 'IMAGE'
              : selectedExtention == 'pdf'
              ? 'DOCUMENT'
              : selectedExtention == 'mp4' ||
                selectedExtention == '3gp' ||
                selectedExtention == 'mov'
              ? 'VIDEO'
              : '';
          console.log('selectedFormat', selectedFormat);
          console.log('this.file', this.file);

          this.templateToReview.components.splice(0, 1, {
            type: 'HEADER',
            format: selectedFormat.toUpperCase(),
            example: {
              header_handle: [this.file.url],
            },
          });
        }

        template.name = formatTemplateMessageName(template.name);
        axios
          .post(
            environment.socket_url +
              '/whatsapp/360dialog/review-template-message',
            {
              ...template,
              botId: this.selected_bot.id,
            },
          )
          .then(async res => {
            console.log('res creacion plantlla->', res.data);
            await Promise.all(
              this.selectedTag.map(async (tag) => {
                try {
                  dashboard_api.post('/label_payroll_bots/create_label_payroll_bot', {
                    label_payroll_bot: {
                      payroll_message_id: res.data.id,
                      payroll_label_id: tag.id,
                      bot_id: this.selected_bot.id
                    }
                  });
                } catch (error) {
                  console.error(error);
                }
              }),
            );
            this.$swal({
              icon: 'success',
              title: 'Mensaje de plantilla creado',
              text:
                'Se ha enviado a revisión. Este proceso puede tardar hasta 5 días.',
              showConfirmButton: true,
            });
            this.loading = false;
            this.$emit('submittedTemplate');
          })
          .catch(err => {
            this.$swal({
              title: 'Ooops!',
              text:
                'Algo salió mal. Por favor, verifica que la plantilla cumpla con las normas en https://developers.facebook.com/docs/whatsapp/message-templates/creation',
              icon: 'error',
              buttons: {
                confirm: {
                  text: 'Ok',
                  value: true,
                  visible: true,
                  className: '',
                  closeModal: true,
                },
              },
            });
            this.loading = false;
          });
      } catch (error) {
        console.log(error);
      }
    },
    showComponent(component) {
      if(component.name !== 'Body'){
        component.show = !component.show
      }
      if(component.show === false){
        switch (component.name) {
          case 'Header':
            this.typeFile = false
            this.changeHeaderComponent()
            this.headerComponent.text = '';
            break;
          case 'Footer':
            this.footerComponent.text = '';
          case 'Botones':
            this.buttonsComponent.buttons = [];
          default:
            break;
        }
      }
    },
  },
};
</script>

<style lang="scss">
.add-plantilla {
  .buefy .switch input[type='checkbox'] + .check {
    width: 1.75rem;
    height: 1rem;

    &:before {
      width: 0.7rem;
      height: 0.7rem;
    }
  }
  .buefy .switch {
    justify-content: flex-end;
  }
}
</style>

<style lang="scss" scoped>
.add-plantilla {
  position: relative;
  text-align: left;
  padding: 0 2rem 1.5rem;
  max-height: 652px;
  overflow-x: hidden;
  overflow: scroll;

  &::-webkit-scrollbar {
    width: 7px;
  }
  &::-webkit-scrollbar-thumb {
    visibility: visible;
    border-radius: 2rem;
    background: #cdcdcd;
    transition: .25s;

    &:hover, &:active {
      background: #afafaf;
    }
  }
  select {
    width: 100%;
    padding-right: 1.25rem;
  }
  textarea {
    background-color: #fafafa;
    margin: 0;

    &:hover,
    &:focus {
      color: #181818;
    }
  }
  .select-xs {
    width: auto;
    font-size: 14px;
    background-color: transparent !important;
    box-shadow: none !important;
    color: #767676 !important;
    padding: 0.25rem 1.5rem 0.25rem 0.25rem;
    margin: 0 0 0.25rem;
  }
  .btn_box {
    width: 100%;
    border: 1px solid #cdcdcd;
    border-radius: 1rem;
    margin-bottom: 1.5rem;

    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: #f2f2f2;
      border-radius: 1rem 1rem 0 0;
      border-bottom: 1px solid #cdcdcd;

      label {
        padding-left: 1rem;
        margin-bottom: 0;
      }
      select {
        background-color: transparent;
        border-radius: 0;
        border: 0;
        border-left: 1px solid #cdcdcd;
        border-right: 1px solid #cdcdcd;
        margin-bottom: 0;
        padding-right: 2rem;

        &:hover {
          background-color: #fafafa;
        }
      }
      i {
        padding: 1rem;
        margin-right: 0;
        color: #b2b2b2;
      }
    }
    &-body {
      padding: 1rem;
      background-color: #fff;
      border-radius: 0 0 1rem 1rem;
    }
  }

  .text-sm {
    font-size: 13px !important;
    color: #b2b2b2;
  }

  .info {
    font-size: 12px;
  }
  .tooltip-custom {
    .tooltip_text {
      &::after {
        bottom: 20%;
      }
    }
  }
  .preview {
    top: 0;
    width: 100%;
    min-height: 500px;
    border-radius: 1rem;
    border: 1px solid #cdcdcd;
    position: sticky;
    background-color: #f1eee8;
    .no_info {
      width: 100%;
      height: 500px;
      background-color: #fafafa;
      border-radius: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .preview_box {
      width: 100%;
      border-radius: 1rem;
      padding: 0.75rem;
      word-break: break-word;
      justify-content: space-between;

      .message_box {
        position: relative;
        width: 100%;
        background-color: #fff;
        padding: 0.75rem;
        border-radius: 0.5rem;
        border: 1px solid #cdcdcd;
        margin: 0 auto;

        img,
        embed {
          width: 225px;
          height: 150px;
          object-fit: cover;
          object-position: center;
          margin-bottom: 0.5rem;
          border: 1px solid #cdcdcd;
          border-radius: 0.25rem;
        }

        .text_xs {
          color: #b2b2b2;
          position: absolute;
          right: 0.5rem;
          bottom: 0.5rem;
        }
      }
      .btn-preview {
        color: #2981ef;
      }
      .preview_box-button {
        margin-bottom: 1rem;
        width: 49.25%;

        &:nth-child(1) {
          // border-radius: 0.5rem 0 0 0.5rem;
          margin-right: 0.1rem;
        }
        &:nth-child(2) {
          margin-left: 0.1rem;
          // border-radius: 0 0.5rem 0.5rem 0;
        }
      }
    }
  }
  .click:hover {
    border-top-right-radius: 1rem;
  }
  .components_buttons, 
  .components_buttons-active {
    border: 1px solid black;
    border-radius: 5px;
    font-weight: 700;
    cursor: pointer;
    user-select: none;
  }
  .components_buttons {
    &-active {
      background-color: black;
      color: white
    }
  }
  .input-no-radius-bottom {
    border-bottom-left-radius: 0!important;
    border-bottom-right-radius: 0!important;
  }
  .input-container-buttons {
    position: relative;
    overflow: visible;
    border: 1px solid #cdcdcd;
    border-top: 0;
    margin-top: -5px
  }
  .input-buttons {
    border-radius: 5px;
    border: 1px solid grey;
    margin-right: 0.5rem;
    cursor: pointer;
    user-select: none;
  }
  .i-box {
    // position: absolute;  
    display: grid;
    grid-template-columns: repeat(1, 30px);
    grid-template-rows: 35px;
    column-gap: 0.5rem;
    max-height: 45px;
    right: 0.5rem;
    bottom: 0.5rem;
    z-index: 10000000;
  }
  .emoji-picker {
    position: absolute;
    width: 500px;
    top: 50px;
    right: -5px;
    background-color: #fff !important;
    border-radius: 0.5rem !important;
    box-shadow: 10px 10px 40px #bfdbff;
    .border {
      border-color: transparent !important;
    }
  }
  #Categories {
    background-color: #fff !important;
  }
  .i-btn {
    border: 1px solid grey;
  }
}
</style>
