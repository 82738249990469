import * as echarts from 'echarts';

export default {
  methods: {
    TreeChart(id, data, title) {
      const chartDom = document.getElementById(id);
      var myChart = echarts.init(chartDom, null, {
        renderer: 'svg',
      });

      window.addEventListener('resize', () => {
        myChart.resize();
      });

      data.children.forEach((datum, index) => {
        index % 1 === 0 && (datum.collapsed = true);
      });

      // Configuración de las opciones del gráfico
      myChart.setOption({
        title: {
          text: title,
        },
        tooltip: {
          trigger: "item",
          triggerOn: "mousemove",
          formatter: (params) => {
            // For title
            let tooltipText = `
              <span class="text_sm">${
                params.treeAncestors.length == 2 ?
                'Conversaciones del Chatbot' :
                  params.treeAncestors.length == 3 ?
                  'Tópicos' : params.treeAncestors.length == 4 ?
                    'Conversaciones por categoría' :
                    '-'
              }</span><br/>
            `;
            tooltipText += `${params.marker} ${params.data.name}: <label class="mb-0 ml-2">${
              params.treeAncestors.length == 2 ?
              '' :
                params.treeAncestors.length == 3 ?
                params.data.total_percent.toFixed(0) + ' %' :
                  params.treeAncestors.length == 4 ?
                  params.data.cantidad :
                  '-' ||
                '-'
            }</label><br/>`;

            return tooltipText;
          }
        },
        series: [
          {
            type: "tree",
            data: [data], // Se pasa directamente el objeto data
            top: "1%",
            left: "7%",
            bottom: "1%",
            right: "20%",
            symbolSize: 7,
            label: {
              position: "left",
              verticalAlign: "middle",
              align: "right",
              fontSize: 9,
            },
            leaves: {
              label: {
                position: "right",
                verticalAlign: "middle",
                align: "left",
              },
            },
            emphasis: {
              focus: "descendant",
            },
            expandAndCollapse: true,
            animationDuration: 550,
            animationDurationUpdate: 750,
            roam: true
          },
        ],
      });
    }
  },
};