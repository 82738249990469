import * as echarts from 'echarts';

export default {
  // IMPORTATNTE: mantener orden de llegada de variables. Last, Current
  methods: {
    donutChartSm(id, last, current, total) {
      var myChart = echarts.init(document.getElementById(id));

      window.addEventListener('resize', () => {
        myChart.resize();
      });

      // Draw the chart
      myChart.setOption({
        color: ['#cdcdcd', '#2981ef'],
        series: [
          {
            type: 'pie',
            radius: ['50%', '70%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center'
            },
            labelLine: {
              show: false
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '14',
                fontWeight: 'bold'
              }
            },
            data: [
              { value: last, name: total ? `Total: ${total}` : `Anterior: ${last}` },
              { value: current, name: `Actual: ${current}` },
            ],
            itemStyle: {
              opacity: 0.75
            }
          }
        ]
      });
    }
  },
};
