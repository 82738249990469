<template>
  <Modal class="modal-2" ancho="600" alto="500" :show="showFilterModal" @close="$emit('closeModal')">
    <template slot="header">
      <h1 class="titulo__modal mb-4"></h1>
    </template>
    <template slot="content">
      <section class="date-filter">
        <div class="col-lg-12">
          <div class="d-flex">
            <div class="col-md-6">
              <label>Desde</label>
              <input
                id="input__date"
                type="date"
                v-model="time_export_init"
              />
            </div>
            <div class="col-md-6">
              <label>Hasta</label>
              <input
                id="input__date"
                type="date"
                v-model="time_export_end"
              />
            </div>
          </div>
          <p
            @click="empty"
            class="btn-text pl-3"
          >x Borrar fechas</p>
          <div class="row justify-content-center my-4">
            <button
              class="btn btn-base sm"
              @click="pushFilter(time_export_init, time_export_end)"
            >Filtrar</button>
          </div>
        </div>
      </section>
    </template>
  </Modal>
</template>

<script>
export default {
  props: ["showFilterModal"],

  data() {
    return {
      time_export_init: '',
      time_export_end: '',
    };
  },
  methods: {
    pushFilter(init, end) {
      this.$emit("filterDate", init, end);
    },
    empty() {
      this.time_export_init = "";
      this.time_export_end = "";
    },
  }
};
</script>

<style lang="scss" scoped>
.date-filter {
  background-color: #fff;
  border-radius: 10px;
  text-align: left;

  input, select {
    width: 100%;
    margin: 0 0 1rem;
  }
  .contenedor {
    padding: 0 1rem;
  }
  .btn-date {
    @media (max-width: 1024px) {
      display: none;
    }
  }
}
</style>
