import axios from 'axios';
import environment from '@/environment.js';

export default {
  getDeliveriesByBot(botId) {
    return axios.get(
      `${environment.url_env_bot}/whatsapp/deliveries_by_bot/${botId}`
    );
  },
};
