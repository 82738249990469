import { render, staticRenderFns } from "./EditNoteClient.vue?vue&type=template&id=059c609a&scoped=true"
import script from "./EditNoteClient.vue?vue&type=script&lang=js"
export * from "./EditNoteClient.vue?vue&type=script&lang=js"
import style0 from "./EditNoteClient.vue?vue&type=style&index=0&id=059c609a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "059c609a",
  null
  
)

export default component.exports