import { render, staticRenderFns } from "./ChatCards.vue?vue&type=template&id=17ca578c&scoped=true"
import script from "./ChatCards.vue?vue&type=script&lang=js"
export * from "./ChatCards.vue?vue&type=script&lang=js"
import style0 from "./ChatCards.vue?vue&type=style&index=0&id=17ca578c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "17ca578c",
  null
  
)

export default component.exports