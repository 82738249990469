<template>
  <FadeTransition :duration="200">
    <section v-show="selected == 'databot'">
      <hr class="my-5" />
      <h3>Integrar Databot API</h3>
      <p class="mb-5">Si necesitas utilizar la API de Databot puedes hacerlo obteniendo el token aquí. También podrás generar uno nuevo si es necesario.</p>
      <div class="info_box text-center my-5">
        <template v-if="this.currenToken && currenToken.length > 0">
          <label class="text-center">Token Databot</label>
          <input
            v-model="currenToken"
            class="no_input"
            type="password"
            id="inputToken"
          />
          <button
            @click="copyToken()"
            class="btn btn-base second sm"
            >Copiar token</button>
        </template>
        <template v-else>
          <label class="text-center">Genera un nuevo Token</label>
        </template>
        <p
          class="btn-text mt-3 click m-auto pt-4 pt-2"
          @click="generateToken()"
          >Nuevo token</p>
      </div>
    </section>
  </FadeTransition>
</template>

<script>
import { mapState } from "vuex";
import { FadeTransition } from "vue2-transitions";
import dashboard_api from "@/dashboard_api.js";

export default {
  components: {
    FadeTransition
  },
  props: [
    "selected",
  ],
  data() {
    return {
      currenToken: "",
      edit: false,
    };
  },

  created() {
    this.getIntegrations();
  },

  computed: {
    ...mapState(["user"]),
  },

  watch: {
    currenToken(val) {
      return this.currenToken && this.currenToken.length > 0 ? this.edit = true : this.edit = false;
    }
  },
  methods: {
    copyToken() {
      navigator.clipboard.writeText(this.currenToken);

      this.$swal({
        icon: "success",
        title: "¡Copiado correctamente!",
        timer: 1000,
        timerProgressBar: false,
        showConfirmButton: false,
      });
    },

    async generateToken() {
      this.currenToken = (await dashboard_api.get(`/user_credentials/generate_user_api_key`)).data.api_key;

      if (this.currenToken && this.currenToken.length > 0) {
        this.$swal({
          icon: "success",
          title: "Token listo y guardado",
          timer: 1000,
          showConfirmButton: false,
        });
      }
    },
    async getIntegrations() {
      this.currenToken = (await dashboard_api.get(`/relief/user?user_id=${this.user.id}`)).data.api_key;

      this.currenToken && this.currenToken.length > 0 ? this.currenToken : "";
    },
  }
};
</script>
