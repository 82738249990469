<template>
  <section class="report">
    <FadeTransition :duration="200">
      <div class="container">
        <div class="my-4">
          <h1 class="mb-0 text-center">Reportar incidencia</h1>
        </div>
        <div class="formulario p-4" style="background-color: white;">
          <div class="row">
            <div class="col-md-6 col-sm-12">
              <label>Nombre</label>
              <input class="mb-3" :class="error.name && 'input_error'" placeholder="Ingresa tu nombre" v-model="name" />
            </div>
            <div class="col-md-6 col-sm-12">
              <label>Correo</label>
              <input class="mb-3" :class="error.email && 'input_error'" placeholder="Ingresa tu correo" type="email" v-model="email"/>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6 col-sm-12">
              <label>Teléfono</label>
              <input class="mb-3" :class="error.phone && 'input_error'" placeholder="Ingresa tu teléfono" type="phone"
                v-model="phone" />
            </div>
            <div class="col-md-6 col-sm-12">
              <label>Categoría</label>
              <select class="w-100 mb-3" :class="error.category && 'input_error'" v-model="selectedCategory">
                <option value="">Elegir</option>
                <option v-for="category in categories" :key="category.text" :value="category.value">{{
                  category.text }}
                </option>
              </select>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12">
              <label>Asunto</label>
              <input class="mb-3" :class="error.subject && 'input_error'" placeholder="Ingresa el asunto del reporte"
                v-model="subject" />
            </div>
            <div class="col-sm-12">
              <label>Descripción</label>
              <textarea class="mb-3" :class="error.description && 'input_error'"
                placeholder="Ingresa la descripción del reporte" rows="5" v-model="description"></textarea>
            </div>
            <div class="col-sm-12">
              <label>Imágenes (máximo 5)</label>
              <input type="file" ref="input_file" @change="inputFile" class="d-none" accept="image/png, image/jpeg, image/jpg">
              <div class="d-flex justify-content-start align-items-center flex-wrap">
                <button @click="buttonFile" class="btn btn-dark p-2 mr-2 mb-2" :disabled="images.length == 5">Agregar imágen</button>
                <div v-for="(img, index) in images" class="file_images d-flex p-2 mr-2 mb-2" style="width: 125px;">
                  <span class="text-truncate">
                    {{ img.name }}
                  </span>
                  <span class="pointer ml-2" @click="removeFile(index)">X</span>
                </div>
              </div>
            </div>  
          </div>

          <div class="d-flex justify-content-center">
            <button class="btn btn-primary second py-2 px-4 my-4" @click="sendTicket">
              Enviar
            </button>
          </div>
        </div>
      </div>
    </FadeTransition>
  </section>
</template>
  
<script>
import { FadeTransition } from "vue2-transitions";
import { mapState } from 'vuex';
import dashboard_api from "../../dashboard_api";

export default {
  components: {
    FadeTransition,
  },
  data() {
    return {
      categories: [
        {
          text: "Ventas",
          value: "ventas"
        },
        {
          text: "Facturación",
          value: "facturacion"
        },
        {
          text: "Post Venta",
          value: "post_venta"
        },
        {
          text: "Soporte",
          value: "soporte"
        },
        {
          text: "General",
          value: "general"
        },
      ],
      priorities: [
        'Urgente',
        'Media',
        'Baja'
      ],
      name: '',
      // lastname: '',
      email: '',
      phone: '',
      subject: '',
      description: '',
      selectedCategory: '',
      selectedPriority: '',
      loading: false,
      error: {
        name: false,
        lastname: false,
        email: false,
        phone: false,
        category: false,
        priority: false,
        subject: false,
        description: false
      },
      images: []
    }
  },
  watch: {
    name() {
      this.error.name = false
    },
    lastname() {
      this.error.lastname = false
    },
    email() {
      this.error.email = false
    },
    phone() {
      this.error.phone = false
    },
    subject() {
      this.error.subject = false
    },
    description() {
      this.error.description = false
    },
    selectedCategory() {
      this.error.category = false
    },
    // selectedPriority() {
    //   this.error.priority = false
    // },
  },
  mounted() {
    this.name = this.user.name
    this.email = this.user.email
    this.phone = this.user.phone
  },
  computed: {
    ...mapState(["user", "selected_bot"])
  },
  methods: {
    removeFile(index){
      this.images.splice(index, 1)
    },
    buttonFile() {
      this.$refs.input_file.click()
    },
    inputFile(){
      if(this.images.length < 5){
        const newFile = this.$refs.input_file.files[0];
        this.images.push(newFile)        
      }
    },
    getCompanyFromUrl(url){
      let formatedUrl = ''
      if(!url.startsWith("http://") || !url.startsWith("http://")){
        formatedUrl = "https://" + url
      }
      let urlObject = new URL(formatedUrl)
      return urlObject.hostname.replace(/^www\./, '').split('.')[0]
    },
    async sendTicket() {
      try {
        this.$swal({
          title: 'Enviando reporte',
          showConfirmButton: false,
          onBeforeOpen: () => {
              this.$swal.showLoading()
          }
        })
        const company = this.getCompanyFromUrl(this.selected_bot.web)        
        
        let formData = new FormData();
        formData.append('ticket[category]', this.selectedCategory);
        formData.append('ticket[state]', 'pendiente');
        formData.append('ticket[follow_by_email]', true);
        formData.append('ticket[follow_by_whatsapp]', false);
        formData.append('ticket[subject]', this.subject);
        formData.append('ticket[description]', this.description);
        formData.append('ticket[email]', this.email);
        formData.append('ticket[phone]', this.phone);
        formData.append('ticket[name]', this.name);
        formData.append('ticket[company]', company);
        formData.append('ticket[bot_id]', 64);
        formData.append('ticket[sender_bot_id]', this.selected_bot.id);
        if(this.images.length > 0){
          this.images.map(e=>{
            formData.append('ticket_files[]', e);
          })
        }
        this.checkError()
        let resp = await dashboard_api.post(`/tickets`, formData);

        this.$store.dispatch('notificationsModule/createNewTicketNotification', resp.data);
        this.$swal({
          title: 'Reporte enviado',
          icon: 'success',
          text: "Gracias por ayudarnos a mejorar",
        })
      } catch (error) {
        console.log("error al crear ticket", error)
        this.$swal({
          title: 'Hubo un error',
          icon: 'error',
          text: "Inténtelo más tarde",
        })
      }
    },
    checkError() {
      if (!this.name) this.error.name = true
      if (!this.lastname) this.error.lastname = true
      if (!this.email) this.error.email = true
      if (!this.phone) this.error.phone = true
      if (!this.selectedCategory) this.error.category = true
      // if (!this.selectedPriority) this.error.priority = true
      if (!this.subject) this.error.subject = true
      if (!this.description) this.error.description = true
    }
  }
}
</script>
<style lang="scss" scoped>
.report {
  width: 100%
}

.formulario {
  justify-content: center;
  margin: auto;
  width: 1200px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

  .input_error {
    border: 2px solid red;
  }

  @media (max-width: 480px) {
    width: 100%;

    input {
      height: 50px;
      font-size: 1.12rem;
    }
  }
}
.file_images {
    background: #ddd;
    border-radius: 10px;
}
.pointer {
  cursor: pointer;
  user-select: none;
}
</style>
  