import * as echarts from 'echarts';

export default {
  methods: {
    stackedChart(months, data, id, title, names) {
      let chartDom = document.getElementById(id);
      var myChart = echarts.init(chartDom, null, {
        renderer: 'svg',
      });

      window.addEventListener('resize', () => {
        myChart.resize();
      });
      let set_series = [];

      data.forEach((el, index) => {
        let new_obj = {
          name: names[index],
          data: el,
          barWidth: '25%',
          type: 'bar',
          stack: 'total'
        }
        set_series.push(new_obj);
      })

      myChart.setOption({
        title: {
          text: title,
          textStyle: {
            fontSize: 15,
            fontWeight: 700,
            fontFamily: "Manrope, sans-serif",
            color: ['#181818'],
          },
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: (params) => {
            // For title
            let tooltipText = `<span class="text_sm">${params[0].name}</span><br/>`;
            
            params.forEach(item => {
              tooltipText += `${item.marker} ${item.seriesName}: <label class="mb-0 ml-2">${item.value || '-'}</label><br/>`;
            });
            return tooltipText;
          }
        },
        xAxis: {
          type: 'category',
          data: months
        },
        yAxis: {
          type: 'value'
        },
        color: ['#e8e8e8', '#cdcdcd', '#E3F0FF', '#71B1FF', '#2981EF'],
        series: set_series,
      });
    }
  },
};
