<template>
  <FadeTransition :duration="200">
    <div v-show="selected == 'hubspot'">
      <hr class="my-5" />
      <h3 class="mb-4">Integrar Hubspot</h3>
      <br />
      <label class="mb-2">API Key</label>
      <label for="hubspot-api_key" class="card card-click bg_grey mb-5">
        <input
          type="text"
          placeholder="Ingresa tu API key"
          class="no_input"
          v-model="apiKey"
          id="hubspot-api_key"
        />
      </label>
      <div class="row justify-content-between">
        <button @click="saveIntegration" class="btn btn-base md mx-0" :disabled="loading">{{ !edit ? 'Guardar' :
          'Guardar cambios' }}
        </button>
        <button class="btn btn-base outline md mx-0" @click="$emit('closeView')">Cancelar
        </button>
      </div>
      <hr class="mt-5 mb-3" />
    </div>
  </FadeTransition>
</template>

<script>
import { FadeTransition } from "vue2-transitions";
import dashboard_api from "@/dashboard_api.js";

export default {
  components: {
    FadeTransition
  },
  props: [
    "bot_id",
    "fields",
    "selected",
    "hubspot_api_key",
    "integrations"
  ],
  data() {
    return {
      loading: false,
      edit: false,
      credentials: {},
      error: '',
      apiKey: ''
    }
  },
  watch: {
    selected(val) {
      if (this.integrations && this.integrations.length > 0) {
        for (let integration of this.integrations) {
          if (this.selected == integration.provider) {
            this.edit = true;
            this.integrationId = integration.id;
            this.credentials = integration.credentials;
            this.getCredentials();
          } else {
            this.edit = false;
          }
        }
      }
    },
  },
  methods: {
    getCredentials() {
      let currentCredentials = JSON.parse(this.credentials);
      this.apiKey = currentCredentials.apiKey;
    },
    saveIntegration() {
      let status = true;
      this.loading = true;

      if (this.apiKey.length == 0) {
        this.error = 'error_api_key';
        this.loading = false;
      } else if (!this.edit) {
        dashboard_api
          .post("/user_credentials", {
            provider: this.selected,
            credentials: JSON.stringify({ apiKey: this.apiKey }),
            status,
            bot_id: this.bot_id,
          },
          ).then(resp => {
            this.$swal({
              icon: "success",
              title: "¡Vtex integrado!",
              timer: 1000,
              showConfirmButton: false,
            });
            this.loading = false;
            this.error = '';
            this.credentials = {};
            this.$emit('closeView');
            this.$emit('getIntegrations');
          })
          .catch(error => console.log('error->', error));
      } else {
        dashboard_api
          .put(`user_credentials/${this.integrationId}`, {
            credentials: JSON.stringify({ apiKey: this.apiKey }),
          }).then((resp => {
            this.$swal({
              icon: "success",
              title: "Integración modificada",
              timer: 1000,
              showConfirmButton: false,
            });
            this.loading = false;
            this.error = '';
            this.$emit('closeView');
            this.$emit('getIntegrations');
          }));
      }
    }
  }
};
</script>

<style lang="scss" scoped>

</style>
