<template>
  <section class="test">
    <div class="container">
      <div class="row-cart">
        <DataCardChart
          v-for="(item, index) in test"
          lang="es"
          chat_bars
          icon
          hover
          :type="item.type"
          :key="index"
          :model="item"
        />
      </div>
    </div>
  </section>
</template>

<script>
import DataCardChart from "../components/builders/DataCardChart.vue";

export default {
  components: {
    DataCardChart
  },
  data() {
    return {
      // for test card charts components
      test: [
        {
          title: '',
          label: 'Dentro de horario de atención',
          current_value: 150,
          before_value: 300,
          date: '',
          label_url: '',
          url: '',
          type: 'percent'
        },
        {
          title: '',
          label: 'Fuera de horario de atención',
          current_value: 1000000,
          before_value: 2000,
          date: '',
          label_url: '',
          url: '',
          type: 'price'
        },
      ],
    }
  }
}
</script>

<style lang="scss" scoped>
.row-cart {
 display: grid;
 grid-template-columns: 3;
}
</style>