<template>
  <div>
    <button
      @click="launchWhatsAppSignup"
      style="
        background-color: #1877f2;
        border: 0;
        border-radius: 4px;
        color: #fff;
        cursor: pointer;
        font-family: Helvetica, Arial, sans-serif;
        font-size: 16px;
        font-weight: bold;
        height: 40px;
        padding: 0 24px;
      "
    >
      Conectar Nuevo Número
    </button>
  </div>
</template>

<script>
import environment from '../environment';
import axios from 'axios';

export default {
  name: 'FacebookLoginButton',

  mounted() {
    this.loadFacebookSDK();
    window.addEventListener('message', this.sessionInfoListener);
  },
  beforeDestroy() {
    window.removeEventListener('message', this.sessionInfoListener);
  },
  methods: {
    loadFacebookSDK() {
      window.fbAsyncInit = () => {
        FB.init({
          appId: '280308108047238',
          cookie: true,
          xfbml: true,
          version: 'v19.0',
        });
      };
      window.fbAsyncInit();
    },
    // Ensure the SDK is loaded and FB is initialized
    launchWhatsAppSignup() {
      this.ensureFbInit(() => {
        // Tracking for custom analytics
        fbq &&
          fbq('trackCustom', 'WhatsAppOnboardingStart', {
            appId: '280308108047238',
            feature: 'whatsapp_embedded_signup',
          });

        // Launch Facebook login
        FB.login(
          (response) => {
            console.log('🐞 LOG HERE response:', response);
            this.$emit('onEmbeddedSignup', response);
            if (response.authResponse) {
              const code = response.authResponse.code;
              console.log('Finsidhed Code:', code);
              // Here you would normally send the code to your server
            } else {
              console.log('User cancelled login or did not fully authorize.');
            }
          },
          {
            config_id: '446433931214057',
            response_type: 'code',
            override_default_response_type: true,
            extras: {
              sessionInfoVersion: 2, // Receive Session Logging Info
            },
            redirect_uri:
              'https://qd1m02k2-8080.brs.devtunnels.ms/integraciones',
          },
        );
      });
    },

    // Helper method to ensure FB is initialized
    ensureFbInit(callback) {
      if (typeof FB !== 'undefined' && FB.init) {
        callback();
      } else {
        // Retry after 100 milliseconds if FB is not initialized
        setTimeout(() => this.ensureFbInit(callback), 100);
      }
    },

    sessionInfoListener(event) {
      if (event.origin !== 'https://www.facebook.com') return;
      try {
        const data = JSON.parse(event.data);
        this.$emit('onSessionInfo', data);
        console.log('🐞 EVENT IN LISTENER:', data);
        if (data.type === 'WA_EMBEDDED_SIGNUP') {
          if (data.event === 'FINISH') {
            console.log('DATA ON FINISHED SIGNUP:', data);
            const { phone_number_id, waba_id } = data.data;
            console.log('Signup finished:', phone_number_id, waba_id);
          } else {
            console.log('Data when user cancelled step...:', data);
            const { current_step } = data.data;
            console.log('Signup cancelled at step:', current_step);
          }
        }
      } catch (error) {
        console.log('Non JSON Response', event.data);
      }
    },
  },
};
</script>

<style scoped>
/* Additional styles can be written here */
</style>
