<template>
  <section class="historial-conversaciones">
    <SpinnerLoading v-if="loading" />
    <FadeTransition v-else :duration="200" v-show="view_permissions.read == true">
      <div class="row justify-content-center">
        <div class="col-lg-11">
          <div class="row mb-4 align-items-center justify-content-between">
            <h2 class="mb-0">Historial de conversaciones</h2>
            <div class="row btn-head">
              <span
                class="btn-filtro"
                :class="{ active: showFilter }"
                @click="showFilter = !showFilter"
                >Filtrar</span
              >
              <router-link
                :to="{ name: 'crm-clients' }"
                class="btn btn-base md mx-2"
                >Clientes en CRM</router-link
              >
            </div>
          </div>
          <FadeTransition :duration="200">
            <div v-if="showFilter" class="row mt--4 mb-4 align-items-center">
              <button class="btn-date" @click="empty">
                <i class="fas fa-times mr-0" />
              </button>
              <input
                id="input__date"
                type="date"
                @change="getConversationsPage(1)"
                v-model="filter_time_text"
              />
            </div>
          </FadeTransition>
          <div
            v-if="tableDatafiltered.length > 0"
            class="row justify-content-between"
          >
            <div class="col-lg-7 pl-0">
              <section class="tabla__conversaciones buefy">
                <b-table
                  :data="tableDatafiltered"
                  :pagination-position="'bottom'"
                  :default-sort-direction="'desc'"
                  :sort-icon="'caret-up'"
                  aria-next-label="Next page"
                  aria-previous-label="Previous page"
                  aria-page-label="Page"
                  aria-current-label="Current page"
                  icon-pack="fas"
                  class="table pb-1 table-striped"
                >
                  <template slot-scope="props">
                    <b-table-column label="Conversación">
                      <button
                        @click="getFullConversationBySessionID(props.row)"
                        class="btn-base outline sm"
                        :disabled="props.row.lead"
                      >
                        Ver chat
                      </button>
                    </b-table-column>
                    <b-table-column
                      v-if="props.row.lead"
                      label="Nombre"
                      :visible="columnsVisible.name.display"
                    >
                      <i
                        v-if="props.row.conversation_files.length"
                        class="fas fa-paperclip"
                      />
                      <input
                        class="column-input"
                        name="nombre"
                        v-if="newedit.name"
                        @blur="handleBlur(props.row.lead.id)"
                        v-model="props.row.lead.name"
                        @dblclick="changen('name')"
                      />
                      <span v-else @dblclick="changen('name')">{{
                        props.row.lead.name || '- -'
                      }}</span>
                    </b-table-column>

                    <b-table-column
                      field="email"
                      label="Correo"
                      v-if="props.row.lead"
                      :visible="columnsVisible['email'].display"
                      sortable
                    >
                      <div v-if="props.row.lead.email">
                        <span class="sobre"></span>
                        <input
                          class="column-input"
                          name="email"
                          @blur="handleBlur(props.row.lead.id)"
                          v-if="newedit.email"
                          v-model="props.row.lead.email"
                          @dblclick="changen('email')"
                        />
                        <span v-else @dblclick="changen('email')">{{
                          props.row.lead.email
                        }}</span>
                      </div>
                      <div v-else>
                        <span class="sobre"></span
                        ><input
                          class="column-input"
                          name="email"
                          @blur="handleBlur(props.row.lead.id)"
                          v-if="newedit.email"
                          v-model="props.row.lead.email"
                          @dblclick="changen('email')"
                        />
                        <span v-else @dblclick="changen('email')">- -</span>
                      </div>
                    </b-table-column>
                    <b-table-column
                      pack="fab"
                      icon="whatsapp"
                      field="phone"
                      label="Teléfono"
                      v-if="props.row.lead"
                      :visible="columnsVisible['phone'].display"
                      sortable
                    >
                      <div v-if="props.row.lead.phone">
                        <span
                          v-if="
                            props.row.lead.phone.toString().length < 8 ||
                            props.row.lead.phone.toString().length > 11
                          "
                        >
                          <i class="fab fa-whatsapp red" />
                        </span>
                        <span
                          v-else
                          target="_blank"
                          @click="whatsapp(props.row.lead.phone)"
                        >
                          <i
                            class="fab fa-whatsapp"
                            :class="
                              failedNumbers.includes(props.row.lead.phone)
                                ? 'wsap_number_error'
                                : ''
                            "
                          />
                        </span>
                        <input
                          class="column-input"
                          name="phone"
                          @blur="handleBlur(props.row.lead.id)"
                          v-if="newedit.phone"
                          v-model="props.row.lead.phone"
                          @dblclick="changen('phone')"
                        />
                        <span v-else @dblclick="changen('phone')">{{
                          props.row.lead.phone
                        }}</span>
                      </div>
                      <div v-else>
                        <span>
                          <i class="fab fa-whatsapp red" />
                        </span>
                        <input
                          class="column-input"
                          name="phone"
                          @blur="handleBlur(props.row.lead.id)"
                          v-if="newedit.phone"
                          v-model="props.row.lead.phone"
                          @dblclick="changen('phone')"
                        />
                        <span v-else @dblclick="changen('phone')">- -</span>
                      </div>
                    </b-table-column>
                    <!-- Horario de contacto -->
                    <b-table-column
                      field="question"
                      label="Pregunta inicial"
                      v-if="props.row.lead"
                      :visible="columnsVisible['question'].display"
                      sortable
                    >
                      <div v-if="props.row.lead.question">
                        <span></span>
                        <input
                          class="column-input"
                          name="question"
                          @blur="handleBlur(props.row.lead.id)"
                          v-if="newedit.question"
                          v-model="props.row.lead.question"
                          @dblclick="changen('question')"
                        />
                        <span v-else @dblclick="changen('question')">{{
                          props.row.lead.question
                        }}</span>
                      </div>
                      <div v-else>
                        <span></span
                        ><input
                          class="column-input"
                          name="question"
                          @blur="handleBlur(props.row.lead.id)"
                          v-if="newedit.question"
                          v-model="props.row.lead.question"
                          @dblclick="changen('question')"
                        />
                        <span v-else @dblclick="changen('question')">- -</span>
                      </div>
                    </b-table-column>
                    <b-table-column
                      field="contact_date"
                      label="Fecha de contacto"
                      v-if="props.row.lead"
                      :visible="columnsVisible['contact_date'].display"
                      sortable
                    >
                      <div v-if="props.row.contact_date">
                        <span>{{ props.row.contact_date }}</span>
                      </div>
                      <div v-else>
                        <span>- -</span>
                      </div>
                    </b-table-column>
                    <b-table-column
                      field="calification"
                      label="Calificación"
                      :visible="columnsVisible['calification'].display"
                      sortable
                    >
                      <div v-if="props.row.calification">
                        <span><i class="far fa-star" /></span
                        >{{ props.row.calification }}/5
                      </div>
                      <div v-else>
                        <span><i class="far fa-star" /></span>0/5
                      </div>
                    </b-table-column>

                    <b-table-column
                      v-if="props.row.lead && props.row.lead.type_of_operation"
                      field="type_of_operation"
                      label="tipo de operacion"
                      :visible="columnsVisible2['type_of_operation'].display"
                      sortable
                      searchable
                    >
                      <div v-if="props.row.lead.type_of_operation">
                        {{ props.row.lead.type_of_operation }}
                      </div>
                      <div v-else><span></span>- -</div>
                    </b-table-column>

                    <b-table-column
                      v-if="props.row.lead && props.row.lead.type_of_housing"
                      field="type_of_housing"
                      label="tipo de casa"
                      :visible="columnsVisible2['type_of_housing'].display"
                      sortable
                      searchable
                    >
                      <div
                        v-if="props.row.lead && props.row.lead.type_of_housing"
                      >
                        {{ props.row.lead.type_of_housing }}
                      </div>
                      <div v-else><span></span>- -</div>
                    </b-table-column>
                    <b-table-column field="created_at" label="Creada" sortable>
                      <div>
                        {{ messageTime(props.row.created_at) }}
                      </div>
                    </b-table-column>
                  </template>
                </b-table>
              </section>

              <div class="row justify-content-between my-4">
                <div class="pagination">
                  <button
                    class="btn btn-pagination"
                    @click="
                      actual_page = 1;
                      getConversationsPage(1);
                    "
                    v-if="actual_page > 1"
                  >
                    1
                  </button>
                  <button
                    class="btn btn-pagination"
                    @click="
                      actual_page = actual_page - 1;
                      getConversationsPage(actual_page);
                    "
                    v-if="actual_page > 1"
                  >
                    <i class="fas fa-caret-left" />
                  </button>
                  <button
                    class="btn btn-pagination active_page"
                    @click="getConversationsPage(actual_page)"
                    v-if="pages > 2"
                  >
                    {{ actual_page }}
                  </button>
                  <button
                    class="btn btn-pagination"
                    @click="
                      actual_page = actual_page + 1;
                      getConversationsPage(actual_page);
                    "
                    v-if="actual_page < pages"
                  >
                    <i class="fas fa-caret-right" />
                  </button>
                  <button
                    class="btn btn-pagination"
                    @click="
                      actual_page = pages;
                      getConversationsPage(pages);
                    "
                    v-if="actual_page < pages"
                  >
                    {{ pages }}
                  </button>
                </div>
                <!-- <button
                  v-if="chatbot.activated"
                  class="btn-base outline sm"
                  @click="showExportaciones = true"
                >Exportar datos</button> -->
              </div>
            </div>
            <div class="col-lg-5">
              <SpinnerLoading v-if="loadingChat" />
              <FadeTransition v-else :duration="200">
                <ChatPreview :logs="logs" :chat="selected_chat" />
              </FadeTransition>
            </div>
          </div>
          <template v-else>
            <div class="info_box w-100 my-4">
              <img
                class="icon"
                src="/img/icons/15-not_found.svg"
                alt="databot"
              />
              <p class="text-center text_sm my-3">
                Aún no tienes conversaciones.
              </p>
            </div>
          </template>
          <div class="col-lg-4 px-0 my-5">
            <router-link class="btn-text" to="/conversations">&lt;- Volver</router-link>
          </div>
        </div>
      </div>
    </FadeTransition>
    <NoAccessView
      :show="!view_permissions.read && loading == false"
      @loading="loading = false"
    />
  </section>
</template>

<script>
import ChatPreview from '@/components/ChatPreview';
import { mapState } from 'vuex';
import dashboard_api from '../../dashboard_api.js';
import dashboard_api_v2 from '../../dashboard_api_v2.js';
import '../../assets/buefy_class.scss';
import '../../assets/main.scss';
import moment from 'moment-strftime';
import { FadeTransition } from 'vue2-transitions';
import { formatDateRelative } from '@/utils/utils';

export default {
  name: 'clients-id',

  components: {
    ChatPreview,
    FadeTransition,
  },
  data() {
    return {
      tableDatafiltered: [],
      newedit: {
        name: false,
        email: false,
        phone: false,
        contact_time: false,
        question: false,
      },
      showFilter: false,
      loading: true,
      loadingChat: false,
      columnsVisible: {
        name: { title: 'Nombre', display: true },
        email: { title: 'Email', display: true },
        phone: { title: 'Telefono', display: true },
        contact_time: { title: 'Horario de contacto', display: true },
        question: { title: 'Pregunta inicial', display: true },
        contact_date: { title: 'Horario de contacto', display: true },
        calification: { title: 'Calificación', display: true },
        estado: { title: 'Estado', display: true },
      },
      columnsVisible2: {
        type_of_operation: { title: 'Tipo de operación', display: true },
        type_of_housing: { title: 'Tipo de casa', display: true },
        number_of_bedrooms: { title: 'Número de dormitorios', display: true },
        sqare_meter: { title: 'Metros cuadrados', display: true },
        comuna: { title: 'Comuna', display: true },
        region: { title: 'Región', display: true },
      },
      logs: [],
      pages: 0,
      actual_page: 1,
      filter_time_text: '',
      failedNumbers: [],
      selected_chat: {},
    };
  },
  created() {
    window.scrollTo(0, 0);
  },

  computed: {
    ...mapState(['user', 'selected_bot', 'view_permissions']),
  },

  watch: {
    selected_bot: {
      immediate: true,
      handler(val) {
        this.getConversationsPage(1);
      },
    },
  },

  methods: {
    messageTime(date) {
      return formatDateRelative(date);
    },
    whatsapp(phone) {
      const res = phone
        .toString()
        .substring(phone.toString().length - 8, phone.toString().length);
      window.open(`https://wa.me/569${res}`, '_blank');
    },

    // limpiar fecha
    empty() {
      const self = this;
      self.filter_time_text = '';
      self.getConversationsPage(1);
    },

    // metodo para paginacion
    async getConversationsPage(pageNumber) {
      const self = this;
      let dateTime1 = '';
      self.loading = true;

      if (self.filter_time_text != '') {
        dateTime1 = moment(self.filter_time_text).format('YYYY-MM-DD');
      }

      let resp = (
        await dashboard_api.get(
          `/bot/conversations/${self.selected_bot.id}?page=${pageNumber}&filter_date=${dateTime1}`,
        )
      ).data;

      self.tableDatafiltered = resp.data;
      self.pages = resp.pages;
      self.loading = false;
    },
    // Metodo que trae la conversacion del cliente con el bot o agente, segun su id de sesion creado por IBM Watson
    async getFullConversationBySessionID(chat) {
      const self = this;
      self.loadingChat = true;
      self.selected_chat = chat;

      // Validar si existe conversación o no - sino genera error
      let item = self.tableDatafiltered.find(
        (el) => el.session_id == chat.session_id,
      );

      // if (item.lead) {
      dashboard_api
        .get(`/databot_live_logs/get_logs_by_session_id/${chat.session_id}`)
        .then((resp) => {
          self.logs = resp.data;
          self.loadingChat = false;
        })
        .catch(() => (self.loadingChat = false));
      // }
    },
    // metodo para detectar si pierde el foco en el input para editar , edite los campos en la base de datos
    handleBlur(id, e) {
      // obtener el evento del metodo
      if (!e) e = window.event;
      const self = this;
      const { value } = e.target;

      const lead_params = {};
      if (e.target.name == 'nombre') lead_params.name = value;
      if (e.target.name == 'email') lead_params.email = value;
      if (e.target.name == 'phone') lead_params.phone = value;
      if (e.target.name == 'rut') lead_params.rut = value.rut;
      if (e.target.name == 'contact_time') lead_params.contact_time = value;
      if (e.target.name == 'question') lead_params.question = value;

      dashboard_api_v2
        .put(`/leads/${id}`, { lead: lead_params })
        .then(result => {
        self.newedit.name = false;
        self.newedit.email = false;
        self.newedit.phone = false;
        self.newedit.rut = false;
        self.newedit.contact_time = false;
        self.newedit.question = false;
      }).catch((error) => {
        console.log(error);
      });
    },

    // metodo cuando el admin o agente de doble click, cambie a input o a span
    changen(data) {
      const self = this;
      if (data === 'name') {
        self.newedit.name = !self.newedit.name;
      } else if (data === 'email') {
        self.newedit.email = !self.newedit.email;
      } else if (data === 'phone') {
        self.newedit.phone = !self.newedit.phone;
      } else if (data === 'contact_time') {
        self.newedit.contact_time = !self.newedit.contact_time;
      } else if (data === 'question') {
        self.newedit.question = !self.newedit.question;
      }
    },
  },
};
</script>

<style lang="scss">
.loading-chat {
  .loading__icon {
    top: 40%;
    margin: auto !important;
    left: 0 !important;
    right: 0 !important;
  }
}
</style>

<style lang="scss" scoped>
.historial-conversaciones {
  .btn-head {
    margin: 0.5rem 0 0.5rem auto;
  }

  .multiselect {
    @media (max-width: 1024px) {
      max-width: 1000px;
      width: 100%;
    }
    .checkbox-id {
      border-radius: 0 0 10px 10px;
    }
  }

  .col-lg-11 {
    @media (max-width: 320px) {
      padding-left: 0;
      padding-right: 0;
    }
  }
  .row {
    margin-right: 0;
    margin-left: 0;

    @media (max-width: 768px) {
      display: block;
    }
  }
  i.far.fa-star {
    color: #fcae40;
  }
  i.fa-whatsapp {
    font-size: 0.9rem;
    margin-right: 0.2rem;
    color: #00bb2d;

    &:hover {
      color: #767676;
      transition: 0.5s;
    }
  }
  i.fa-whatsapp.red {
    color: #ff6565;
  }

  .tabla__conversaciones {
    background-color: #fff;
    border: 1px solid #f2f2f2;
    max-height: 520px;
    height: 520px;
    border-radius: 1rem;
    overflow-x: scroll !important;
    // scroll
    flex-grow: 1;
    margin-bottom: 0;

    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }
    &::-webkit-scrollbar-thumb {
      margin-bottom: 1rem;
      border-radius: 2rem;
      background: #cdcdcd;
      visibility: visible;
      transition: .25s;

      &:hover, &:active {
        background: #afafaf;
      }
    }
    @media (max-width: 1600px) {
      max-height: 500px;
      overflow-y: scroll !important;
    }
    .column-input {
      padding: 0.2rem 0.3rem;
      margin: 0;
      font-size: 13px;
      font-family: 'Quicksand', 'sans-serif';
    }
  }

  input {
    margin: 0.5rem 0.5rem 0.5rem 0;
    width: auto;

    @media (max-width: 1024px) {
      width: 100%;
      margin: 0.5rem 0;
    }
  }

  .contenedor-exportar {
    margin: 2rem 0;
    padding-right: 0;
    padding-left: 0;

    @media (max-width: 768px) {
      margin-right: 0;
    }
    .exportar {
      font-size: 14px;
      color: #181818;
      padding: 2rem 0;
    }
  }

  .boton-limpiar-fecha {
    border: none;
    border-radius: 5px 0 0 5px;
    background: #cdcdcd;
    color: #fff;
    padding: 0.6rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-right: -4px;
    z-index: 4;
    margin: auto 0;

    @media (max-width: 1024px) {
      display: none;
    }
  }

  select {
    font-size: 14px !important;
    margin: 0.5rem;

    @media (max-width: 1024px) {
      margin: 0.5rem 0;
      width: 100%;
    }
  }

  #input__date {
    border-radius: 0 0.5rem 0.5rem 0;
  }
  .info_box {
    height: 520px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media (max-width: 1600px) {
      height: 430px;
    }
    @media (max-width: 960px) {
      height: auto;
    }
  }
}
.btn-filtro {
  width: 150px;
}
</style>
