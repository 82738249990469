<template>
  <section class="guide">
    <div class="guide_box">
      <div class="col-lg-7">
        <h2 class="mb-2">¡Bienvenido a Databot!</h2>
        <p class="mb-4">Sigue estos pasos:</p>
        <div class="info_box">
          <div class="row justify-content-center">
            <div class="col-xl-7 pl-0">
              <div
                class="card no_card"
                v-for="step, index in steps"
                :key="index"
                :class="{ 'card-selected': selected_step == index }"
                @click="showStep(index)"
              >
              <div class="d-flex">
                <div class="col-custom">
                  <img
                    class="mr-3"
                    :src="step.img_url"
                    alt=""
                  />
                </div>
                <label class="mb-0">{{ step.label }}</label>
              </div>
              </div>
            </div>
            <div class="col-xl-5">
              <div v-if="selected_step == 0">
                <h2>Configuración</h2>
                <p>Tu chatbot viene configurado para que pueda atendiender a tus clientes. En caso de que quieras modificar, ve a la pestaña chatbot, editar.</p>
              </div>
              <div v-if="selected_step == 1">
                <h2>Habilitar</h2>
                <p>Para habilitarlo ve al panel de edición del tema en tu tienda de shopify. Necesitas el ID:
                  <span class="text-blue"
                  >{{ selected_bot.id }}</span> y el token:
                  <span class="text-blue"
                  >{{ selected_bot.token }}</span>
                  <!-- <span class="btn-copy"><i class="far fa-clone mr-2" />Copiar</span> -->
                </p>
                <button @click="goToShopifyTheme" class="btn btn-base second mt-4 mb-2">Ir al tema en Shopify</button>
              </div>
              <div v-if="selected_step == 2">
                <h2>Analítica</h2>
                <p>Podrás obtener resultados y visualizarlos en tu dashboard. Para habilitar necesitas el ID:
                  <span class="text-blue"
                  >{{ selected_bot.id }}</span> y el token:
                  <span class="text-blue"
                  >{{ selected_bot.token }}</span>
                  <!-- <span class="btn-copy"><i class="far fa-clone mr-2" />Copiar</span> -->
                </p>
                <button @click="goToShopifyTheme" class="btn btn-base second mt-4 mb-2">Ir al tema en Shopify</button>
              </div>
            </div>
          </div>
        </div>
        <p
          class="btn-text d-block my-5"
          @click="skipView()"
        >Habilitar chatbot más tarde<i class="fas fa-arrow-right ml-2" />
        </p>
      </div>
    </div>
  </section>
</template>

<script>
import dashboard_api from '@/dashboard_api.js';
import { mapMutations, mapState } from 'vuex';
import { FadeTransition } from 'vue2-transitions';

export default {
  components: {
    FadeTransition
  },
  data() {
    return {
      originPlatformBase: {},
      info: [],
      selected_step: 0,
      showGuide: true,
      steps: [
        {
          label: 'Configura funcionalidades de tu chatbot.',
          img_url: '/img/icons/1-circle.svg',
        },
        {
          label: 'Habilita tu chatbot al sitio web.',
          img_url: '/img/icons/2-circle.svg',
        },
        {
          label: 'Analiza los datos en tu dashboard.',
          img_url: '/img/icons/3-circle.svg',
        },
      ]
    };
  },

  computed: {
    ...mapState(['skip', 'user', 'selected_bot', 'logged']),
  },

  methods: {
    ...mapMutations(['toggleSkipGuide']),

    // copyText(text) {
    //   navigator.clipboard.writeText(text);

    //   try {
    //     this.$swal({
    //       toast: true,
    //       position: 'top-end',
    //       icon: "success",
    //       title: "Copiado",
    //       timer: 1000,
    //       timerProgressBar: false,
    //       showConfirmButton: false,
    //     });
    //   } catch (err) {
    //     this.$swal({
    //       icon: "error",
    //       title: "Ha habido un problema",
    //     });
    //   }
    // },

    showStep(index) {
      this.selected_step = index;
    },

    async goToShopifyTheme() {
      if (this.logged == 'true') {
        this.originPlatformBase = (await dashboard_api.get(`user_data/${this.user.id}`)).data;

        dashboard_api
          .put('/update_origin_ecommerce', {
            user_id: this.user.id,
            origin_ecommerce: {
              url_origin: this.originPlatformBase.origin_ecommerce.url_origin,
              welcome_instructions: false,
            }
          })
          .then(resp => {
            window.open(`https://${this.originPlatformBase.origin_ecommerce.url_origin}/admin/themes/current/editor?context=apps&appEmbed=493568c5-b33c-4dd3-834b-1d9e58a66f29%2Fapp-embed`, '_blank');
          });
      }
    },

    skipView() {
      if (this.skip == false) {
        this.toggleSkipGuide();
        this.$router.push('/dashboard');
      } else console.log('this.skip', this.skip);
    }
  }
};
</script>

<style lang="scss" scoped>
.guide {
  width: 100%;
  height: 100%;

  &_box {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 5rem;

    @media (max-width: 991px) {
      padding: 0 1rem;
    }
  }

  .col-custom {
    width: 35px;
  }

  .card {
    position: relative;
    display: flex;
    margin: .75rem auto;
    z-index: 10;

    &:first-child {
      margin: 0 auto .5rem;
    }
    &:last-child {
      margin: .5rem auto 0;

      @media (max-width: 1199px) {
        margin-bottom: 2rem;
      }
      &::before {
        border: none;
      }
    }
    &::before {
      content: '';
      position: absolute;
      left: 1.75rem;
      bottom: -1rem;
      border-right: 1px solid #cdcdcd;
      height: 1rem;
      z-index: 5;
    }

    img {
      width: 1.5rem;
    }
  }
  .info_box {
    padding: 2rem;
    border-radius: 1.75rem;
  }
  .card-selected {
    &::before {
      border-color: #2981EF;
    }
  }
  .text-blue:hover ~ .btn-copy {
    top: 0;
    right: 0;
    visibility: visible;
    opacity: 1;
    transition: .5s;
  }
  .btn-copy {
    cursor: normal;
    &:hover {
      visibility: hidden !important;
      opacity: 0 !important;
    }
  }
}
</style>
