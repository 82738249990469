<template>
  <Modal class="modal-2" ancho="600" alto="500" :show="showModal" @close="$emit('closeModal')">
    <template slot="header">
      <h1 class="titulo__modal mb-4"></h1>
    </template>
    <template slot="content">
      <section class="exportaciones">
        <div class="col-lg-12">
          <div class="row align-items-center mt-3 mb-5">
            <div class="col-lg-10">
              <label for="">Exportar datos</label>
              <p class="mb-0">
                Aquí podrás exportar los datos de los últumos 30 días o por las fechas que seleccionaes.
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-12">
          <div class="d-flex">
            <div class="col-md-6">
              <label>Desde</label>
              <input
                id="input__date"
                class="mb-2"
                type="date"
                v-model="time_init"
              />
            </div>
            <div class="col-md-6">
              <label>Hasta</label>
              <input
                id="input__date"
                class="mb-2"
                type="date"
                v-model="time_end"
              />
            </div>
          </div>
          <p @click="empty" class="btn-text pl-3">x Borrar fechas</p>
          <div class="row justify-content-center my-4">
            <button
              class="btn btn-base sm"
              @click="$emit('export', time_init, time_end)"
              >Exportar excel
            </button>
          </div>
        </div>
      </section>
    </template>
  </Modal>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: ["showModal", "closeModal"],

  data() {
    return {
      time_init: '',
      time_end: '',
    };
  },
  computed: {
    ...mapState(['selected_bot']),
  },
  methods: {
    empty() {
      this.time_init = "";
      this.time_end = "";
    },
  }
};
</script>

<style lang="scss" scoped>
.exportaciones {
  background-color: #fff;
  border-radius: 10px;
  text-align: left;

  input {
    width: 100%;
  }
}
</style>
