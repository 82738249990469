<template>
  <section class="instal_jumpseller">
    <div class="col-lg-7 justify-content-center m-auto">
      <template v-if="botnull == false">
        <template v-if="open == false">
          <h1>
            Selecciona el chatbot que desea
            <span class="destacado">instalar</span>
          </h1>
          <br />
          <div class="col-lg-5 justify-content-center m-auto">
            <select v-model="bot">
              <option disabled value="null">Selecciona un chatbot</option>
              <option v-for="bot in bots" :value="bot.bot" :key="bot.bot.id">
                {{ bot.bot.bot_name }}
              </option>
            </select>
            <br />
            <button class="btn btn-base md" @click.prevent="install()">
              Instalar
            </button>
            <br />
            <p class="my-4" @click.prevent="goToDashboard()">
              ¿Ya tienes tu chatbot instalado?
              <span class="btn-text p-destacado">Ingresa aquí</span>
            </p>
          </div>
        </template>
        <!-- Vista proceso terminado -->
        <template v-else>
          <h1 class="destacado">¡Felicidades!</h1>
          <h2>Tu chatbot fue implementado correctamente</h2>
          <p>Ahora puedes ingresar a databot</p>
          <br />
          <button class="btn btn-base second md" @click="goToDashboard">
            Ingresa aquí
          </button>
        </template>
      </template>
      <!-- Vista sin chatbot -->
      <template v-else>
        <div class="col-lg-5 justify-content-center m-auto my-5 py-5">
          <h1 class="destacado">Oops..</h1>
          <h1 class="my-4">No tienes un chatbot registrado en esta cuenta.</h1>
          <button class="btn btn-base lg" @click.prevent="goToNewBot()">
            Crear aquí
          </button>
          <br />
          <hr class="my-5" />
          <h2 class="my-4">
            Cuando termines de crearlo, has click en actualizar:
          </h2>
          <button class="btn btn-base outline lg" @click.prevent="refresh()">
            Actualizar
          </button>
        </div>
      </template>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import dashboard_api from "@/dashboard_api.js";
import chatbot_api from "@/chatbot_api.js";
import environment from "@/environment.js";
import { getCurrentEcommercePlatform } from "@/utils/utils";
import store from "@/store/store";
import { checkHeadersForReauthorization } from "@/utils/useAuthenticatedShopify.js";

const AppBridgeUtils = window["app-bridge-utils"];

export default {
  data() {
    return {
      bots: [],
      bot: null,
      open: false,
      botnull: false
    };
  },

  created() {
    const self = this;
    dashboard_api.get(`/widget_whatsapp/getwidget/${self.user.id}`).then(response => {
      self.bots = response.data;
      if (response.data == "") {
        self.botnull = true;
      }
    });
  },

  computed: {
    ...mapState(["user", "platform_name"])
  },

  methods: {
    goToDashboard() {
      let platform = getCurrentEcommercePlatform();
      if (platform === "shopify") {
        this.$router.push({ name: "dashboard" });
      } else {
        // defecto
        window.open("https://ia.databot.cl/dashboard");
      }
    },
    goToNewBot() {
      window.open("https://ia.databot.cl/chatbot/new");
    },
    refresh() {
      window.location.reload();
    },
    async install() {
      const self = this;
      if (self.bot.id == "" && self.bot.token == "") {
        self.$swal({
          icon: "error",
          title: "El id y el token no pueden estar vacios"
        });
      } else {
        // let platform = getCurrentEcommercePlatform();
        if (self.platform_name === "shopify") {
          await self.installShopifyBot(
            self.$store.state.ecommercePlatformsModule.platformParameters.shop,
            self.$store.state.ecommercePlatformsModule.platformParameters.access_token,
            self.bot.id,
            self.bot.token
          );
        } else {
          // por defecto, jumpseller
          await chatbot_api.post("/implementar", {
            id: self.bot.id,
            token: self.bot.token,
            token_access: self.$route.query.token_access
          });
        }
        self.$swal({
          icon: "success",
          title: "Chatbot instalado exitosamente"
        });
        self.open = true;
      }
    },
    async installShopifyBot(shop, access_token, bot_id, bot_token) {
      AppBridgeUtils.getSessionToken(store.state.ecommercePlatformsModule.shopifyAppInstance).then(
        session => {
          axios
            .post(
              `${environment.socket_url}/api/shopify/intall-bot`,
              {
                shop,
                access_token,
                bot_id,
                bot_token
              },
              {
                headers: {
                  Authorization: `Bearer ${session}`
                }
              }
            )
            .catch(error => {
              console.log("🚀 Aqui *** -> error", error);
              if (error.response.status === 403) {
                checkHeadersForReauthorization(error.response.headers);
              }
              throw new Error(error);
            });
        }
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.instal_jumpseller {
  display: flex;
  height: 75vh;
  text-align: center;

  select {
    width: 100%;
    margin-bottom: 2rem;
  }
  .btn-base {
    width: 200px;
  }
  label {
    font-size: 1rem;
  }
}
</style>
