<template>
  <FadeTransition :duration="200">
    <section v-show="selected == 'blue_express'">
      <hr class="my-5" />
      <h3>Integrar Blue Express</h3>
      <div class="row mt-5 mb-4">
        <div class="col-lg-6 pl-0">
          <p class="text_error" v-if="error == 'error_blue_token'">Completa el token</p>
          <label v-else>Token</label>
          <input :class="{ border_error: error == 'error_blue_token' }" placeholder="Ingresa tu token" type="text"
            v-model="blue.token" />
        </div>
        <div class="col-lg-6 pr-0">
          <p class="text_error" v-if="error == 'error_user_code'">Completa el código de usuario</p>
          <label v-else>Código de usuario</label>
          <input :class="{ border_error: error == 'error_user_code' }" placeholder="Ingresa tu código de usuario"
            type="text" v-model="blue.userCode" />
        </div>
      </div>
      <p class="text_error" v-if="error == 'error_client_account'">Completa la cuenta de cliente</p>
      <label v-else class="mb-2">Cuenta de cliente</label>
      <label for="blue-client-account" class="card card-click mb-5"
        :class="{ border_error: error == 'error_client_account' }">
        <input type="text" placeholder="Ingresa tu cuenta de cliente" class="no_input" id="blue-client-account"
          v-model="blue.clientAccount" />
      </label>
      <div class="row justify-content-between">
        <button @click="saveIntegration()" class="btn btn-base md mx-0" :disabled="loading">{{ !edit ? 'Guardar' :
          'Guardar cambios' }}
        </button>
        <button class="btn btn-base outline md mx-0" @click="$emit('closeView')">Cancelar
        </button>
      </div>
      <hr class="mt-5 mb-3" />
    </section>
  </FadeTransition>
</template>
  
<script>
import { FadeTransition } from "vue2-transitions";
import dashboard_api from "@/dashboard_api.js";

export default {
  components: {
    FadeTransition
  },
  props: [
    "selected",
    "bot_id",
    "fields",
    "integrations",
  ],
  data() {
    return {
      edit: false,
      loading: false,
      credentials: {},
      error: '',
      blue: {
        token: '',
        userCode: '',
        clientAccount: '',
      }
    };
  },
  watch: {
    selected(val) {
      if (this.integrations && this.integrations.length > 0) {
        const integrationFound = this.integrations.find(e => e.provider == val)
        if (integrationFound && integrationFound.provider === val) {
          this.edit = true;
          this.integrationId = integrationFound.id;
          this.credentials = integrationFound.credentials;
          this.getCredentials();
        } else {
          this.edit = false;
        }
      }
    },
    fields(val) {
      this.edit = false,
      this.blue.token = '';
      this.blue.userCode = '';
      this.blue.clientAccount = '';
    }
  },

  methods: {
    getCredentials() {
      let currentCredentials = JSON.parse(this.credentials);
      this.blue.token = currentCredentials.token;
      this.blue.userCode = currentCredentials.userCode;
      this.blue.clientAccount = currentCredentials.clientAccount;
    },
    saveIntegration() {
      let status = true;
      this.loading = true;

      const { token, userCode, clientAccount } = this.blue;

      if (token.length == 0) {
        this.error = 'error_blue_token';
        this.loading = false;
      } else if (userCode.length == 0) {
        this.error = 'error_user_code';
        this.loading = false;
      } else if (clientAccount.length == 0) {
        this.error = 'error_client_account';
        this.loading = false;
      } else if (!this.edit) {
        dashboard_api
          .post("/user_credentials", {
            provider: this.selected,
            credentials: JSON.stringify(this.blue),
            status,
            bot_id: this.bot_id,
          },
          ).then(resp => {
            console.log('Guardado', resp.data);

            this.$swal({
              icon: "success",
              title: "¡Blue Express integrado!",
              timer: 1000,
              showConfirmButton: false,
            });
            this.loading = false;
            this.error = '';
            this.credentials = {};
            this.$emit('closeView');
            this.$emit('getIntegrations');
          })
          .catch(error => console.log('error->', error));
      } else {
        dashboard_api
          .put(`user_credentials/${this.integrationId}`, {
            credentials: JSON.stringify(this.blue)
          }).then((resp => {
            console.log('resp edit jump', resp.data);

            this.$swal({
              icon: "success",
              title: "Integración modificada",
              timer: 1000,
              showConfirmButton: false,
            });
            this.loading = false;
            this.error = '';
            this.$emit('closeView');
            this.$emit('getIntegrations');
          }));
      }
    }
  }
};
</script>
  