<template>
  <section class="tabla-clientes">
    <div class="row justify-content-between align-items-center b-bottom">
      <h3 class="my-2">Seguimiento de reportes</h3>
    </div>
    <SpinnerLoading v-if="loading" />
    <template v-else-if="quantity > 0">
      <section class="table buefy">
        <b-table
          :range-before="rangeBefore"
          :range-after="rangeAfter"
          :data="tickets"
          :per-page="itemsPerPage"
          :current-page.sync="currentPage"
          :pagination-simple="true"
          :pagination-position="'bottom'"
          :default-sort-direction="'desc'"
          :sort-icon="'caret-up'"
          aria-next-label="Next page"
          aria-previous-label="Previous page"
          aria-page-label="Page"
          aria-current-label="Current page"
          icon-pack="fas"
          :key="tableKey"
          :selected.sync="selectedRow"
          :sticky-header="stickyHeaders"
          height="500"
          focusable
        >
          <template slot-scope="props">
            <b-table-column
              field="tracking_code"
              label="Código de seguimiento"
              sortable
            >
              <p class="e-show">
                {{ props.row.ticket.tracking_code }}
              </p>
            </b-table-column>

            <b-table-column
              field="subject"
              label="Asunto"
              sortable
            >
              <p class="e-show">
                {{ props.row.ticket.subject }}
              </p>
            </b-table-column>

            <b-table-column
              field="description"
              label="Descripción"
              sortable
            >
              <p class="e-show text-truncate" style="max-width: 150px;">
                {{ props.row.ticket.description }}
              </p>
            </b-table-column>

            <b-table-column
              field="state"
              label="Estado"
              sortable
              style="width: 140px;"
            >
              <p>
                {{ capitalize(props.row.ticket.state) }}
              </p>
            </b-table-column>

            <b-table-column
              field="messages"
              label="Mensajes"
              sortable
            >
              <p>
                <i class="fas fa-comments messages_button p-2" @click="openMessages(props.row)"></i>
              </p>
            </b-table-column>

            <b-table-column
              field="created"
              label="Creado"
              sortable
            >
              <p class="e-show">
                {{ $moment(props.row.ticket.created_at).format("DD/MM/YYYY - hh:mm a") }}
              </p>
            </b-table-column>

            <b-table-column
              field="modified"
              label="Modificado"
              sortable
            >
              <p class="e-show">
                {{ $moment(props.row.ticket.updated_at).format("DD/MM/YYYY - hh:mm a") }}
              </p>
            </b-table-column>

          </template>
        </b-table>
      </section>
    </template>

    <template v-else>
      <div class="no_data">
        <img class="icon" src="/img/icons/15-not_found.svg" alt="databot" />
        <p class="text-center text_sm my-3">
          No hay reportes.
        </p>
      </div>
    </template>

    <div class="table-footer">
      <div class="col-md-4 pl-0">
        <span class="text_sm mr-3 mb-0">
          Visibles: {{ tickets.length == 1 ? tickets.length + " Ticket." : tickets.length + " Tickets." }}
        </span>
      </div>
      <div class="col-md-4 d-flex flex-column justify-content-center">
        <div class="d-flex justify-content-center">
          <p
            class="pagination__firstPage my-auto mx-4"
            :class="actual_page != 1 && 'allowed'"
            :style="`cursor: ${actual_page == 1 ? 'not-allowed' : 'pointer'}`"
            @click="actual_page != 1 && getAllTickets(1)"
          >
            1
          </p>
          <div class="number-input my-auto">
            <button
              class="btn-left left-arrow py-1 px-3"
              type="button"
              id="button-addon1"
              @click="
                actual_page != 1 && getAllTickets(Number(actual_page) - 1)
              "
              :disabled="actual_page == 1"
            ></button>
            <div>
              <input
                class="input_page m-0"
                :class="{ input_error: errorInputPage }"
                type="number"
                :max="pages"
                v-model="input_page"
                @keyup="inputPageSubmit"
                error="true"
              />
            </div>
            <button
              class="btn-right right-arrow py-1 px-3"
              type="button"
              id="button-addon2"
              :disabled="actual_page == pages"
              @click="
                actual_page != pages && getAllTickets(Number(actual_page) + 1)
              "
            ></button>
          </div>
          <p
            class="pagination__lastPage my-auto mx-4"
            :class="actual_page != pages && 'allowed'"
            :style="
              `cursor: ${actual_page == pages ? 'not-allowed' : 'pointer'}`
            "
            @click="actual_page != pages && getAllTickets(pages)"
          >
            {{ pages }}
          </p>
        </div>
        <span
          v-if="errorInputPage"
          class="text_sm text_error text-center mx-auto mt-2"
        >
          El valor ingresado debe ser entre 1 y {{ pages }}
        </span>
      </div>
      <div class="col-md-4 d-flex align-items-center justify-content-end pr-0">
        <!-- REVISAR -->
        <!-- <span v-if="totalLeadsId" class="text_sm mb-0 mr-3">{{ totalLeadsId.length }} leads en total.</span> -->
        <select
          name="items"
          class="select_text my-1 mr-3"
          @change="getAllTickets(1)"
          v-model="itemsPerPage"
        >
          <option value="15">15 tickets por página</option>
          <option value="30">30 tickets por página</option>
          <option value="50">50 tickets por página</option>
        </select>
      </div>
    </div>

    <Modal
      class="modal-2"
      ancho="600"
      alto="500"
      :show="showChat"
      @close="showChat=false"
      >
      <template slot="header">
        <h1 class="titulo__modal mb-4">
          Mensajes
        </h1>
      </template>
      <template slot="content">
        <section class="chat_container d-flex flex-column justify-content-between align-items-center">
          <div class="accordion_container">
            <div class="accordion mt-3 mx-0 py-2 px-3 d-flex justify-content-between" :class="{ 'accordion_active': activeAccordion }" @click="handleAccordion">
              <span>Ticket {{ selectedTicket?.ticket?.tracking_code }}</span>          
              <span class="accordion-icon">{{ activeAccordion ? '-' : '+' }}</span>
            </div>
            <div class="accordion-item px-3 py-2 mx-0" :class="{ 'accordion-item_active': activeAccordion }">
              <h4>{{ selectedTicket?.ticket?.subject }}</h4>
              <p>{{ selectedTicket?.ticket?.description }}</p>
              <div class="d-flex justify-content-start align-items-center flex-wrap">
                <div v-for="image in selectedTicket?.ticket_files">
                  <a :href="image" target="_blank">
                    <img :src="image" class="chat_image mr-2">
                  </a>
                </div>
              </div>
            </div>
          </div>
          <SpinnerLoading v-if="loadingMessages" />
          <template v-else-if="messages.length > 0">
            <div class="chat w-100 h-100 my-2 d-flex flex-column" ref="chat">
              <div v-for="message in messages" v-if="message.body || message.comment_files_urls.length > 0" class="chat_bubble" :class="listAgents.find(e=>e.id == message.user_bot_id) ? 'chat_bubble_right ml-auto mr-4 my-2' : 'chat_bubble_left mr-auto ml-4 my-2'">
                <div>
                  <p v-if="message.body" class="text-left mb-2">{{ message.body }}</p>
                  <div class="d-flex justify-content-start align-items-center mb-2 flex-wrap">
                    <div v-for="image in message.comment_files_urls" class="m-1 pointer">
                      <a :href="image" target="_blank">
                        <img :src="image" class="chat_image">
                      </a>
                    </div>
                  </div>
                  <p class="text-right text_xs">{{ $moment(message.created_at).format("DD/MM/YY - h:mm a")}} </p>
                </div>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="d-flex justify-content-center align-items-center w-100 h-100">
              <div>
                <img class="icon" src="/img/icons/15-not_found.svg" alt="databot" />
                <p class="text-center text_sm my-3">
                  No hay mensajes
                </p>
              </div>
            </div>
          </template>
          <div class="w-100 px-4">
            <div v-for="(image, index) in images" class="file_images d-flex justify-content-between align-items-center p-2 my-1">
              <span>
                {{ image.name }}
              </span>
              <span class="pointer" @click="removeFile(index)">X</span>
            </div>
          </div>
          <div class="chat_input_containter w-100 p-4 d-flex justify-content-between align-items-center">
            <input class="w-100 mb-0 mr-4" type="text" placeholder="Escriba su mensaje" v-model="inputMessage" @keyup="sendMessage"/>
            <input type="file" accept="image/png, image/jpeg, image/jpg" class="d-none" ref="input_file" @change="inputFile">
            <button class="btn btn-light h-100 mr-2" @click="buttonFile"><i class="fas fa-paperclip p-2 m-0"></i></button>
            <button class="btn btn-primary h-100" @click="sendMessage(null)"><i class="fas fa-paper-plane p-2 m-0"></i></button>
          </div>
        </section>
      </template>
    </Modal>
  </section>
</template>

<script>
import { FadeTransition } from "vue2-transitions";
import { mapState } from 'vuex';
import dashboard_api from "../../dashboard_api";
import '../../assets/buefy_class.scss';
import '../../assets/main.scss';

export default {
  components: {
    FadeTransition,
  },
  data(){
    return {
      tickets: [],
      allTickets: [],
      input_page: this.actual_page,
      actual_page: 1,
      loading: false,
      pages: 0,
      quantity: 0,
      tableKey: 1,
      itemsPerPage: 15,
      currentPage: 1,
      errorInputPage: false,
      // TABLE PROPS
      rangeBefore: 1,
      rangeAfter:1,
      tableKey: 0,
      checkedRows: [],
      selectedRow: {},
      stickyHeaders: true,
      showChat: false,
      inputMessage: '',
      loadingMessages: false,
      messages: [],
      listAgents: [],
      selectedTicket: null,
      userbot: null,
      activeAccordion: false,
      images: []
    }
  },
  computed: {
    ...mapState(["selected_bot", "user"])
  },
  async mounted() {
    try {
      this.loading = true
      await this.getUserBot()
      await this.getAllAgents()
      await this.getAllTickets(this.currentPage)
    } catch (error) {
      console.log("error en el mounted", error);
    } finally {
      this.loading = false
    }
  },
  methods: {
    handleAccordion() {
      this.activeAccordion = !this.activeAccordion
    },
    removeFile(index){
      this.images.splice(index, 1)
    },
    buttonFile() {
      this.$refs.input_file.click()
    },
    inputFile(){
      if(this.images.length < 5){
        const newFile = this.$refs.input_file.files[0];
        this.images.push(newFile)        
      }
    },
    async getAllAgents(){
      try {
        this.listAgents = (await dashboard_api.get(`/userbot/user_for_bot/${this.selected_bot.id}`)).data
        console.log("this.listAgents",this.listAgents);
      } catch (error) {
        console.log("error al obtener agentes asignados", error);
      }
    },
    async openMessages(ticket){
      try {
        this.showChat = true
        this.loadingMessages = true
        this.selectedTicket = ticket
        const response = (await dashboard_api.get(`/comments/by_ticket_id/${ticket.ticket.id}`)).data
        this.messages = response
        setTimeout(() => {
          this.$refs.chat.scrollTo(0, this.$refs.chat.scrollHeight);
        }, "300");
      } catch (error) {
        console.log(error)
      } finally {
        this.loadingMessages = false
      }
    },
    async getUserBot(){
      try {
        const response = await dashboard_api.get(`/userbot/show_bots_for_user/${this.user.id}`)
        const filterBot = response.data.find(e => e.bot_id == this.selected_bot.id)
        this.userbot = filterBot.id
      } catch (error) {
        console.log(error)
      }
    },
    async sendMessage(event) {
      try {
        if(event && event.keyCode != 13) return
        if(!this.inputMessage && this.images.length == 0) return
        let formData = new FormData();
        if(this.images.length > 0) {
          this.images.map(e => {
            formData.append('comment_files[]', e);
          })
        }
        this.images = []
        const body = this.inputMessage
        this.inputMessage = ''
        this.loadingMessages = true
        formData.append('comment[sender]', this.user.name)
        formData.append('comment[body]', body)
        formData.append('comment[ticket_id]', this.selectedTicket.ticket.id)
        formData.append('comment[user_bot_id]', this.userbot)
        await dashboard_api.post("/comments", formData)
        this.openMessages(this.selectedTicket)
      } catch (error) {
        console.log(error)
      } finally {
        this.loadingMessages = false
      }
    },
    inputPageSubmit(e) {
      this.errorInputPage = false;
      if (e.key === 'Enter' || e.keyCode === 13) {
        if (this.input_page >= 1 && this.input_page <= this.pages) {
          this.getAllTickets(Number(this.input_page));
        } else this.errorInputPage = true;
      }
    },
    capitalize(string) {
      if(string)
        return string.charAt(0).toUpperCase() + string.slice(1)
      else return string
    },
    async getAllTickets(page) {
      try {
        this.input_page = page
        this.actual_page = page
        let filters = {
          "sender_bot_id": this.selected_bot.id
        }

        this.loading = true
        const response = (await dashboard_api.post('/tickets/tickets_by_bot', {
          "page": page,
          "items": this.itemsPerPage,
          "filters": filters
        })).data
        this.tickets = response.tickets
        this.allTickets = response.tickets
        this.pages = response.pages
        this.quantity = response.quantity
        this.tableKey += 1; // esto hace que el componente de la tabla se renderice de nuevo para evitar bugs

      } catch (error) {
        console.log("error al obtener tickets", error);
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss">
.tabla-clientes {
  .buefy .b-table .table {
    border: none;
  }
}
</style>

<style lang="scss" scoped>
.tabla-clientes {
  width: 100%;
  height: calc(100vh - 90px);
  display: grid;
  grid-template-rows: auto 2fr 90px;
  .bg-spinner {
    position: relative;
    height: calc(80vh - 90px);
  }
  .table {
    height: 100%;
    overflow: scroll;

    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
      background-color: transparent;
    }

    /* Color de fondo y radius esquinas del thumb */
    &::-webkit-scrollbar-thumb {
      background: #e8e8e8;
      border-radius: 2rem;
      margin-bottom: 1rem;
      transition: 0.5s;

      /* Cambiamos el fondo en hover */
      &:hover {
        transition: 0.5s;
        background: #cdcdcd;
      }

      /* Cambiamos el fondo en active */
      &:active {
        background-color: #cdcdcd;
      }
    }
  }

  .table-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    border-top: 1px solid #e8e8e8;
  }

  .input_page {
    width: 80px;
  }

  .no_data {
    width: 100%;
    height: 100%;
    background-color: #fafafa;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .b-bottom {
    padding: 1.5rem 2rem;
    border-bottom: 1px solid #e8e8e8;
  }

  .e-show:hover {
    .i-btn-hover {
      opacity: 1;
    }
  }
  .messages_button {
    transition: 300ms ease;
    &:hover {
      color: #2A81EF;
      font-size: 1.2rem;
    }
  }
  .chat {
    max-width: 100%;
    overflow: auto;
    scroll-behavior: smooth;
    &_image {
      width: 75px;
      height: 75px;
      object-fit: cover;
      border:1px solid #bbb; 
      border-radius: 5px;
    }
    &::-webkit-scrollbar {
      width: 7px;
    }
    &::-webkit-scrollbar-thumb {
      visibility: visible;
      border-radius: 2rem;
      background: #cdcdcd;
      transition: .25s;

      &:hover, &:active {
        background: #afafaf;
      }
    }
    &_bubble {
      border-radius: 1rem;
      padding: 0.75rem 1rem;
      max-width: 70%;
      &_right {
        background-color: #eff6ff;
        border: 1px solid #bfdbff;
        float: right
      }
      &_left {
        background-color: #fafafa;
        border: 1px solid #cdcdcd;
        float: left
      }
    }
    &_container {
      height: 80vh;
      overflow-y: auto;
    }
  }
  .file_images {
    background: #f5f5f5;
    border-radius: 10px;
  }
  .pointer {
    cursor: pointer;
    user-select: none;
  }
  .accordion {
    background: #eee;
    color:black;
    border-radius: 5px;
    user-select: none;
    transition: background 0.4s ease;
    &:hover {
      background: #ddd;
    }
    // width: calc(100% - 32px);
    cursor: pointer;
    &_active {
      border-bottom-left-radius: 0px;  
      border-bottom-right-radius: 0px;  
    }
    &-icon {
      font-size: 1.2rem;
    }
    &_container {
      width: calc(100% - 32px)
    }
    &-item {
      // width: calc(100% - 32px);
      background-color: #fff;
      display: none;
      overflow: hidden;
      text-align: left;
      border: 1px solid #ddd;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      &_active {
        display: block;
        overflow: visible;
      }
    }
  }
}
</style>
