<template>
  <Modal
    class="modal-2"
    ancho="600"
    alto="500"
    :show="showModaledit"
    @close="$emit('closeModaledit')"
  >
    <template slot="header">
      <h1 class="titulo__modal mb-4"></h1>
    </template>
    <template slot="content">
      <section class="agregar-nota">
        <div class="col-lg-12">
          <label>Titulo de la nota</label>
          <input
            type="text"
            maxlength="50"
            placeholder="Ejem: Importante"
            v-model="editNoteModal.title"
          />
          <label>Mensaje</label>
          <textarea
            class="textarea_2"
            rows="3"
            maxlength="200"
            placeholder="Escribe la nota aquí"
            v-model="editNoteModal.body"
          />
          <p class="text_sm text-right">{{ editNoteModal.body.length }} / 200</p>
          <div class="row justify-content-center my-4">
            <button @click="$emit('update')"
              class="btn btn-base text-center">Actualizar
            </button>
          </div>
        </div>
      </section>
    </template>
  </Modal>
</template>

<script>
import Modal from "./Modal";

export default {
  props: [
    'editNoteModal',
    'showModaledit',
    'closeModal',
  ],
  components: {
    Modal
  },
  data() {
    return {
      test: '',
    };
  }
};
</script>

<style lang="scss" scoped>
.agregar-nota {
  background-color: #fff;
  border-radius: 10px;
  padding: 0 1rem;
  text-align: left;

  input {
    width: 100%;
    margin: 0 0 1rem;
  }
  select {
    width: 100%;
    margin: 0 0 1rem;
  }
  .contenedor {
    padding: 0 1rem;
  }
}
</style>
