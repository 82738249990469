<template>
  <FadeTransition :duration="200">
    <section v-show="selected == 'vtex_auto'">
      <hr class="my-5" />
      <h3>Integrar Vtex</h3>
      <p>Credenciales para automatizaciones</p>
      <div class="row mt-5">
        <div class="col-lg-6 pl-0">
          <p class="text_error" v-if="error == 'error_name'">Completa el nombre</p>
          <label v-else>Nombre</label>
          <input :class="{ border_error: error == 'error_name' }" placeholder="Ingresa un nombre" type="text"
            v-model="vtex.name" />
        </div>
        <div class="col-lg-6 pr-0">
          <p class="text_error" v-if="error == 'error_app_domain'">Completa el dominio</p>
          <label class="mb-2">Dominio</label>
          <input :class="{ border_error: error == 'error_empty' || error == 'error_app_domain' }"
            placeholder="Ejem: https://tu-dominio.com" type="text" v-model="vtex.app_domain" />
        </div>
        <div class="col-lg-6 pl-0">
          <p class="text_error" v-if="error == 'error_app_key'">Completa el App Key</p>
          <label v-else>App Key</label>
          <input :class="{ border_error: error == 'error_app_key' }" placeholder="Ingresa tu App Key" type="text"
            v-model="vtex.app_key" />
        </div>
        <div class="col-lg-6 pr-0">
          <p class="text_error" v-if="error == 'error_app_token'">Completa el App token</p>
          <label v-else>App token</label>
          <input 
              :class="{ border_error: error == 'error_app_token' }"
              placeholder="Ingresa tu App token" 
              type="text" 
              v-model="vtex.app_token" 
            />          
        </div>
      </div>
      
      <div class="row justify-content-between">
        <button @click="saveIntegration()" class="btn btn-base md mx-0" :disabled="loading || view_permissions.edit != true">{{ !edit ? 'Guardar' :
          'Guardar cambios' }}
          <span class="tooltip_text-sm for_disabled" v-if="view_permissions.edit != true">Lo sentimos, no tienes permisos para guardar cambios.</span>
        </button>
        <button class="btn btn-base outline md mx-0" @click="$emit('closeView')">Cancelar
        </button>
      </div>
      <hr class="mt-5 mb-3" />
    </section>
  </FadeTransition>
</template>
  
<script>
import { FadeTransition } from "vue2-transitions";
import dashboard_api from "@/dashboard_api.js";

export default {
  components: {
    FadeTransition
  },
  props: [
    "selected",
    "bot_id",
    "fields",
    "integrations",
    "view_permissions"
  ],
  data() {
    return {
      edit: false,
      loading: false,
      credentials: {},
      error: '',
      vtex: {
        app_key: '',
        app_token: '',
        name: '',
        app_domain: ''
      }
    };
  },

  watch: {
    integrations(val) {
      if (this.integrations) {
        this.edit = true;
        this.integrationId = this.integrations.id;
        this.credentials = this.integrations.credentials;
        this.getCredentials();
      } else {
        this.edit = false
        this.credentials = null
        this.getCredentials();
      }
    },

    fields(val) {
      this.edit = false,
        this.loading = false;
      this.vtex.app_key = '';
      this.vtex.app_token = '';
      // this.vtex.account_name = '';
      // this.vtex.app_environment = '';
      this.vtex.app_domain = '';
    }
  },

  methods: {
    getCredentials() {
      if(this.credentials){
        let currentCredentials = JSON.parse(this.credentials);
        this.vtex.app_key = currentCredentials.app_key;
        this.vtex.app_token = currentCredentials.app_token;
        this.vtex.app_domain = currentCredentials.app_domain;
        this.vtex.name = currentCredentials.name;
      } else {
        this.vtex.app_key = ''
        this.vtex.app_token = ''
        this.vtex.app_domain = ''
        this.vtex.name = ''
      }
    },
    saveIntegration() {
      let status = true;
      this.loading = true;

      const { app_key, app_token, app_domain, name } = this.vtex;
      if (app_key.length == 0) {
        this.error = 'error_app_key';
        this.loading = false;
      } else if (app_token.length == 0) {
        this.error = 'error_app_token';
        this.loading = false;
      } else if (app_domain.length == 0) {
        this.error = 'error_empty';
        this.loading = false;
      } else if (!app_domain.includes("http")) {
        this.error = 'error_app_domain';
        this.loading = false;
      } else if (name.length == 0) {
        this.error = 'error_name';
        this.loading = false;
      } else if (!this.edit) {
        dashboard_api
          .post("/user_credentials", {
            provider: "vtex_auto",
            credentials: JSON.stringify(this.vtex),
            status,
            bot_id: this.bot_id,            
          },
          ).then(resp => {
            this.$swal({
              icon: "success",
              title: "¡Vtex integrado!",
              timer: 1000,
              showConfirmButton: false,
            });
            this.loading = false;
            this.error = '';
            this.credentials = {};
            this.$emit('closeView');
            this.$emit('getIntegrations');
          })
          .catch(error => console.log('error->', error));
      } else {
        dashboard_api
          .put(`user_credentials/${this.integrationId}`, {
            credentials: JSON.stringify(this.vtex)
          }).then((resp => {
            this.$swal({
              icon: "success",
              title: "Integración modificada",
              timer: 1000,
              showConfirmButton: false,
            });
            this.loading = false;
            this.error = '';
            this.$emit('closeView');
            this.$emit('getIntegrations');
          }));
      }
    }
  }
};
</script>