<template>
  <section v-if="true" class="info">
    <SpinnerLoading v-if="isLoadingInfoSession" />
    <template v-else>
      <div class="info_header d-flex justify-content-between align-items-center mb-3">
        <h3 class="m-0">
          <span @click="setShowClientInfo(false)" class="click mr-2">
            {{ `<-` }}
          </span>
          {{ 
            type == 'messages' ? 'Mensajes' 
            : type == 'recommender' ? 'Recomendaciones'
            : `Archivos`
          }}
          <span v-if="type!=='messages'" class="font-weight-light">({{ dataCounter }})</span>
        </h3>
        <button 
          v-if="type == 'messages' || type == 'recommender'" 
          class="export_button px-3"
          @click="exportLog"
        >
          Exportar
        </button>
      </div>
      <div class="info_body">
        <div class="scroll_box main-scroll px-0">
          <SessionBodyFiles
            v-if="type=='files'"
            :files="sessionData"
          />
          <SessionBodyMessages
            v-if="type=='messages'"
            :messages="sessionData"
            :bot="selected_bot"
            :getImgBot="imgBot"
            :user="user"
            :conversation="selectedConversation"
          />
          <SessionBodyRecommender
            v-if="type=='recommender'"
            :products = 'products'
          />
        </div>
      </div>
    </template>
  </section>
</template>
<script>
import { mapState, mapMutations } from 'vuex';
import { SlideYUpTransition, SlideXLeftTransition } from 'vue2-transitions';
import SessionBodyMessages from "../components/SessionBodyMessages"
import SessionBodyFiles from "../components/SessionBodyFiles"
import SessionBodyRecommender from "../components/SessionBodyRecommender"
import dashboard_api from '../../../../dashboard_api';
import environment from '@/environment.js';
import axios from "axios"

export default {
  props: [
    'show',
    'type',
    'sessionData',
    'sessionId',
    'selectedConversation',
    'isLoadingInfoSession'
  ],
  components: {
    SlideYUpTransition,
    SlideXLeftTransition,
    SessionBodyMessages,
    SessionBodyFiles,
    SessionBodyRecommender,    
  },
  data(){
    return {
      imgBot: null
    }
  },
  mounted() {
    this.getChatbotInfo()
  },
  computed:{
    ...mapState(['user', 'selected_bot']),
    dataCounter() {
      if(this.type == 'files') {
        return this.sessionData.length
      }
      if(this.type == 'recommender') {
        if(this.sessionData.length > 0) {
          let totalProducts = 0
          this.sessionData.map(data => {
            totalProducts = totalProducts + data.products_recomended.length
          })
          return totalProducts
        }
        return 0
      }
    },
    products() {
      if(this.type == 'recommender' && this.sessionData.length > 0) {
        let products = []
        this.sessionData.map(data => {
          data.products_recomended.map(prod=>{
            products.push(prod)
          })
        })
        return products
      } else return []
    }
  },
  methods: {
    ...mapMutations(['setShowClientInfo']),
    async getChatbotInfo() {
      let chatbot = (
        await dashboard_api.get(`/bot/showedit/${this.selected_bot.id}`)
      ).data;
      this.imgBot =
        chatbot.bot_avatar || chatbot.bot_logo_url || 'img/brand/0-default.svg';
    },
    async exportLog(){
      try {
        this.$swal({
          icon: "info",
          title: "Exportando...",
          showConfirmButton: false
        });
        this.$swal.showLoading();
        let baseUrl = ''
        if(this.type == 'messages') {
          baseUrl = `${environment.url_metrics}/export_session_logs_to_pdf`
        } else if(this.type == 'recommender') {
          baseUrl = `${environment.url_metrics}/export_by_session`          
        }
        let payload = {
          "session_id": this.sessionId,
          "user_name": this.user.name,
          "email": this.user.email
        }
        await axios.post(baseUrl, payload)
        this.$swal({
          title: 'Exportación exitosa',
          icon: 'success',
          timer: 1000,
          showConfirmButton: false,
        });
      } catch (error) {
        console.log(error);
        this.$swal({
          title: 'Exportación fallida',
          text: 'Algo salió mal, contactese con soporte',
          icon: 'error',
          buttons: {
            confirm: {
              text: 'Ok',
              value: true,
              visible: true,
              className: '',
              closeModal: true,
            },
          },
        });
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.info {
  width: 100%;
  height: 100%;

  &_header {
    padding: 0.5rem 2rem 1rem;

    @media (max-width: 1600px) {
      padding: 0.5rem 1rem 1rem;
    }
    @media (max-width: 960px) {
      padding: 0.5rem 2rem 1rem;
    }
  }
  &_body {
    padding: 0;
  }
  .scroll_box {
    transition: 0.25s;
    height: auto;
    padding: 0 0.5rem 0 1rem;
    overflow-x: hidden;
    overflow-y: scroll !important;

    &::-webkit-scrollbar-thumb {
      visibility: hidden;

      &:hover,
      &:active {
        visibility: visible;
      }
    }
  }
  .main-scroll {
    height: 750px;

    @media (max-width: 1600px) {
      height: 600px;
    }
  }
}
.export_button {
  background-color: transparent;
  border: 1px solid #888;
  border-radius: 7px;
  color:#888;
  &:hover {
    border: 1px solid #2981ef;
    color:#2981ef
  }
}
</style>