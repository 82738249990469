<template>
  <section class="mx-4">
    <div 
      v-for="file in files" 
      :key="file.id" 
      class="d-flex align-items-center py-3"
      :class="{
        'border-bottom': files.length > 1
      }"
    >
      <img v-if="file.mime_type == 'application/pdf'" src="/img/icons/44-i-file-pdf.svg" class="small_icon"/>
      <img v-else-if="excel.includes(file.mime_type)" src="/img/icons/43-i-file-excel.svg" class="small_icon"/>
      <img v-else :src="file.url" alt="imagen de archivo" class="small_image">
      <span class="pl-2 text-truncate w-100">{{ file.name }}</span>
      <a target="_blank" :href="file.url">
        <button class="view_button px-2 ml-2">
          {{ excel.includes(file.mime_type) ? 'Descargar' : 'Ver' }}
        </button>      
      </a>
    </div>
  </section>
</template>

<script>
export default {
  props: [
    'files',
  ],
  data() {
    return {
      excel: [
        'application/vnd.oasis.opendocument.spreadsheet',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.ms-excel'
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.small_icon {
  height: 40px;
  min-width: 40px;
}
.small_image{
  min-width: 40px;
  height: 40px;
  border-radius: 5px;
  overflow: hidden;
  border:1px solid #ddd;
  object-fit: cover;
}
.view_button {
  background-color: transparent;
  border: 1px solid #888;
  border-radius: 7px;
  color:#888;
  &:hover {
    border: 1px solid #2981ef;
    color:#2981ef
  }
}
.border-bottom {
  border-bottom: 1px solid #ddd;
}
</style>