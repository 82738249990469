import store from "@/store/store";

var AppBridge = window["app-bridge"];
var actions = AppBridge.actions;
var Redirect = actions.Redirect;

export function checkHeadersForReauthorization(headers) {
  if (
    headers["X-Shopify-API-Request-Failure-Reauthorize"] === "1" ||
    headers["x-shopify-api-request-failure-reauthorize"] === "1"
  ) {
    const authUrlHeader =
      headers["X-Shopify-API-Request-Failure-Reauthorize-Url"] ||
      headers["x-shopify-api-request-failure-reauthorize-url"] ||
      `/api/auth`;

    const redirect = Redirect.create(store.state.ecommercePlatformsModule.shopifyAppInstance);
    redirect.dispatch(
      Redirect.Action.REMOTE,
      authUrlHeader.startsWith("/")
        ? `https://${window.location.host}${authUrlHeader}`
        : authUrlHeader
    );
  }
}
