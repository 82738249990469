<template>
  <section class="chats botton-line position-relative h-100">
    <div class="menu">
      <h3 class="my-3">Historia de conversaciones</h3>
      <div class="d-flex justify-content-between" :class="{ search_menu: true }">
        <input
          type="search"
          placeholder="Buscar chat"
          v-model="searchChat"
          class="m-0"
        />
        <span
          v-if="tags.length"
          @click="showDropdownFilter = !showDropdownFilter"
          class="ml-3 filter"
          :class="{
            'i-btn': true,
            'i-filter': !showDropdownFilter,
            'i-btn-active i-close i-st': showDropdownFilter,
          }"
        ></span>
        <MultiselectTags
          @sendCategoryFilter="categoryFilter = $event"
          :showDropdown="showDropdownFilter"
          :items="tags"
        />
      </div>
    </div>
    <spinnerLoading v-if="isLoadingConversations" />
    <template v-else>
      <template v-if="conversations?.length > 0">
        <FadeTransition :duracion="200" v-show="!isLoadingConversations">
          <div class="scroll_box position-relative">
            <div
              :class="{
                to_card: true,
                selected_card: selectedConversation?.id === conversation.id,
              }"
              v-for="(conversation, idx) in conversations"
              :key="idx"
              @click="$emit('onSelectedConversation', conversation), showDropdownFilter = false"
            >
              <div class="card-box">
                <span
                  v-if="
                    conversation.channel !== 'whatsapp' &&
                    conversation.channel !== 'instagram'
                  "
                  class="user-img"
                  >{{ leadName(conversation)[0] }}</span
                >
                <img
                    v-else-if="conversation.channel==='instagram' && conversation.custom?.profile_pic"
                    :src="conversation.custom?.profile_pic"
                    alt=""
                    class="mr-2 msg-profile">
                <span
                  v-else
                  :class="{
                    'user-img-wsp': conversation.channel === 'whatsapp',
                    'user-img-instagram': conversation.channel === 'instagram',
                  }"
                ></span>
                <div class="row align-items-center">
                  <div class="col-6 px-0">
                    <label class="mb-0 mr-2">
                      {{ leadName(conversation) }}
                    </label>
                    <span
                      v-if="
                        conversation.category &&
                        conversation.category != 'general'
                      "
                      class="etiqueta label-p-sm mr-1"
                      >{{ conversation.category }}</span
                    >
                  </div>
                  <div class="col-6 pl-2 pr-0">
                    <p class="text_sm text-right m-0">
                      {{$moment(conversation.last_message_date).locale('es').format('DD MMMM YYYY - h:mm a')}}
                    </p>
                  </div>
                </div>
              </div>              
            </div>
          </div>
        </FadeTransition>
        <div class="pagination-container position-absolute px-3 py-4 w-100">
          <Pagination
            :itemsPerPage="itemsPerPage"
            :page="page"
            :pages="pages"
            @changePage="$emit('changePage', $event)"
            @changeItemsPerPage="$emit('changeItemsPerPage', $event)"
          />
        </div>
      </template>
      <template v-else>
        <FadeTransition :duracion="200" v-show="conversations.length == 0">
          <div class="info_box mx-3 my-4">
            <p class="text-center">Sin conversaciones</p>
          </div>
        </FadeTransition>
      </template>
    </template>
  </section>
</template>

<script>
import { FadeTransition } from 'vue2-transitions/dist/vue2-transitions.cjs';
import { capitalizeFirstLetter } from '@/utils/utils.js';
import MultiselectTags from '@/components/MultiselectTags';
import Pagination from '../../DatabotLive/components/Pagination.vue';
import { mapState } from 'vuex';

export default {
  components: {
    FadeTransition,
    MultiselectTags,
    Pagination
  },
  props: {
    selectedConversation: {
      type: Object,
      default: () => {}
    },
    conversations: {
      type: Array,
      default: () => [],
    },
    isLoadingConversations: {
      type: Boolean,
      default: false,
    },
    tags: {
      type: Array,
      default: () => [],
    },
    itemsPerPage: {
      type: String,
      default: 15
    },
    page: {
      type: Number,
      default: 1
    },
    pages: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      states: ['Activas', 'Tomadas', 'Pendientes'],
      searchChat: '',
      showDropdownFilter: false,
      categoryFilter: [],      
      delayTimer: null
    };
  },
  agentAccount() {
    return this.$store.state.agentAccount;
  },
  watch: {
    categoryFilter(value) {
      this.$emit(
        'onAddCategoryFilter',
        value.map((val) => val.label_name),
      );
    },    
    searchChat() {
      this.searchConversation();
    },
  },
  computed: {
    ...mapState([
      'user',
      'roomToken',
      'selected_bot',
      'show_client_info',
      'push_data',
      'selectedChat',
      'activeChatRooms',
    ]),
  },
  methods: {
    searchConversation() {
      clearTimeout(this.delayTimer);
      this.delayTimer = setTimeout(() => {
        this.$emit('onSearchChat', this.searchChat);
      }, 600);
    },
    sendToArchive(conversation) {
      this.$store.commit(
        'conversationsModule/setSelectedConversation',
        conversation,
      );
      this.$emit('sendToArchive');
    },
    translateStateKey(key) {
      const states = {
        active: 'Activas',
        pending: 'Pendientes',
        taken: 'Tomadas',
      };
      return states[key];
    },
    leadName(conversation) {
      return this.capitalize(
        conversation.lead_name ||
          (!conversation.lead_name || conversation.lead_name == '--'
            ? 'Cliente'
            : conversation.lead_name),
      );
    },
    capitalize(value) {
      return capitalizeFirstLetter(value);
    },
    searchConversationCardAndDelete(conversation, event) {
      this.$store.commit(
        'conversationsModule/deleteConversation',
        conversation,
      );

      this.$store.dispatch('conversationsModule/countStates', {
        botId: this.selected_bot.id,
        userId: this.user.id,
        category: this.$store.state.agentAccount?.category,
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.chats {
  z-index: 0;
  transition: 0.25s;
  background-color: #fff;
  .menu {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;
    margin: 0 auto 1rem;

    @media (max-width: 375px) {
      padding: 0;
    }
  }
  .search_menu {
    width: 100%;
    margin-top: 0.75rem;
    // display: grid;
    // grid-template-columns: 6fr 45px;
    // column-gap: 1rem;

    input[type='search'] {
      border: 1px solid #cdcdcd;
      border-radius: 0.5rem;
      width: 100%;
      padding: 0.5rem 0.75rem;
      margin-right: 0.75rem;
      margin-bottom: 0;
      background-size: 1.125rem 100%;
    }
  }
  .to_card {
    position: relative;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 1rem 0;
    transition: 0.25s;
    border-bottom-color: #f2f2f2;
    cursor: pointer;

    &:hover {
      border-radius: 1rem;
      padding: 1rem 1.25rem;
      background-color: #f2f2f2;
      .archive_chat {
        right: 1.25rem;
      }
    }
    .card-box {
      display: grid;
      grid-template-columns: 30px 1fr;
      column-gap: 0.75rem;

      .user-img {
        text-transform: capitalize;
      }
    }
    .archive_chat {
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      transition: all 0.3s ease;
      span {
        background-color: white;
        padding: 9px 21.5px;
      }
    }
  }
  .selected_card {
    background-color: #f2f2f2;
    border-color: #cdcdcd;
    border-radius: 1rem;
    padding: 1rem 1.25rem;
    transition: 0.25s;
  }
  .user-img,
  .user-img-wsp,
  .user-img-instagram {
    width: 30px;
    height: 30px;
    display: flex;
    border-radius: 50rem;
    background-color: #2981ef;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-weight: 700;
  }
  .user-img-wsp {
    background-color: #9edd63;
    background-image: url('/img/icons/18-i-whatsapp-b.svg');
    background-repeat: no-repeat, repeat;
    background-position: center;
    background-size: 1rem 100%;
  }
  .user-img-instagram {
    background-color: #f5365c;
    background-image: url('/img/icons/31-i-instagram-b.svg');
    background-repeat: no-repeat, repeat;
    background-position: center;
    background-size: 1rem 100%;
  }
  .etiqueta {
    text-transform: capitalize;
    border-color: #cdcdcd;
    margin: auto;
    cursor: default;

    &:hover {
      color: #767676;
      background-color: #fff;
    }
  }
  .message_notification {
    display: flex;
    align-items: center;
    background-color: #2981ef;
    padding: 0.025rem 0.5rem;
    padding-right: calc(0.5rem - 8px);
    border-radius: 2rem;
    color: #fff;
  }
  .pagination-container {
    bottom: 0;
    background-color: white;
  }
  .scroll_box {
    height: 80%;
    transition: 0.25s;
    padding: 0 0.5rem 3rem 1rem;
    overflow-y: scroll !important;

    @media (max-width: 1600px) {
      height: 80%;
    }
    @media (max-width: 375px) {
      padding: 0;
      padding-bottom: 25rem;
    }
    &::-webkit-scrollbar-thumb {
      visibility: hidden;

      &:hover,
      &:active {
        visibility: visible;
      }
    }
  }
  .user-message {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  .text-state {
    font-size: 95%;
    background-color: #fff;
  }
  .not-viewed {
    background-color: #f5365c;
    color: #fff;
    font-weight: 700;
    font-size: 12px;
    padding: 0.05rem 0.5rem;
    border-radius: 0.5rem;
    margin-left: 0.5rem;
  }
  .filter {
    width: 45px
  }
}
</style>
